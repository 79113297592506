import React, {useEffect, useState} from "react";
import AdminRequestHonorariumCreate from "../Models/Request/AdminRequestHonorariumCreate";
import {Card, Col, Row, Spinner} from "reactstrap";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import ResponseUserModel from "../../User/Models/Response/ResponseUserModel";
import ResponseOrgModel from "../../Org/Models/Response/ResponseOrgModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import $ from 'jquery'
import {useTranslation} from "react-i18next";
import AdminResponseHonorarium from "../Models/Response/AdminResponseHonorarium";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {LoadingData} from "../../Common/Components/LoadingData";
import Select from "react-select";
import {useSelector} from "react-redux";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";

export function AdminHonorariumCreate() {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();

    const [honorarium, setHonorarium] = useState(new AdminRequestHonorariumCreate());
    const [responseHonorarium, setResponseHonorarium] = useState(new AdminResponseHonorarium());

    const completeSchemaHonorarium = AdminRequestHonorariumCreate.getAttributes( null, true);

    const [apiResponseUser, setApiResponseUser] = useState('');
    const [apiResponseOrg, setApiResponseOrg] = useState(null);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState(null);

    const [costJson, setCostJson] = useState({});
    const [jsonUsers, setJsonUsers] = useState({});
    const [jsonOrg, setJsonOrg] = useState({});

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);
    const [costSelected, setCostSelected] = useState(null);

    const [displaySpinner, setDisplaySpinner] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    // GET USERS FOR SELECT
    useEffect(() => {
        const userData = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/user/list-dropdown",
                };
                ApiCall(apiCallOptions, setApiResponseUser);
            } catch (error) {
                console.log('error', error);
            }
        };
        userData()
    }, []);

    // GET ORGANIZATIONS FOR SELECT
    useEffect(() => {
        if (selectedUser !== null) {
            const handleApiResponse = (apiResponse) => {
                setApiResponseOrg(apiResponse);
            };
            const orgData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/org/list-dropdown?userId=" + selectedUser,
                    };
                    await ApiCall(apiCallOptions, handleApiResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            orgData();
        }
    }, [selectedUser]);

    // API CALL TO GET COST BEARER
    useEffect(() => {
        if (selectedOrg !== null) {
            const handleCostResponse = (costResponse) => {
                setCostResponse(costResponse);
            };
            const CostApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/costbearer/list-org?id=" + selectedOrg,
                    };
                    await ApiCall(apiCallOptions, handleCostResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            CostApiCall();
        }
    }, [selectedOrg]);

    useEffect(() => {
        if (costResponse !== null) {
            const costBearerArray = [];
            costResponse.forEach((item) => {
                costBearerArray.push({
                    id: item.id,
                    name: item.name,
                });
            });
            setCostJson({
                costBearer: costBearerArray
            });
        }
    }, [costResponse]);

    useEffect(() => {
        if (apiResponseUser !== null && apiResponseUser !== '') {
            const userArray = [];
            apiResponseUser.items.forEach((item) => {
                userArray.push({
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                });
            });
            setJsonUsers({
                userId: userArray,
            });
        }
    }, [apiResponseUser]);

    useEffect(() => {
        if(selectedUser !== null) {
            if (apiResponseOrg !== null && apiResponseOrg.items && apiResponseOrg.items.length > 0) {
                const orgArray = [];
                apiResponseOrg.items.forEach((item) => {
                    orgArray.push({
                        id: item.id,
                        name: item.name,
                        type: item.type
                    });
                });
                setJsonOrg({
                    orgId: orgArray,
                });
            }
        }
    }, [apiResponseOrg]);

    const handleChangeHonorarium = (e, name) => {
        let value;
        if (name === 'paymentInterval') {
            if (e && e.target && e.target.value) {
                value = parseInt(e.target.value);
            } else {
                value = 0;
            }
        }
        if (name === 'annualHonorarium') {
            if (e && e.target && e.target.value) {
                value = CheckDecimal(e.target.value);
            } else {
                value = 0;
            }
        }
        if (name === 'firstPayment') {
            value = ChangeDateFormat('date', e);
        }
        if (name === "userId") {
            if (apiResponseUser !== null && apiResponseUser !== '') {
                apiResponseUser.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            setSelectedUser(item.id);
                            value = e.value;
                        }
                    }
                });
            }
        }
        if (name === "orgId") {
            if (apiResponseOrg !== null && apiResponseOrg.items && apiResponseOrg.items.length > 0) {
                apiResponseOrg.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            setSelectedOrg(item.id);
                            value = e.value;
                        }
                    }
                });
            } else {
                value = '';
            }
        }
        if (name === "costBearerId" && costResponse !== null) {
            costResponse.forEach(item => {
                if (item.id === e.target.value) {
                    setCostSelected(item.id);
                }
            });
            value = e.target.value;
        }
        setHonorarium((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleValidation = async () => {
        $('#submitHonorarium').prop('disabled', true);
        try {
            await completeSchemaHonorarium.validate(honorarium, { abortEarly: false });

            setDisplaySpinner(true);
            if (honorarium && honorarium.userId !== "") {
                const apiCallOptions = {
                    method: "post",
                    url: "/honorarium/admin",
                    data: honorarium,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseHonorarium, setErrorMessage);
                } catch (error) {
                    setDisplaySpinner(false);
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                    $('#submitHonorarium').prop('disabled', false);
                }
            }
        } catch (err) {
            console.log(err)
            $('#submitHonorarium').prop('disabled', false);
        }
    }
    
    useEffect(() => {
        console.log('errorMessage', errorMessage);
        if (errorMessage !== '') {        
            if (errorMessage === 'UserWithoutPnr') {
                FlashMessage('Användaren har inte Pnr , därför är det inte möjligt att behandla denna begäran.', 'flash-messageError', 4500);
            }
            else if (errorMessage === 'AccountNumberInHonorariumIsMandatory') {
                FlashMessage(t('honorarium.missingAccountHonorarium'), 'flash-messageError', 5500);
                setTimeout(()=>{CustomReload();},5800)  
                return;
            }
            else{
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }  
            setTimeout(()=>{CustomReload();},4800)       
        }
    }, [errorMessage]);
    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (responseHonorarium && responseHonorarium.id !== "") {
            FlashMessage(t('validationMessage.honorariumSuccessfully'), 'flash-messageSuccess');
            setTimeout(()=>{
                navigate(`${ADMIN_PATHS.HONORARIUM_INFO}?id=${responseHonorarium.id}`);
            },2000);
        }
    }, [responseHonorarium]);

    // TODO: MOVE TO CUSTOM INPUT
    const annualHonorariumInput = document.getElementById('annualHonorarium');
    if (annualHonorariumInput) {
        annualHonorariumInput.addEventListener('keypress', function(e) {
            const key = e.key;
            if (!((key >= '0' && key <= '9') || key === '.' || key === ',')) {
                e.preventDefault();
            }
        });
    }

    const messageCostBearer = InputMessage(costResponse, costSelected);

    // HIDE AND SHOW USER SELECT
    setTimeout(() => {
        $('#userDiv').find('.css-b62m3t-container').click(function () {
            if (selectedUser === null) {
                $('#userDiv').find('.css-b62m3t-container').find('.css-1nmdiq5-menu').hide();
            } else {
                $('#userDiv').find('.css-b62m3t-container').find('.css-1nmdiq5-menu').show();
            }
        });
    }, 250);
    // SHOW WHEN DATA-VALUE IS NOT UNDEFINED
    const checkKey = (e) => {
        let value = undefined;
        value = $('#userDiv').find('.css-b62m3t-container').find('.css-10ber8t-control').find('.css-hlgwow').find('.css-1cfo1cf').data('value');
        if (value !== undefined) {
            $('#userDiv').find('.css-b62m3t-container').find('.css-1nmdiq5-menu').show();
        }
    }
    window.addEventListener('keydown', checkKey);

    return(
        <>
            <Col>
                <CustomButton text={t('button.back')} icon={'bx bx-chevron-left'} buttonSize={'small'} customClasses={'mb-3 ms-3'} onClick={() => {
                        navigate(ADMIN_PATHS.HONORARIUM_LIST);
                    }} />
            </Col>
            <Row className="pb-4">
                <Col lg={6}>
                    <Card className="card-backend p-4">
                        <Row>
                            <Col xs='auto'>
                                <h2 className='rokkitt-font'>{t('honorarium.create')}</h2>
                            </Col>
                            <InfoPageMessage message={'Test: message create Honorarium'}/>
                        </Row>
                        {apiResponseUser !== null && apiResponseUser !== '' ?
                            <Row>
                                <Col lg={6} id="userDiv">
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='userId' data={''} customChange={(e) => {
                                        handleChangeHonorarium(e, 'userId');
                                        handleChangeHonorarium({ target: { value: '' } }, 'orgId');
                                        setJsonOrg({});
                                    }} customOptions={jsonUsers}/>
                                </Col>
                                <Col lg={6}>
                                    {selectedUser !== null ?
                                        <CustomInput model={AdminRequestHonorariumCreate} attribute='orgId' data={honorarium.orgId} customChange={(e) => {
                                            handleChangeHonorarium(e, 'orgId');
                                            setCostSelected(null);
                                            setHonorarium((prevFormData) => ({
                                                ...prevFormData,
                                                costBearerId: ""
                                            }));
                                        }} customOptions={jsonOrg}/>
                                        : '' }
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='firstPayment' data={''} customChange={(e) => handleChangeHonorarium(e, 'firstPayment')}/>
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='paymentInterval' data={''} customChange={(e) => handleChangeHonorarium(e, 'paymentInterval')}/>
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestHonorariumCreate} attribute='annualHonorarium' data={''} customChange={(e) => handleChangeHonorarium(e, 'annualHonorarium')}/>
                                </Col>
                                <Col lg={6}>
                                    {costResponse !== null && selectedOrg != null ?
                                        <>
                                            <CustomInput model={AdminRequestHonorariumCreate} attribute='costBearerId' data={honorarium.costBearerId} customChange={(e) => {
                                                handleChangeHonorarium(e, 'costBearerId');
                                            }} customOptions={costJson}/>
                                            {messageCostBearer}
                                        </>

                                        : ''}
                                </Col>
                                <Col lg={12} className="text-end pt-5">
                                    {displaySpinner === true ?
                                        <>
                                            {t('validationMessage.sendingHonorarium')} <Spinner color='success' className="me-3" />
                                        </>
                                    : ''}
                                    <CustomSubmitButton text={t('button.send')} id='submitHonorarium' icon='bx bx-send pe-2' customClick={handleValidation} />
                                </Col>
                            </Row>
                            :
                            <LoadingData data={apiResponseUser}/>}

                    </Card>
                </Col>
            </Row>
        </>
    )
}