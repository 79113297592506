import i18n from "i18next";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class SuperAdminResponseTransaction {
    constructor({
                    id = "",
                    openPaymentAccountId = "",
                    openPaymentTransactionId = "",
                    bookingDate = "",
                    status = "",
                    displayStatus = "",
                    valueDate = "",
                    transactionAmount = {},
                    balanceAfterTransaction = {},
                    remittanceInformationUnstructured = "",
                    remittanceInformationStructured = "",
                    transactionListId = "",
                    bicFi = "",
                } = {}) {
        this.id = id;
        this.openPaymentAccountId = openPaymentAccountId;
        this.openPaymentTransactionId = openPaymentTransactionId;
        this.bookingDate = bookingDate;
        this.status = status;
        this.displayStatus = displayStatus;
        this.valueDate = valueDate;
        this.transactionAmount = transactionAmount;
        this.balanceAfterTransaction = balanceAfterTransaction;
        this.remittanceInformationUnstructured = remittanceInformationUnstructured;
        this.remittanceInformationStructured = remittanceInformationStructured;
        this.transactionListId = transactionListId;
        this.bicFi = bicFi;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes =  {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            openPaymentAccountId :{
                'label': "Open Payment Account ID",
                'sortable': true,
                'sortField': 'openPaymentAccountId',
                'value': (item) => item.openPaymentAccountId || "",
            },
            openPaymentTransactionId: {
                'label': "Open Payment Transaction ID",
                'sortable': true,
                'sortField': 'openPaymentTransactionId',
                'value': (item) => item.openPaymentTransactionId || "",
            },
            bookingDate: {
                'label': i18n.t('modelLabel.bookedDate'),
                'sortable': true,
                'sortField': 'bookingDate',
                'value': (item) => item.bookingDate ? ChangeDateFormat("date", item.bookingDate) : "",
                'type': 'dateTime'
            },
            status: {
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': 'status',
                'value': (item) => item.status || "",
                'type': 'select'
            },
            displayStatus: {
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': 'displayStatus',
                'insideClass' : (item)=> item.displayStatus === 'Bokad' || item.displayStatus === 'BankGiro' ? 'box-green me-4 fixed-width' : "box-custom-grey me-4 fixed-width",
                'value': (item) => item.displayStatus || "",
                'type': 'select'
            },
            valueDate: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'valueDate',
                'value': (item) => item.valueDate ? ChangeDateFormat("date", item.valueDate) : "",
                'type': 'dateTime'
            },
            transactionAmount: {
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                'sortField': 'transactionAmount',
                'insideClass' : (item)=> item.transactionAmount && item.transactionAmount.amount ? item.transactionAmount.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width' : "",
                'value': (item) => item.transactionAmount && item.transactionAmount.amount ?
                    ToCustomDecimal(parseFloat(item.transactionAmount.amount)) + " " + (item.transactionAmount.currency === "EUR" ? "€" : (item.transactionAmount.currency === "SEK" ? "kr" : item.transactionAmount.currency))
                 : "",
                 'type': 'number',
            },
            balanceAfterTransaction: {
                'label': i18n.t('modelLabel.balanceAfterTransaction'),
                'sortable': true,
                'sortField': 'balanceAfterTransaction',
                'insideClass' : (item)=> item.balanceAfterTransaction && item.balanceAfterTransaction.balanceAmount && item.balanceAfterTransaction.balanceAmount.amount ? item.balanceAfterTransaction.balanceAmount.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width' : "",
                'value': (item) => item.balanceAfterTransaction && item.balanceAfterTransaction.balanceAmount && item.balanceAfterTransaction.balanceAmount.amount ?
                    ToCustomDecimal(parseFloat(item.balanceAfterTransaction.balanceAmount.amount)) + " " + (item.balanceAfterTransaction.balanceAmount.currency === "EUR" ? "€" : (item.balanceAfterTransaction.balanceAmount.currency === "SEK" ? "kr" : item.balanceAfterTransaction.balanceAmount.currency))
                    : "",
                'type': 'number'
            },
            remittanceInformationUnstructured: {
                'label': i18n.t('button.info'),
                'sortable': true,
                'sortField': 'remittanceInformationUnstructured',
                "tdClass": (item) => "auto-wrap-td",
                'value': (item) => item.remittanceInformationUnstructured || "",
            },
            remittanceInformationStructured:{
                'label': "Remittance Information Structured",
                'sortable': true,
                'sortField': 'remittanceInformationStructured',
                "tdClass": (item) => "auto-wrap-td",
                'value': (item) => item.remittanceInformationStructured || "",
            },
            transactionListId: {
                'label': "Transaction List ID",
                'sortable': true,
                'sortField': 'transactionListId',
                'value': (item) => item.transactionListId || '',
            },
            bicFi: {
                'label': 'BicFi',
                'sortable': true,
                'sortField': 'bicFi',
                'value': (item) => item.bicFi || '',
            }
        };

        return attributes;
    }
}

export default SuperAdminResponseTransaction;