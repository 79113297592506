import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";

class AdminResponseOpenPaymentModel {
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    userId = '',
                    model = '',
                    modelId = '',
                    type = '',
                    instructedAmount = '',
                    createdAt =  '',
                    updatedAt =  '',
                    creditorName = '',
                    creditorAccountIban = '',
                    clearingNumber = '',
                    paymentType = '',
                    paymentId = '',
                    status='',
                    paymentDetails = {},
                    warning = '',
                    message = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.userId = userId;
        this.model = model;
        this.modelId = modelId;
        this.instructedAmount = instructedAmount;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.creditorName = creditorName;
        this.creditorAccountIban = creditorAccountIban;
        this.clearingNumber = clearingNumber;
        this.paymentType = paymentType;
        this.paymentId = paymentId;
        this.status = status;
        this.type = type;
        this.paymentDetails = paymentDetails;
        this.warning = warning;
        this.message = message;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const id = i18n.t('modelLabel.id');
        const orgId = i18n.t('modelLabel.id');
        const model = i18n.t('modelLabel.type');
        const modelId = i18n.t('modelLabel.typeId');
        const amount = i18n.t('modelLabel.amount');
        const createdAt = i18n.t('modelLabel.createdAt');
        const updatedAt = i18n.t('modelLabel.updatedAt');
        const userId = i18n.t('modelLabel.userId');
        const creditorName = i18n.t('openPayment.creditorName');
        const creditorAccountIban = i18n.t('openPayment.creditorAccountIban');
        const statusName = i18n.t('modelLabel.status');
        const reimbursement = i18n.t('reimbursement.reportProcessing');
        const TheSwedishTaxAgency = i18n.t('modelLabel.TheSwedishTaxAgency');
        const honorarium = i18n.t('honorarium.honorarium');
        const pay = i18n.t('payReceive.pay');
        const internalTransaction = i18n.t('internalTransaction.internalTransaction');
        const clearingNumber = i18n.t('userSetting.modelClearingNumber');
        const paymentType = i18n.t('modelLabel.paymentType');

        const attributes = {
            id :{
                'label': id,
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            orgId :{
                'label': orgId,
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            userId :{
                'label': userId,
                'sortable': true,
                'sortField': 'userId',
                'value': (item) => item.userId || "",
            },
            model :{
                'label': model,
                'sortable': true,
                'sortField': 'model',
                'value': (item) =>  item.model === 'Skatteverket' ? 'Skatteinbetalning' : item.model === 'Förrättning' ? reimbursement : item.model === 'Arvode' ? honorarium : item.model === 'Betala' ? pay : item.model === 'Intern transaktion' ? internalTransaction : '' || "",
                'type': 'select' ,
            },
            modelId :{
                'label': modelId,
                'sortable': true,
                'sortField': 'modelId',
                'value': (item) => item.modelId || "",
            },
            instructedAmount :{
                'label': amount,
                'sortable': true,
                'sortField': 'instructedAmount',
                'value': (item) => item.instructedAmount || "",
                'type': 'number',
            },
            createdAt :{
                'label': updatedAt,
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.updatedAt  ? ChangeDateFormat('dateTime', item.updatedAt) : (item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) :''|| ""),
                'type': 'dateTime' 
            },
            updatedAt :{
                'label': updatedAt,
                'sortable': true,
                'sortField': 'updatedAt',
                'value': (item) => item.updatedAt ? ChangeDateFormat('dateTime', item.updatedAt) : ''|| "",
                'type': 'dateTime' ,
            },
            creditorName :{
                'label': creditorName,
                'sortable': true,
                'sortField': 'creditorName',
                'value': (item) => item.model === 'Skatteverket' ? TheSwedishTaxAgency : item.creditorName || "",
            },
            creditorAccountIban :{
                'label': creditorAccountIban,
                'sortable': true,
                'sortField': 'creditorAccountIban',
                'value': (item) => {
                    const clearingNumber = item.clearingNumber ? item.clearingNumber : "";
                    return `${clearingNumber}${item.creditorAccountIban}`  || "";
                }
            },
            clearingNumber :{
                'label': clearingNumber,
                'sortable': true,
                'sortField': 'clearingNumber',
                'value': (item) => item.clearingNumber || "",
            },
            paymentType :{
                'label': paymentType,
                'sortable': true,
                'sortField': 'paymentType',
                'value':  (item) =>  item.paymentType || "",
            },
            paymentId :{
                'label': paymentType,
                'sortable': true,
                'sortField': 'paymentId',
                'value': (item) => item.paymentId || "",
            },
            status :{
                'label': statusName,
                'sortable': true,
                'sortField': 'status',
                'value': (item) => item.status || "",               
            },
            paymentDetails :{
                'label': statusName,
                'sortable': true,
                'sortField': 'status',
                'value': (item) => item.paymentDetails || "",
            },


        }
        return attributes;
    }
}
export default AdminResponseOpenPaymentModel;