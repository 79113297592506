import {Button, Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import React from "react";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import { generateFileName } from "../../AppSystem/Helpers/GenerateFileName";
import useApiCallService from "../../Common/Helpers/ApiCall";

export function AttestSwedbankPayViews({data, modelId = null}) {
    const { t } = useTranslation();
    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const { ApiCall } = useApiCallService();

    const downloadFile = async (url, number) => {
            const handleDownloadApiResponse  = (response) => {
                let file;
                if (response instanceof Blob) {
                    file = response;
                } else {
                    file = new Blob([response]);
                }
                const newFileName = generateFileName('Kvitto', number);
                let fileName = newFileName;
                const urlToFile = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = urlToFile;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
    
            const apiCallOptions = {
                method: "get",
                url: url,
                dataTypes: 'blob',
                responseType: 'blob'
            };
            try {
                await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response));
            } catch (error) {
                console.log(error);
            }
        }
    return (
        <Row className="px-4 justify-content-center">
            {/*<hr className="mt-4" />*/}
            <Col lg={8}>
                <h2 className="rokkitt-font">{t('payReceive.quickPay')}</h2>
            </Col>
            <Col lg={4} >
                <ul className="list-unstyled">
                    {isSuperAdmin === true && modelId ?
                        <>
                            <li className="pt-1 row">
                                <span className="fw-bold col-4">{t('modelLabel.modelId')}:</span>
                                <span className="col-8">{modelId}</span>

                            </li>
                        </>
                        : '' }
                </ul>
            </Col>
            <Col lg={10} className="border-top">
                <Row>
                    {isSuperAdmin === true ?
                        <Col lg={6}>
                                <ul className="list-unstyled">
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-4">{t('modelLabel.id')} </span>
                                        <span className="col-8">{data.id}</span>
                                    </li>
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-4">{t('reimbursement.costBearer')} </span>
                                        <span className="col-8">{data.costBearerId}</span>
                                    </li>
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-4">{t('modelLabel.accountingId')} </span>
                                        <span className="col-8">{data.accountingId}</span>
                                    </li>
                                </ul>
                        </Col>
                    : ''}
                    <Col lg={6}>
                        <ul className="list-unstyled">
                            {data.visualId !== "" ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-4">{t('modelLabel.id')}:</span>
                                    <span className="col-8">{data.visualId}</span>
                                </li>
                            : ""}
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.createdAt')} </span>
                                <span className="col-8">{ChangeDateFormat('dateTime', data.createdAt)}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.createdBy')} </span>
                                <span className="col-8">{data.createdByName}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('reimbursement.costBearer')} </span>
                                <span className="col-8">{data.costBearerName}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.accounting')} </span>
                                <span className="col-8">{data.accountingName}</span>

                            </li>                       
                            {data.orgName ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-4">{t('modelLabel.orgName')} </span>
                                    <span className="col-8">{data.orgName}</span>

                                </li>
                                : ''}
                            {data.displayStatus ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-4">{t('modelLabel.status')} </span>
                                    <span className="col-8">
                                     {data.displayStatus === 'Initialiserad' ? t('modelLabel.initialized') : data.displayStatus === 'Avbruten' ? t('modelLabel.aborted') : data.displayStatus === 'Betald' ? t('modelLabel.paid') : ''}
                                    </span>
                                </li>
                                : ''}
                        </ul>
                    </Col>
                </Row>
            </Col>
            <Col lg={10} className="border-bottom">
                <Row>
                    <Col lg={6}>
                        <ul className="list-unstyled">
                        <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.recipient')} </span>
                                <span className="col-8">{data.email}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('reimbursement.amount')} </span>
                                <span className="col-8">{data.amount ? ToCustomDecimal(data.amount) : 0} kr</span>

                            </li>
                            {data.vatAmount ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-4">{t('modelLabel.vatAmount')} </span>
                                    <span className="col-8">{data.vatAmount ? ToCustomDecimal(data.vatAmount) : 0} kr</span>

                                </li>
                                : ''}
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.description')} </span>
                                <span className="col-8">{data.description}</span>

                            </li>
                            {data.paymentMethod ?
                                <li className="pt-2 row"><span
                                    className="fw-bold col-4">{t('modelLabel.paymentMethod')} </span>
                                    <span className="col-8">{data.paymentMethod}</span>

                                </li>
                                : ''}
                            {data.updatedAt ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-4">{t('modelLabel.transactionDate')} </span>
                                    <span className="col-8">{ChangeDateFormat('dateTime', data.updatedAt)}</span>

                                </li>
                            : ''}
                        </ul>
                    </Col>
                    <Col lg={6} className="order-1 order-lg-0 d-flex align-items-end justify-content-end pt-3 pt-lg-0 pb-2">
                {/* {dataInvoice.status !== "Attestera kö" ? */}
                 {data.receiptFileUrl !== null && data.displayStatus === 'Betald' ?
                            <Button className="bg-green-light text-green border-0 position-relative" onClick={() => {
                                  downloadFile(data.receiptFileUrl ,data.id)
                            }}>
                                <i className="bx bx-down-arrow-alt fs-5 align-middle pb-1" />
                                <div className="tooltip-custom-button-table">
                                   {t('modelLabel.download')}
                                </div>
                            </Button>
                     : ''} 
                         {/* : ''} */}
                </Col>
                </Row>
            </Col>
        </Row>
    );

}