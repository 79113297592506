import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Button, Spinner} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import useApiCallService from "../../Common/Helpers/ApiCall";
import RequestReimbursementFixed from "../Models/Request/RequestReimbursementFixed"
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementMileage from "../Models/Request/RequestReimbursementMileage";
import RequestReimbursementExpense from "../Models/Request/RequestReimbursementExpense";
import $ from 'jquery';
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import ResponseReimbursement from "../Models/Response/ResponseReimbursement";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {Link, useNavigate} from "react-router-dom";
import {REPORT_PROCEEDING_PATHS, USER_PATHS} from "../../config";
import {ReimbursementFixed} from "../Components/ReimbursementFixed";
import {ReimbursementMileage} from "../Components/ReimbursementMileage";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {ReimbursementExpense} from "../Components/ReimbursementExpense";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import RequestPassenger from "../Models/Request/RequestPassenger";
import {StringToLowerCaseFirst} from "../../Common/Helpers/StringToLowerCaseFirst";
import {useTranslation} from "react-i18next";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {CustomUnsavedModal} from "../../Common/Components/Modal/CustomUnsavedModal";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CustomReimbursementView} from "../Components/CustomReimbursementView";
import {CustomReimbursementFixedView} from "../Components/CustomReimbursementFixedView";
import {CustomReimbursementMileageView} from "../Components/CustomReimbursementMileageView";
import {CustomReimbursementExpenseView} from "../Components/CustomReimbursementExpenseView";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {useSelector} from "react-redux";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import _ from "lodash";
import RequestReimbursementV2 from "../Models/Request/RequestReimbursementV2";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";

export function ReportProceedingV2() {
    const { t } = useTranslation()
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();
    const { userData, orgData } = useSelector(state => state.auth);

    // GET ID IF EXISTS FOR DRAFT
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // PERMISSIONS
    let permissionCreate = false;
    if (CheckUserPermission("reimbursement") || CheckUserPermission("reimbursement.create")) {
        permissionCreate = true;
    }

    // CHECK UNSAVED
    const [checkUnsaved, setCheckUnsaved] = useState(false);

    // REQUESTS
    const [requestReimbursement, setRequestReimbursement] = useState(new RequestReimbursementV2());
    const [requestReimbursementFixed, setRequestReimbursementFixed] = useState(new RequestReimbursementFixed());
    const [requestReimbursementFixedList, setRequestReimbursementFixedList] = useState([]);
    const [requestReimbursementMileage, setRequestReimbursementMileage] = useState(new RequestReimbursementMileage());
    const [requestReimbursementExpense, setRequestReimbursementExpense] = useState(new RequestReimbursementExpense());
    const [requestReimbursementExpenseList, setRequestReimbursementExpenseList] = useState([]);

    // RESPONSES
    const [infoResponse, setInfoResponse] = useState(null);
    const [draftResponse, setDraftResponse] = useState(null);
    const [responseReimbursement, setResponseReimbursement] = useState(new ResponseReimbursement());
    const [responseDelete, setResponseDelete] = useState(null);

    // VALIDATION SCHEMAS
    const completeSchema = RequestReimbursementV2.getAttributes(null, "send");
    const completeSchemaSave = RequestReimbursementV2.getAttributes(null, "save");
    const completeSchemaFixed = RequestReimbursementFixed.getAttributes(null, true);
    const completeSchemaMileage = RequestReimbursementMileage.getAttributes(null, true);
    const completeSchemaPassenger = RequestPassenger.getAttributes(null, true);
    const completeSchemaExpense = RequestReimbursementExpense.getAttributes( null, true);

    // REIMBURSEMENT FIXED
    const [isFixed, setIsFixed] = useState(false);
    const [wasAddedFixed, setWasAddedFixed] = useState(false);
    const [deletedFixed, setDeletedFixed] = useState(false);

    // REIMBURSEMENT EXPENSE
    const [fileType, setFileType] = useState("");
    const [fileResponse, setFileResponse] = useState(null);
    const [addedExpense, setAddedExpense] = useState(false);
    const [expenseIndex, setExpenseIndex] = useState(null);
    const [requestName , setRequestName] = useState("");
    const [requestName2 , setRequestName2] = useState("");
    const [extraField , setExtraField] = useState(null);
    const [extraField2 , setExtraField2] = useState(null);
    const [isDeletedFile, setIsDeletedFile] = useState(false);
    const [isDeletedExtraFile, setIsDeletedExtraFile] = useState(false);
    const [localDisplayExpense, setLocalDisplayExpense] = useState(false);

    // SELECTS
    const [orgs, setOrgs] = useState({});
    const [accountings, setAccountings] = useState({});
    const [costBearers, setCostBearers] = useState({});

    // GROSS AMOUNT
    const [grossAmount, setGrossAmount] = useState(0);

    // ORG ID
    const [selectedOrgId, setSelectedOrgId] = useState(orgData.orgId ?? "");
    const [selectedOrgName, setSelectedOrgName] = useState("");
    const [selectedCostBearerId, setSelectedCostBearerId] = useState("");
    const [selectedCostBearerName, setSelectedCostBearerName] = useState("");

    // EDIT MODE
    const [editModeFixed, setEditModeFixed] = useState(false);
    const [editModeMileage, setEditModeMileage] = useState(false);
    const [editModeExpense, setEditModeExpense] = useState(false);

    // DISPLAY FORMS
    const [displayFixed, setDisplayFixed] = useState(true);
    const [displayMileage, setDisplayMileage] = useState(true);
    const [displayExpense, setDisplayExpense] = useState(true);

    // MODALS
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [reimbursementToDelete, setReimbursementToDelete] = useState("");
    const [displaySendModal, setDisplaySendModal] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [displayDeleteReimbursementModal, setDisplayDeleteReimbursementModal] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    /********************************************* GET INFO ******************************************/
    // GET NECESSARY INFO
    useEffect(() => {
        const HandleInfoResponse = (response) => {
            setRequestReimbursement((prevFormData) => ({
                ...prevFormData,
                toDate: null,
            }));
            let orgType = "";
            // ORGS
            if (response.orgsByUser && Array.isArray(response.orgsByUser) && response.orgsByUser.length > 0) {
                const orgArray = [];
                response.orgsByUser.map(item => {
                    if (item.id === selectedOrgId) {
                        orgType = item.type;
                        setSelectedOrgName(item.name);
                    }
                    orgArray.push({
                        id: item.id,
                        name: item.type != null ? `${item.name} (${item.type})` : item.name,
                    });
                });
                setOrgs({
                    org: orgArray
                });
            }

            // ACCOUNTINGS
            if (response.accountings && Array.isArray(response.accountings) && response.accountings.length > 0) {
                const accountingArray = [];
                response.accountings.map(item => {
                    accountingArray.push({
                        id: item.id,
                        name: item.showLabel,
                        message: item.message
                    });
                });
                setAccountings({
                    accounting: accountingArray
                });
            }

            // COST BEARERS
            if (orgType !== "" && response.costBearers && Array.isArray(response.costBearers) && response.costBearers.length > 0 && response.costBearersByOrgType && Array.isArray(response.costBearersByOrgType) && response.costBearersByOrgType.length > 0) {
                response.costBearers.map(item => {
                    if (item.id === selectedCostBearerId) {
                        setSelectedCostBearerName(item.name);
                    }
                });
                const costBearersByOrgType = response.costBearersByOrgType.find(item => item.type === orgType);
                if (costBearersByOrgType) {
                    const costBearerJsonArray = response.costBearers.filter(cb => costBearersByOrgType.costBearerNames.includes(cb.name)).map(cb => ({ id: cb.id, name: cb.name }));
                    setCostBearers({
                        costBearer: costBearerJsonArray
                    });
                }
            }

            setInfoResponse(response);
        };

        const GetInfo = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: `/reimbursement/v2/info`
                };
                await ApiCall(apiCallOptions, HandleInfoResponse);
            } catch (error) {
                //console.log({error});
            }
        };
        GetInfo();
    }, []);

    // GET REIMBURSEMENT DRAFT
    useEffect(() => {
        if (id !== "") {
            const HandleDraftResponse = (response) => {
                if (response.reimbursement) {
                    setTimeout(() => {
                        setRequestReimbursement(new RequestReimbursementV2(response.reimbursement));
                    }, 100);
                    if ((response.reimbursement.toDate && response.reimbursement.toDate === "") || !response.reimbursement.toDate) {
                        setTimeout(() => {
                            setRequestReimbursement((prevFormData) => ({
                                ...prevFormData,
                                toDate: null,
                            }));
                        }, 150);
                    }
                    if (response.reimbursement.fixeds && Array.isArray(response.reimbursement.fixeds) && response.reimbursement.fixeds.length > 0) {
                        setDisplayFixed(false);
                        setEditModeFixed(false);
                        if (response.reimbursement.fixeds.length > 1) {
                            setRequestReimbursementFixedList(response.reimbursement.fixeds.map(item => {
                                return new RequestReimbursementFixed(item);
                            }));
                        } else {
                            if (response.reimbursement.fixeds[0].type === "Fixed") {
                                setIsFixed(true);
                                setWasAddedFixed(true);
                                setRequestReimbursementFixed(new RequestReimbursementFixed(response.reimbursement.fixeds[0]));
                            } else {
                                setRequestReimbursementFixedList(response.reimbursement.fixeds.map(item => {
                                    return new RequestReimbursementFixed(item);
                                }));
                            }
                        }
                        setTimeout(() => {
                            $("#date, #toDate, #checkInput").prop("disabled", true);
                        }, 200);
                    }
                    if (response.reimbursement.mileage) {
                        setDisplayMileage(false);
                        setEditModeMileage(false);
                        setRequestReimbursementMileage(new RequestReimbursementMileage(response.reimbursement.mileage));
                    }
                    if (response.reimbursement.date) {
                        setRequestReimbursementExpense((prevFormData) => ({
                            ...prevFormData,
                            dateReceive: response.reimbursement.date,
                        }));
                    }
                    if (response.reimbursement.expenses && response.reimbursement.expenses.length > 0) {
                        setAddedExpense(true);
                        setEditModeExpense(false);
                        setRequestReimbursementExpenseList(response.reimbursement.expenses.map(item => {
                            return new RequestReimbursementExpense(item);
                        }));
                    }
                    if (response.reimbursement.orgId && response.reimbursement.orgId !== "") {
                        setSelectedOrgId(response.reimbursement.orgId);
                    }
                    if (response.reimbursement.orgName && response.reimbursement.orgName !== "") {
                        setSelectedOrgName(response.reimbursement.orgName);
                    }
                    if (response.reimbursement.costBearerId && response.reimbursement.costBearerId !== "") {
                        setSelectedCostBearerId(response.reimbursement.costBearerId);
                    }
                    if (response.reimbursement.costBearerName && response.reimbursement.costBearerName !== "") {
                        setSelectedCostBearerName(response.reimbursement.costBearerName);
                    }
                }
                setDraftResponse(response);
            };
            const GetReimbursement = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/reimbursement/v2?id=${id}`
                    };
                    await ApiCall(apiCallOptions, HandleDraftResponse);
                } catch (error) {
                    //console.log({error});
                }
            };
            GetReimbursement();
        }
    }, []);
    /********************************************* GET INFO ******************************************/

    /********************************************* COMMON ********************************************/
    // HANDLE CHANGE COMMON
    const HandleChangeCommon = (e, name, isDraft = null) => {
        if (isDraft === null) {
            setCheckUnsaved(true);
        }
        let value = e?.target?.value && e?.target?.value !== "" ? e.target.value : "";
        let salaryDateValue = requestReimbursement.date;
        let dateValue = requestReimbursement.date;
        let toDateValue = requestReimbursement.toDate;
        let dateReceiveValue = requestReimbursementExpense.dateReceive && requestReimbursementExpense.dateReceive !== "" ? ChangeDateFormat("date", requestReimbursementExpense.dateReceive) : "";
        switch (name) {
            case "orgId":
                setSelectedOrgId(e.target.value);
                break;
            case "costBearerId":
                setSelectedCostBearerId(e.target.value);
                break;
            case "date":
                dateValue = e && e !== "" ? ChangeDateFormat('date', e) : "";
                break;
            case "toDate":
                toDateValue = e && e !== "" ? ChangeDateFormat('date', e) : "";
                break;
        }
        if (name === "date" /*|| name === "toDate"*/) {
            salaryDateValue = dateValue;
            if (dateReceiveValue === "") {
                dateReceiveValue = dateValue;
            }
        }
        if (name === "check") {
            if (toDateValue === null) {
                toDateValue = "";
            } else {
                toDateValue = null;
            }
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            date: dateValue,
            toDate: toDateValue,
        }));
        setRequestReimbursementFixed((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            date: dateValue,
            toDate: toDateValue,
            salaryDate: salaryDateValue,
        }));
        if (requestReimbursementFixedList.length > 0) {
            requestReimbursementFixedList.map(item => {
                item[name] = value;
                item.date = dateValue;
                item.toDate = toDateValue;
            });
            setRequestReimbursementFixedList(requestReimbursementFixedList);
        }
        setRequestReimbursementMileage((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            date: dateValue,
            toDate: toDateValue,
        }));
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            date: dateValue,
            toDate: toDateValue,
            dateReceive: dateReceiveValue,
        }));
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(item => {
                item[name] = value;
                item.date = dateValue;
                item.toDate = toDateValue;
                //item.dateReceive = dateReceiveValue;
            });
            setRequestReimbursementExpenseList(requestReimbursementExpenseList);
        }
    };

    // CHECK REQUIRED INPUTS
    const CheckRequired = () => {
        $("#date").focus();
        $("#orgId").focus();
        $("#purpose").focus();
        $("#costBearerId").focus();
        setTimeout(() => {
            $('.react-datepicker__tab-loop').addClass('d-none');
            $("#date").blur();
            $("#orgId").blur();
            $("#purpose").blur();
            $("#costBearerId").blur();
        },1)
        if (requestReimbursement.date === "" || requestReimbursement.orgId === "" || requestReimbursement.purpose === "" || requestReimbursement.costBearerId === "") {
            return false;
        }
        return true;
    }
    /********************************************* COMMON ********************************************/

    /********************************************* FIXED *********************************************/
    // CHECK IF THERE ARE MORE THAN ONE FIXED
    useEffect(() => {
        if ((requestReimbursement.date && requestReimbursement.date !== "") || deletedFixed === true) {
            // DATE AND TODATE
            if (requestReimbursement.toDate && requestReimbursement.toDate !== "") {
                // FORMAT DATES
                const dateFormated = new Date(ChangeDateFormat("date", requestReimbursement.date));
                const toDateFormated = new Date(ChangeDateFormat("date", requestReimbursement.toDate));

                // DIFFERENCE IN SECONDS
                const secondsDifference = toDateFormated.getTime() - dateFormated.getTime();

                // GET DAYS
                const days = (secondsDifference / (1000 * 60 * 60 * 24)) + 1;

                // CHECK IF DAY IS POSITIVE
                if (days > 0) {
                    // MULTIPLE
                    if (days > 1) {
                        // IF THERE ARE MORE FIXEDS THAN DAYS, DELETE THE OUT OF RANGE
                        if (requestReimbursementFixedList.length > days) {
                            // HIDE TOOLTIP ERROR
                            $('#ErrorFormDate').addClass('d-none');
                            setRequestReimbursementFixedList(requestReimbursementFixedList.filter(item => {
                                const itemSalaryDate = new Date(item.salaryDate);
                                if (itemSalaryDate < dateFormated) {
                                    // FIT
                                    //item.salaryDate = itemDate;
                                    // DELETE
                                    return false;
                                }
                                if (itemSalaryDate > toDateFormated) {
                                    // FIT
                                    //item.salaryDate = itemToDate;
                                    // DELETE
                                    return false;
                                }
                                return true;
                            }));
                        }
                        // IF THERE ARE NO FIXEDS CREATE NUMBER OF DAYS
                        else if (requestReimbursementFixedList.length === 0) {
                            // HIDE TOOLTIP ERROR
                            $('#ErrorFormDate').addClass('d-none');
                            const newFixeds = [];
                            for (let i = 0; i < days /*- 1*/; i++) {
                                const tempReimbursement = _.cloneDeep(requestReimbursementFixed);
                                const newSalaryDate = new Date(dateFormated);
                                newSalaryDate.setDate(newSalaryDate.getDate() + i);
                                tempReimbursement.id = null;
                                tempReimbursement.salaryDate = ChangeDateFormat("date", newSalaryDate);
                                tempReimbursement.hours = 0;
                                tempReimbursement.amount = 0;
                                tempReimbursement.type = "Hourly";
                                if (requestReimbursement.costBearerId && requestReimbursement.costBearerId !== "") {
                                    tempReimbursement.costBearerId = requestReimbursement.costBearerId;
                                }
                                if (requestReimbursement.date && requestReimbursement.date !== "") {
                                    tempReimbursement.date = requestReimbursement.date;
                                }
                                if (requestReimbursement.toDate && requestReimbursement.toDate !== "") {
                                    tempReimbursement.toDate = requestReimbursement.toDate;
                                }
                                if (requestReimbursement.purpose && requestReimbursement.purpose !== "") {
                                    tempReimbursement.purpose = requestReimbursement.purpose;
                                }
                                newFixeds.push(tempReimbursement);
                            }
                            setRequestReimbursementFixedList((prevFormData) => [
                                ...prevFormData,
                                ...newFixeds
                            ]);
                        }
                        // IF THERE ARE LESS FIXEDS THAN DAYS, ADD THE MISSING
                        else if (requestReimbursementFixedList.length < days) {
                            // HIDE TOOLTIP ERROR
                            $('#ErrorFormDate').addClass('d-none');
                            const existingDates = requestReimbursementFixedList.map(item => new Date(item.salaryDate));
                            existingDates.sort((a, b) => a - b);
                            const lastDate = existingDates[existingDates.length - 1];
                            const firstDate = existingDates[0];
                            const missingFixeds = days - requestReimbursementFixedList.length;
                            const newFixeds = [];
                            if (dateFormated < firstDate) {
                                for (let i = 1; i <= missingFixeds; i++) {
                                    const tempReimbursement = _.cloneDeep(requestReimbursementFixed);
                                    const newSalaryDate = new Date(firstDate);
                                    newSalaryDate.setDate(newSalaryDate.getDate() - i);
                                    tempReimbursement.id = null;
                                    tempReimbursement.salaryDate = ChangeDateFormat("date", newSalaryDate);
                                    tempReimbursement.hours = 0;
                                    tempReimbursement.amount = 0;
                                    tempReimbursement.type = "Hourly";
                                    if (requestReimbursement.costBearerId && requestReimbursement.costBearerId !== "") {
                                        tempReimbursement.costBearerId = requestReimbursement.costBearerId;
                                    }
                                    if (requestReimbursement.date && requestReimbursement.date !== "") {
                                        tempReimbursement.date = requestReimbursement.date;
                                    }
                                    if (requestReimbursement.toDate && requestReimbursement.toDate !== "") {
                                        tempReimbursement.toDate = requestReimbursement.toDate;
                                    }
                                    if (requestReimbursement.purpose && requestReimbursement.purpose !== "") {
                                        tempReimbursement.purpose = requestReimbursement.purpose;
                                    }
                                    // ADD FIRST
                                    newFixeds.unshift(tempReimbursement);
                                }
                            } else if (toDateFormated > lastDate) {
                                for (let i = 1; i <= missingFixeds; i++) {
                                    const tempReimbursement = _.cloneDeep(requestReimbursementFixed);
                                    const newSalaryDate = new Date(lastDate);
                                    newSalaryDate.setDate(newSalaryDate.getDate() + i);
                                    tempReimbursement.id = null;
                                    tempReimbursement.salaryDate = ChangeDateFormat("date", newSalaryDate);
                                    tempReimbursement.hours = 0;
                                    tempReimbursement.amount = 0;
                                    tempReimbursement.type = "Hourly";
                                    if (requestReimbursement.costBearerId && requestReimbursement.costBearerId !== "") {
                                        tempReimbursement.costBearerId = requestReimbursement.costBearerId;
                                    }
                                    if (requestReimbursement.date && requestReimbursement.date !== "") {
                                        tempReimbursement.date = requestReimbursement.date;
                                    }
                                    if (requestReimbursement.toDate && requestReimbursement.toDate !== "") {
                                        tempReimbursement.toDate = requestReimbursement.toDate;
                                    }
                                    if (requestReimbursement.purpose && requestReimbursement.purpose !== "") {
                                        tempReimbursement.purpose = requestReimbursement.purpose;
                                    }
                                    // ADD LAST
                                    newFixeds.push(tempReimbursement);
                                }
                            }
                            setRequestReimbursementFixedList((prevFormData) => [
                                ...prevFormData,
                                ...newFixeds
                            ]);
                        }
                    }
                    // IF ONY HAVE 1 DAY
                    else {
                        // HIDE TOOLTIP ERROR
                        $('#ErrorFormDate').addClass('d-none');
                        let foundFirst = false;
                        setRequestReimbursementFixedList(requestReimbursementFixedList.filter(item => {
                            if (foundFirst === false) {
                                item.salaryDate = requestReimbursement.date;
                                foundFirst = true;
                                return true;
                            }
                            return false;
                        }));
                    }
                }
                // SHOW TOOLTIP ERROR
                else {
                    $('#ErrorFormDate').removeClass('d-none');
                }
            }
            // ONLY DATE
            else {
                // IF THERE ARE MORE FIXEDS THAN 1 DELETE THE OUT OF RANGE
                if (requestReimbursementFixedList.length > 0) {
                    // HIDE TOOLTIP ERROR
                    $('#ErrorFormDate').addClass('d-none');
                    let foundFirst = false;
                    setRequestReimbursementFixedList(requestReimbursementFixedList.filter(item => {
                        if (foundFirst === false) {
                            item.salaryDate = requestReimbursement.date;
                            foundFirst = true;
                            return true;
                        }
                        return false;
                    }));
                }
                // IF THERE ARE NO FIXEDS CREATE ONE
                else {
                    // HIDE TOOLTIP ERROR
                    $('#ErrorFormDate').addClass('d-none');
                    setRequestReimbursementFixedList([requestReimbursementFixed]);
                }
            }
            // UPDATE DELETE FIXED
            if (deletedFixed) {
                setDeletedFixed(false);
            }
        }
    }, [requestReimbursement.date, requestReimbursement.toDate, deletedFixed, requestReimbursementFixedList[0]?.type]);

    // HANDLE CHANGE FIXED
    const HandleChangeFixed = (e, name, index = null, isFixedValue = null) => {
        setCheckUnsaved(true);
        let hoursValue = 0;
        let typeValue = "";
        let amountValue = 0;
        if (index !== null) {
            setIsFixed(false);
            hoursValue = requestReimbursementFixedList[index].hours;
            typeValue = requestReimbursementFixedList[index].type;
            amountValue = requestReimbursementFixedList[index].amount;
        } else if (isFixedValue !== null) {
            setIsFixed(true);
            hoursValue = requestReimbursementFixed.hours;
            typeValue = requestReimbursementFixed.type;
            amountValue = requestReimbursementFixed.amount;
        }
        switch (name) {
            case "hours":
                typeValue = "Hourly";
                hoursValue = 0;
                amountValue = 0;
                if (e?.target?.value && e?.target?.value !== "") {
                    hoursValue = CheckDecimal(e.target.value);
                    if (infoResponse && infoResponse.hourlySalary) {
                        amountValue = (hoursValue <= 14 ? hoursValue : 14) * infoResponse.hourlySalary.value;
                    }
                }
                break;
            case "amount":
                typeValue = "Fixed";
                hoursValue = null;
                amountValue = 0;
                if (e?.target?.value && e?.target?.value !== "") {
                    amountValue = CheckDecimal(e.target.value);
                }
                break;
            case "fixed":
                setIsFixed(true);
                typeValue = "Fixed";
                hoursValue = null;
                amountValue = 0;
                break;
            case "hourly":
                setIsFixed(false);
                typeValue = "Hourly";
                if (requestReimbursementFixed.hours && requestReimbursementFixed.hours !== 0) {
                    hoursValue = 0;
                    amountValue = 0;
                }
                break;
        }
        if (typeValue !== "") {
            if (index !== null && !isFixed) {
                setRequestReimbursementFixedList((prevList) =>
                    prevList.map((item, idx) => idx === index ? {
                        ...item,
                        hours: hoursValue,
                        type: typeValue,
                        amount: amountValue
                    } : item)
                );
            } else if (isFixedValue !== null && isFixed) {
                setRequestReimbursementFixed((prevFormData) => ({
                    ...prevFormData,
                    hours: hoursValue,
                    type: typeValue,
                    amount: amountValue,
                }));
            }
        }
    };

    // VALIDATE FIXED
    const HandleValidateFixed = async (fixeds = null) => {
        try {
            if (CheckRequired()) {
                // CHECK IF HOURS IS MORE THAN 0
                if (isFixed === false && fixeds !== null && fixeds.length > 0) {
                    let hoursSum = 0;
                    fixeds.map(async (item) => {
                        hoursSum += item.hours;
                    });
                    if (hoursSum > 0) {
                        $("#errorMovableAmount").addClass("d-none");
                        await Promise.all(
                            fixeds.map(async (item) => {
                                item.status = "Förslag";
                                return completeSchemaFixed.validate(item, {abortEarly: false});
                            })
                        );
                        setWasAddedFixed(false);
                        setRequestReimbursementFixedList(fixeds);
                        // DISABLE DATE AND TO DATE INPUTS
                        $("#date, #toDate, #checkInput").prop("disabled", true);
                        setDisplayFixed(false);
                        setEditModeFixed(false);
                    } else {
                        $("#errorMovableAmount").removeClass("d-none");
                    }
                } else {
                    if (requestReimbursementFixed.amount > 0) {
                        $("#errorFixedAmount").addClass("d-none");
                        setWasAddedFixed(true);
                        requestReimbursementFixed.status = "Förslag";
                        requestReimbursementFixed.salaryDate = requestReimbursement.date;
                        await completeSchemaFixed.validate(requestReimbursementFixed, {abortEarly: false});
                        setRequestReimbursementFixedList([requestReimbursementFixed]);
                        // DISABLE DATE AND TO DATE INPUTS
                        $("#date, #toDate, #checkInput").prop("disabled", true);
                        setDisplayFixed(false);
                        setEditModeFixed(false);
                    } else {
                        $("#errorFixedAmount").removeClass("d-none");
                    }
                }
            } else {
                if (isFixed === false && fixeds !== null && fixeds.length > 0) {
                    let hoursSum = 0;
                    fixeds.map(async (item) => {
                        hoursSum += item.hours;
                    });
                    if (hoursSum > 0) {
                        $("#errorMovableAmount").addClass("d-none");
                    } else {
                        $("#errorMovableAmount").removeClass("d-none");
                    }
                } else {
                    if (requestReimbursementFixed.amount > 0) {
                        $("#errorFixedAmount").addClass("d-none");
                    } else {
                        $("#errorFixedAmount").removeClass("d-none");
                    }
                }
            }
        } catch (error) {
            //console.log({error});
        }
    }

    // HANDLE CANCEL FORMS
    const HandleCancelFixed = (fixeds, fixed) => {
        // RELOAD OLD VALUES IN DATE AND TO DATE
        let dateValue = requestReimbursement.date;
        let toDateValue = requestReimbursement.toDate;
        if (wasAddedFixed === true) {
            dateValue = fixed.date;
            if (fixed.toDate && fixed.toDate !== "") {
                toDateValue = fixed.toDate;
            } else {
                toDateValue = null;
            }
            setRequestReimbursement((prevFormData) => ({
                ...prevFormData,
                date: dateValue,
                toDate: toDateValue,
            }));
            setRequestReimbursementFixed(fixed);
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                date: dateValue,
                toDate: toDateValue,
            }));
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                date: dateValue,
                toDate: toDateValue,
            }));
            if (requestReimbursementExpenseList.length > 0) {
                requestReimbursementExpenseList.map(item => {
                    item.date = dateValue;
                    item.toDate = toDateValue;
                });
                setRequestReimbursementExpenseList(requestReimbursementExpenseList);
            }
        } else {
            if (fixeds.length > 0) {
                dateValue = fixeds[0].date;
                if (fixeds[0].toDate && fixeds[0].toDate !== "") {
                    toDateValue = fixeds[0].toDate;
                } else {
                    toDateValue = null;
                }
                setRequestReimbursement((prevFormData) => ({
                    ...prevFormData,
                    date: dateValue,
                    toDate: toDateValue,
                }));
                setRequestReimbursementFixedList(fixeds);
                setRequestReimbursementMileage((prevFormData) => ({
                    ...prevFormData,
                    date: dateValue,
                    toDate: toDateValue,
                }));
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    date: dateValue,
                    toDate: toDateValue,
                }));
                if (requestReimbursementExpenseList.length > 0) {
                    requestReimbursementExpenseList.map(item => {
                        item.date = dateValue;
                        item.toDate = toDateValue;
                    });
                    setRequestReimbursementExpenseList(requestReimbursementExpenseList);
                }
            }
        }
        setDisplayFixed(false);
        setEditModeFixed(false);
        setIsFixed(wasAddedFixed);
        setTimeout(() => {
            $("#date, #toDate, #checkInput").prop("disabled", true);
        },100);
    }
    /********************************************* FIXED *********************************************/

    /********************************************* MILEAGE *******************************************/
    // HANDLE CHANGE MILEAGE
    const HandleChangeMileage = (e, name) => {
        setCheckUnsaved(true);
        let value = e?.target?.value && e?.target?.value !== "" ? e.target.value : "";
        let amountValue = requestReimbursementMileage.amount;
        let passengersValue = requestReimbursementMileage.passengers;
        let quantityPassengerValue = requestReimbursementMileage.passengers && Array.isArray(requestReimbursementMileage.passengers) && requestReimbursementMileage.passengers.length > 0 ? requestReimbursementMileage.passengers[0].quantity : 0;
        let kmPassengerValue = requestReimbursementMileage.passengers && Array.isArray(requestReimbursementMileage.passengers) && requestReimbursementMileage.passengers.length > 0 ? requestReimbursementMileage.passengers[0].km : 0;
        let amountPassengerValue = requestReimbursementMileage.passengers && Array.isArray(requestReimbursementMileage.passengers) && requestReimbursementMileage.passengers.length > 0 ? requestReimbursementMileage.passengers[0].amount : 0;
        let totalAmountValue = requestReimbursementMileage.totalAmount;
        switch (name) {
            case "km":
                value = 0;
                amountValue = 0;
                if (e && e.target && e?.target?.value !== "") {
                    value = CheckDecimal(e.target.value);
                    if (infoResponse.payableTravelPer10km) {
                        const calculateAmount = (value * infoResponse.payableTravelPer10km.value) / 10;
                        amountValue = parseFloat(calculateAmount.toFixed(2));
                    }
                }
                break;
            case "addPassenger":
                passengersValue = [];
                break;
            case "cancelPassenger":
                passengersValue = null;
                quantityPassengerValue = 0;
                kmPassengerValue = 0;
                break;
            case "quantityPassenger":
                quantityPassengerValue = e?.target?.value && e?.target?.value !== "" ? CheckDecimal(e.target.value) : 0;
                break;
            case "kmPassenger":
                kmPassengerValue = e?.target?.value && e?.target?.value !== "" ? CheckDecimal(e.target.value) : 0;
                break;
        }

        totalAmountValue = amountValue;
        if (passengersValue && infoResponse.payableTravelPassangerPer10km) {
            const calculateAmount = kmPassengerValue * infoResponse.payableTravelPassangerPer10km.value;
            let totalAmount = calculateAmount / 10;
            amountPassengerValue = parseFloat(totalAmount.toFixed(2));
            totalAmountValue = amountValue + amountPassengerValue;
            passengersValue[0] = {
                quantity: quantityPassengerValue,
                km: kmPassengerValue,
                amount: amountPassengerValue,
            };
        }
        if (name === "addPassenger" || name === "cancelPassenger" || name === "quantityPassenger" || name === "kmPassenger") {
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                amount: amountValue,
                passengers: passengersValue,
                totalAmount: totalAmountValue,
            }));
        }
        else {
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                amount: amountValue,
                passengers: passengersValue,
                totalAmount: totalAmountValue,
            }));
        }
    };

    // VALIDATE MILEAGE
    const HandleValidateMileage = async () => {
        try {
            if (CheckRequired()) {
                requestReimbursementMileage.status = "Förslag";
                await completeSchemaMileage.validate(requestReimbursementMileage, {abortEarly: false});
                if (requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0) {
                    await completeSchemaPassenger.validate(requestReimbursementMileage.passengers[0], {abortEarly: false});
                }
                setDisplayMileage(false);
                setEditModeMileage(false);
            }
        } catch (error) {
            //console.log({error});
        }
    }

    // CANCEL MILEAGE
    const HandleCancelMileage = (mileage) => {
        setRequestReimbursementMileage(mileage);
        setDisplayMileage(false);
        setEditModeMileage(false);
    }
    /********************************************* MILEAGE *******************************************/

    /********************************************* EXPENSE *******************************************/
    // HANDLE FILE
    const HandleUploadFile = async (e, setFileResponse, fileInfo) => {
        const formData = new FormData();
        let isValidFile = false;
        e.map(item => {
            if (item.type === 'image/png' || item.type === 'image/jpg' || item.type === 'image/gif' || item.type === 'image/jpeg' || item.type === "application/pdf" || item.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || item.type === "application/msword" || item.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item.type === "application/vnd.ms-excel" || item.type === "text/plain") {
                formData.append('file', item);
                isValidFile = true;
                if (fileInfo === "fileId") {
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        fileExtension: item.type,
                        fileName: item.name,
                    }));
                } else {
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        additionalFileExtension: item.type,
                        additionalFileName: item.name,
                    }));
                }
            }
        })
        if (isValidFile) {
            const apiCallOptions = {
                method: "post",
                url: "/file",
                data: formData,
                dataTypes: "file"
            };
            try {
                await ApiCall(apiCallOptions, setFileResponse);
            } catch (error) {
                //console.log({error});
            }
        } else {
            setFileResponse(null);
        }
    };

    // UPDATE FILE UPLOADED
    useEffect(() => {
        if (fileResponse !== null) {
            if (fileType === "fileId") {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    [fileType]: fileResponse.id,
                }));
            } else {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    additionalInfo: {
                        ...prevFormData.additionalInfo,
                        [fileType]: fileResponse.id
                    }
                }));
            }
        }
    }, [fileResponse]);

    // DELETE FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedFile === true) {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                fileName: "",
            }));
            setIsDeletedFile(false);
        }
    }, [isDeletedFile]);

    // DELETE EXTRA FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedExtraFile === true) {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                additionalInfo: {
                    ...prevFormData.additionalInfo,
                    [fileType]: ""
                },
                additionalFileExtension: "",
                additionalFileName: "",
            }));
            setIsDeletedExtraFile(false);
        }
    }, [isDeletedExtraFile]);

    // HANDLE CHANGE EXPENSE
    const HandleChangeExpense = async (e, name, extraFieldName = null) => {
        setCheckUnsaved(true);
        let dateReceiveValue = requestReimbursementExpense.dateReceive;
        let amountValue = requestReimbursementExpense.amount;
        let vatAmountValue = requestReimbursementExpense.vatAmount ?? '';
        let accountingIdValue = requestReimbursementExpense.accountingId;
        let additionalFileExtensionValue = requestReimbursementExpense.additionalFileExtension;
        let additionalFileNameValue = requestReimbursementExpense.additionalFileName;
        let programValue = requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo.program && requestReimbursementExpense.additionalInfo.program !== "" ? requestReimbursementExpense.additionalInfo.program : "";
        let additionalInfoValue = requestReimbursementExpense.additionalInfo;
        let accountingNameValue = requestReimbursementExpense.accountingName;
        switch (name) {
            case "fileId":
                await HandleUploadFile(e, setFileResponse, "fileId");
                setFileType("fileId");
                break;
            case "dateReceive":
                dateReceiveValue = ChangeDateFormat('date', e);
                break;
            case "amount":
                amountValue = 0;
                if (e?.target?.value && e.target.value !== "") {
                    amountValue = CheckDecimal(e.target.value);
                }
                break;
            case "vatAmount":
                vatAmountValue = '';
                if (e?.target?.value && e.target.value !== "") {
                    if (e.target.value !== null && e.target.value !== "") {
                        vatAmountValue = CheckDecimal(e.target.value);
                    }
                }
                break;
            case "accountingId":
                accountingIdValue = e.target.value;
                if (infoResponse.accountings && Array.isArray(infoResponse.accountings) && infoResponse.accountings.length > 0) {
                    infoResponse.accountings.map(item => {
                        if (item.id === accountingIdValue) {
                            accountingNameValue = item.showLabel;
                        }
                    });
                }
                if (infoResponse.accountingsExtraFields && Array.isArray(infoResponse.accountingsExtraFields) && infoResponse.accountingsExtraFields.length > 0) {
                    let extraFields = [];
                    infoResponse.accountingsExtraFields.map(item => {
                        if (item.accountingId === accountingIdValue) {
                            extraFields = item.extraFields;
                        }
                    });
                    if (extraFields.length > 0) {
                        const requestNameLower = StringToLowerCaseFirst(extraFields[0].requestName);
                        additionalInfoValue = {
                            [requestNameLower]: requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestNameLower] && requestReimbursementExpense.additionalInfo[requestNameLower] !== "" ? requestReimbursementExpense.additionalInfo[requestNameLower] : "",
                        };
                        setRequestName(requestNameLower);
                        setExtraField(extraFields[0]);
                        // IF EXTRA FIELDS ARE MORE THAN 1
                        if (extraFields.length > 1) {
                            const requestNameLower2 = StringToLowerCaseFirst(extraFields[1].requestName);
                            additionalInfoValue[requestNameLower2] = requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestNameLower2] && requestReimbursementExpense.additionalInfo[requestNameLower2] !== "" ? requestReimbursementExpense.additionalInfo[requestNameLower2] : "";
                            setRequestName2(requestNameLower2);
                            setExtraField2(extraFields[1]);
                        }
                    } else {
                        additionalInfoValue = null;
                    }
                }
                break;
            case "dynamic":
                if (e && Array.isArray(e)) {
                    if (extraFieldName !== null) {
                        setFileType(extraFieldName);
                    }
                    setTimeout(() => {
                        HandleUploadFile(e, setFileResponse, "dynamic");
                    }, 500);
                } else {
                    programValue = e.target.value;
                    additionalInfoValue.program = programValue;
                }
                break;
            case "radio":
                if (e?.target?.value) {
                    // TEXT AREA
                    if (e?.target?.value === "option1") {
                        additionalInfoValue.program = ""
                        additionalInfoValue.participantsFileId = null;
                        additionalFileExtensionValue = "";
                        additionalFileNameValue = "";
                    }
                    // FILE
                    else {
                        additionalInfoValue.program = null;
                        additionalInfoValue.participantsFileId = "";
                    }
                }
                break
        }
        if (infoResponse.accountingsIva && Array.isArray(infoResponse.accountingsIva) && infoResponse.accountingsIva.length > 0) {
            infoResponse.accountingsIva.map(item => {
                if (item.accountingId === accountingIdValue) {
                    vatAmountValue = null;
                }
            });
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            dateReceive: dateReceiveValue,
            amount: amountValue,
            vatAmount: vatAmountValue,
            accountingId: accountingIdValue,
            additionalInfo: additionalInfoValue,
            accountingName: accountingNameValue,
            additionalFileExtension: additionalFileExtensionValue,
            additionalFileName: additionalFileNameValue,
        }));
    };

    // VALIDATE EXPENSE
    const HandleValidateExpense = async () => {
        let exit = false;
        // MANUAL FIX FOR ACCOUNTINGS
        if (requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0) {
            if (requestReimbursementExpense?.accountingName === "Representation, avdragsgill (måltid max 60 kr)" || requestReimbursementExpense?.accountingName === "Representation, ej avdragsgill (måltid över 60 kr)" || requestReimbursementExpense?.accountingName === "Förtäring vid max 60 kr/pp (avdragsgillt)" || requestReimbursementExpense?.accountingName === "Förtäring och samkväm, ö 60 kr/pp samt gåvor (Ej avdragsg)") {
                const selectedRadio = $('#divRadioInputs input[type="radio"]:checked');
                if (selectedRadio.length > 0) {
                    $("#errorRadio").addClass("d-none");
                    const value = selectedRadio.val();
                    if (value === "option1") {
                        if (requestReimbursementExpense.additionalInfo.program !== undefined && requestReimbursementExpense.additionalInfo.program === "") {
                            exit = true;
                            $("#errorProgram").removeClass("d-none");
                        } else {
                            $("#errorProgram").addClass("d-none");
                        }
                    } else if (value === "option2") {
                        if (requestReimbursementExpense.additionalInfo.participantsFileId !== undefined && requestReimbursementExpense.additionalInfo.participantsFileId === "") {
                            exit = true;
                            $("#errorParticipantsFileId").removeClass("d-none");
                        } else {
                            $("#errorParticipantsFileId").addClass("d-none");
                        }
                    }
                } else {
                    exit = true;
                    $("#errorRadio").removeClass("d-none");
                }
            } else {
                if (requestReimbursementExpense.additionalInfo.program !== undefined && requestReimbursementExpense.additionalInfo.program === "") {
                    exit = true;
                    $("#errorProgram").removeClass("d-none");
                } else {
                    $("#errorProgram").addClass("d-none");
                }
                if (requestReimbursementExpense.additionalInfo.participantsFileId !== undefined && requestReimbursementExpense.additionalInfo.participantsFileId === "") {
                    exit = true;
                    $("#errorParticipantsFileId").removeClass("d-none");
                } else {
                    $("#errorParticipantsFileId").addClass("d-none");
                }
                if (requestReimbursementExpense.additionalInfo.programFileId !== undefined && requestReimbursementExpense.additionalInfo.programFileId === "") {
                    exit = true;
                    $("#errorProgramFileId").removeClass("d-none");
                } else {
                    $("#errorProgramFileId").addClass("d-none");
                }
            }
        }
        if (exit === false) {
            $("#errorProgram").addClass("d-none");
            $("#errorParticipantsFileId").addClass("d-none");
            $("#errorProgramFileId").addClass("d-none");
            try {
                if (CheckRequired()) {
                    requestReimbursementExpense.status = "Förslag";
                    if (requestReimbursementExpense.vatAmount >= 0 || requestReimbursementExpense.vatAmount === null) {
                        if (requestReimbursementExpense.amount > requestReimbursementExpense.vatAmount) {
                            await completeSchemaExpense.validate(requestReimbursementExpense, {abortEarly: false});
                            if (editModeExpense === true) {
                                setRequestReimbursementExpenseList((prevFormData) => {
                                    return prevFormData.map((item, i) => {
                                        if (i === expenseIndex) {
                                            return requestReimbursementExpense;
                                        } else {
                                            return item;
                                        }
                                    });
                                });
                                setExpenseIndex(null);
                            } else {
                                setRequestReimbursementExpenseList((prevFormData) => ([
                                    ...prevFormData,
                                    requestReimbursementExpense
                                ]));
                            }
                            setEditModeExpense(false);
                            setAddedExpense(true);
                            setLocalDisplayExpense(false);
                            setRequestReimbursementExpense((prevFormData) => ({
                                ...prevFormData,
                                fileId: "",
                                fileExtension: "",
                                amount: 0,
                                vatAmount: '',
                                accountingId: "",
                                accountingName: "",
                                additionalInfo: null,
                                additionalFileExtension: ""
                            }));
                            $("#accountingId").val("");
                        }
                    }
                }
            } catch (error) {
                //console.log({error})
            }
        } else {
            CheckRequired();
        }
    };

    // HANDLE CANCEL EXPENSE
    const HandleCancelExpense = (expense) => {
        if (editModeExpense === true) {
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.map((item, i) => {
                    if (i === expenseIndex) {
                        return expense;
                    } else {
                        return item;
                    }
                });
            });
            setExpenseIndex(null);
        } else {
            setRequestReimbursementExpenseList((prevFormData) => ([
                ...prevFormData,
                expense
            ]));
        }
        setEditModeExpense(false);
        setLocalDisplayExpense(false);
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            fileId: "",
            fileExtension: "",
            amount: 0,
            vatAmount: '',
            accountingId: "",
            accountingName: "",
            additionalInfo: null,
            additionalFileExtension: ""
        }));
        $("#accountingId").val("");
    }
    /********************************************* EXPENSE *******************************************/

    /********************************************* MODAL SEND ****************************************/
    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (responseReimbursement && responseReimbursement.id !== "") {
            let message = "";
            if (!isSend) {
                message = t('validationMessage.reimbursementSavedSuccessfully');

            } else {
                message = t('validationMessage.reimbursementSuccessfully');
            }
            FlashMessage(message, 'flash-messageSuccess');
            setTimeout(() => {
                CustomReload(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)
            }, 2000);
        }
    }, [responseReimbursement]);

    // SEND FORM
    const SubmitReimbursement = async () => {
        if (!isSend) {
            requestReimbursement.status = "Förslag";
        } else {
            requestReimbursement.status = "Skickat";
        }
        if (isSend && grossAmount <= 0) {
            setDisplaySendModal(false);
            FlashMessage(t('validationMessage.totalAmountMustBeMoreThan0'), 'flash-messageError');
            $("#forms").removeClass("d-none");
            return;
        }
        requestReimbursement.date = ChangeDateFormat("date", requestReimbursement.date);
        if (requestReimbursement.toDate && requestReimbursement.toDate !== "") {
            requestReimbursement.toDate = ChangeDateFormat("date", requestReimbursement.toDate);
        } else {
            requestReimbursement.toDate = null;
        }
        requestReimbursement.fixeds = null;
        requestReimbursement.mileage = null;
        requestReimbursement.expenses = null;
        if (!displayFixed && requestReimbursementFixedList.length > 0) {
            if (isFixed) {
                requestReimbursementFixed.hours = null;
                if (requestReimbursementFixed.costBearerId === "") {
                    requestReimbursementFixed.costBearerId = requestReimbursement.costBearerId;
                }
                if (requestReimbursementFixed.date === "") {
                    requestReimbursementFixed.date = requestReimbursement.date;
                }
                if (requestReimbursementFixed.toDate === "") {
                    requestReimbursementFixed.toDate = requestReimbursement.toDate;
                }
                if (requestReimbursementFixed.purpose === "") {
                    requestReimbursementFixed.purpose = requestReimbursement.purpose;
                }
                if (requestReimbursementFixed.salaryDate !== "") {
                    requestReimbursementFixed.salaryDate = ChangeDateFormat("date", requestReimbursementFixed.salaryDate);
                } else if (requestReimbursementFixed.salaryDate === "") {
                    requestReimbursementFixed.salaryDate = requestReimbursement.date;
                }
                requestReimbursement.fixeds = [
                    requestReimbursementFixed
                ];
            } else {
                requestReimbursementFixedList.map(item => {
                    if (item.costBearerId === "") {
                        item.costBearerId = requestReimbursement.costBearerId;
                    }
                    if (item.date === "") {
                        item.date = requestReimbursement.date;
                    }
                    if (item.toDate === "") {
                        item.toDate = requestReimbursement.toDate;
                    }
                    if (item.purpose === "") {
                        item.purpose = requestReimbursement.purpose;
                    }
                    if (item.salaryDate !== "") {
                        item.salaryDate = ChangeDateFormat("date", item.salaryDate);
                    }
                });
                requestReimbursement.fixeds = requestReimbursementFixedList;
            }
        }
        if (!displayMileage && requestReimbursementMileage.from && requestReimbursementMileage.from !== "") {
            if (requestReimbursementMileage.costBearerId === "") {
                requestReimbursementMileage.costBearerId = requestReimbursement.costBearerId;
            }
            if (requestReimbursementMileage.date === "") {
                requestReimbursementMileage.date = requestReimbursement.date;
            }
            if (requestReimbursementMileage.toDate === "") {
                requestReimbursementMileage.toDate = requestReimbursement.toDate;
            }
            if (requestReimbursementMileage.purpose === "") {
                requestReimbursementMileage.purpose = requestReimbursement.purpose;
            }
            requestReimbursement.mileage = requestReimbursementMileage;
        }
        if (addedExpense && requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(item => {
                if (item.costBearerId === "") {
                    item.costBearerId = requestReimbursement.costBearerId;
                }
                if (item.date === "") {
                    item.date = requestReimbursement.date;
                }
                if (item.toDate === "") {
                    item.toDate = requestReimbursement.toDate;
                }
                if (item.purpose === "") {
                    item.purpose = requestReimbursement.purpose;
                }
                if (item.additionalFileExtension === "") {
                    item.additionalFileExtension = null;
                }
                if (item.additionalFileName === "") {
                    item.additionalFileName = null;
                }
                if (item.dateReceive !== "") {
                    item.dateReceive = ChangeDateFormat("date", item.dateReceive);
                }
            });
            requestReimbursement.expenses = requestReimbursementExpenseList;
        }
        const apiCallOptions = {
            method: "post",
            url: "/reimbursement/v2",
            data: requestReimbursement,
        };
        try {
            if (!isSend) {
                await completeSchemaSave.validate(requestReimbursement, {abortEarly: false});
            } else {
                await completeSchema.validate(requestReimbursement, {abortEarly: false});
            }
            setDisplayFixed(false);
            setDisplayMileage(false);
            setDisplayExpense(false);
            $("#submitReimbursement, #saveReimbursement, #sendButton").prop("disabled", true);
            setDisplaySpinner(true);
            await ApiCall(apiCallOptions, setResponseReimbursement, setErrorMessage);
            setCheckUnsaved(false);
            setDisplaySendModal(false);
        } catch (error) {
            //console.log({error});
        }
    };

     useEffect(() => {
            if (errorMessage !== '') {        
                if (errorMessage === 'UserWithoutPnr') {
                    FlashMessage('Användaren har inte Pnr , därför är det inte möjligt att behandla denna begäran.', 'flash-messageError', 4500);
                }              
                else{
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }  
                setTimeout(()=>{CustomReload();},4800)       
            }
        }, [errorMessage]);

    const modalSendContent = (
        <div id="sendMessageDiv">
            {isSend === false ? t('validationMessage.saveReimbursement') : t('validationMessage.sendReimbursement')}
        </div>
    );
    const modalSendFooter = (
        <>
            <Button className="box-green border-0" id="sendButton" onClick={async () => {
                await SubmitReimbursement();
            }}>
                {isSend === false ? t('button.save') : t('button.send')}
            </Button>
            <Button className="box-red border-0" onClick={() => {
                setDisplaySendModal(false)
                $("#forms").removeClass("d-none");
            }}>{t('button.cancel')}</Button>
        </>
    );
    /********************************************* MODAL SEND ****************************************/

    /********************************************* MODAL DELETE FORMS ********************************/
    // DELETE FORMS
    const DeleteFixed = () => {
        let fixedAmount = 0;
        fixedAmount += requestReimbursementFixed.amount;
        if (requestReimbursementFixedList.length > 0) {
            requestReimbursementFixedList.map(item => {
                fixedAmount += item.amount;
            });
        }
        if (fixedAmount > 0) {
            setGrossAmount(grossAmount - fixedAmount);
        }
        setDisplayFixed(true);
        setEditModeFixed(false);
        setIsFixed(false);
        setDeletedFixed(true);
        $("#date, #toDate, #checkInput").prop("disabled", false);
        setRequestReimbursementFixed((prevFormData) => ({
            ...prevFormData,
            type: "",
            hours: 0,
            amount: 0,
            status: ""
        }));
        setRequestReimbursementFixedList([]);
    };

    // DELETE MILEAGE
    const DeleteMileage = () => {
        setGrossAmount(grossAmount - requestReimbursementMileage.totalAmount);
        setRequestReimbursementMileage((prevFormData) => ({
            ...prevFormData,
            from: "",
            to: "",
            km: 0,
            amount: 0,
            passengers: null,
            totalAmount: 0,
            status: "",
        }));
        setDisplayMileage(true);
    };

    // DELETE EXPENSE
    const DeleteExpense = (index = null) => {
        if (index !== null) {
            let expenseAmount = 0;
            if (requestReimbursementExpenseList.length > 0) {
                requestReimbursementExpenseList.map(item => {
                    expenseAmount += item.amount;
                });
            }
            if (expenseAmount > 0) {
                setGrossAmount(grossAmount - expenseAmount);
            }
            const expenseList = requestReimbursementExpenseList.filter((item, i) => i !== index);
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.filter((item, i) => i !== index);
            });
            setExpenseIndex(null);
            if (expenseList.length === 0) {
                setAddedExpense(false);
            }
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                amount: 0,
                vatAmount: '',
                accountingId: "",
                additionalInfo: null,
                additionalFileExtension: ""
            }));
            $("#accountingId").val("");
        }
    };

    // MODAL DELETE
    const HandleModalDelete = () => {
        switch (reimbursementToDelete) {
            case "Fixed":
                DeleteFixed();
                break;
            case "Mileage":
                DeleteMileage();
                break;
            case "Expense":
                DeleteExpense(expenseIndex);
                break;
            default:
                break;
        }
        setDisplayDeleteModal(false);
    };

    const modalDeleteContent = (
        <div>
            {reimbursementToDelete === "Fixed" ? t('validationMessage.deleteFixed') : reimbursementToDelete === "Mileage" ? t('validationMessage.deleteMileage') : t('validationMessage.deleteExpense')}
        </div>
    );
    const modalDeleteFooter = (
        <>
            <Button className="box-green border-0" onClick={HandleModalDelete}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModal(false)}>{t('button.cancel')}</Button>
        </>
    );
    /********************************************* MODAL DELETE FORMS ********************************/

    /********************************************* MODAL DELETE ****************************************/
    // HANDLE MODAL DELETE
    const HandleDeleteReimbursement = async () => {
        if (id !== "") {
            const apiCallOptions = {
                method: "delete",
                url: `/reimbursement/`,
                data: {id: id}
            };
            try {
                await ApiCall(apiCallOptions, setResponseDelete);
                setDisplayDeleteReimbursementModal(false);
            } catch (error) {
                //console.log({error});
            }
        }
    }

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (responseDelete && id !== "") {
            navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
        }
    }, [responseDelete]);

    const modalContentDeleteReimbursementModal = (
        <div>
            {`${t('validationMessage.modalVar')}?`}
        </div>
    );
    const modalFooterDeleteReimbursementModal = (
        <>
            <Button className="box-green border-0" onClick={HandleDeleteReimbursement}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteReimbursementModal(false)}>{t('button.cancel')}</Button>
        </>
    );
    /********************************************* MODAL DELETE ****************************************/

    // ID NULL IF IS NOT A DRAFT
    useEffect(() => {
        if (!draftResponse) {
            setRequestReimbursement((prevFormData) => ({
                ...prevFormData,
                id: null
            }));
            setRequestReimbursementFixed((prevFormData) => ({
                ...prevFormData,
                id: null
            }));
            if (requestReimbursementFixedList.length > 0) {
                requestReimbursementFixedList.map(item => {
                    item.id = null;
                });
                setRequestReimbursementFixedList(requestReimbursementFixedList);
            }
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                id: null,
                passengers: null,
            }));
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                id: null,
            }));
            if (requestReimbursementExpenseList.length > 0) {
                requestReimbursementExpenseList.map(item => {
                    item.id = null;
                });
                setRequestReimbursementExpenseList(requestReimbursementExpenseList);
            }
        }
    }, [draftResponse]);

    // SELECTED ORG
    useEffect(() => {
        if (selectedOrgId !== "") {
            let costBearerId = requestReimbursement.costBearerId;
            if (infoResponse && infoResponse.orgsByUser && Array.isArray(infoResponse.orgsByUser) && infoResponse.orgsByUser.length > 0 && infoResponse.costBearersByOrgType && Array.isArray(infoResponse.costBearersByOrgType) && infoResponse.costBearersByOrgType.length > 0 && infoResponse.costBearers && Array.isArray(infoResponse.costBearers) && infoResponse.costBearers.length > 0) {
                // COST BEARERS
                let orgType = "";
                infoResponse.orgsByUser.map(item => {
                    if (item.id === selectedOrgId) {
                        orgType = item.type;
                        setSelectedOrgName(item.name);
                    }
                });
                const costBearersByOrgType = infoResponse.costBearersByOrgType.find(item => item.type === orgType);
                if (costBearersByOrgType) {
                    const costBearerJsonArray = infoResponse.costBearers.filter(cb => costBearersByOrgType.costBearerNames.includes(cb.name)).map(cb => ({
                        id: cb.id,
                        name: cb.name
                    }));

                    if (costBearerJsonArray.length > 0 && !costBearerJsonArray.find(item => item.id === selectedCostBearerId)) {
                        setSelectedCostBearerId("");
                        setSelectedCostBearerName("");
                        costBearerId = "";
                    }

                    setCostBearers({
                        costBearer: costBearerJsonArray
                    });
                }
            }

            setRequestReimbursement((prevFormData) => ({
                ...prevFormData,
                orgId: selectedOrgId,
                costBearerId: costBearerId,
            }));
            setRequestReimbursementFixed((prevFormData) => ({
                ...prevFormData,
                orgId: selectedOrgId,
                costBearerId: costBearerId,
            }));
            if (requestReimbursementFixedList.length > 0) {
                requestReimbursementFixedList.map(item => {
                    item.orgId = selectedOrgId;
                    item.costBearerId = costBearerId;
                });
                setRequestReimbursementFixedList(requestReimbursementFixedList);
            }
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                orgId: selectedOrgId,
                costBearerId: costBearerId,
            }));
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                orgId: selectedOrgId,
                costBearerId: costBearerId,
            }));
            if (requestReimbursementExpenseList.length > 0) {
                requestReimbursementExpenseList.map(item => {
                    item.orgId = selectedOrgId;
                    item.costBearerId = costBearerId;
                });
                setRequestReimbursementExpenseList(requestReimbursementExpenseList);
            }
        }
    }, [selectedOrgId]);

    // SELECTED COST BEARER
    useEffect(() => {
        if (selectedCostBearerId !== "" && infoResponse && infoResponse.costBearers && Array.isArray(infoResponse.costBearers) && infoResponse.costBearers.length > 0) {
            infoResponse.costBearers.map(item => {
                if (item.id === selectedCostBearerId) {
                    setSelectedCostBearerName(item.name);
                }
            });
        }
    }, [selectedCostBearerId]);

    // CALCULATE GROSS AMOUNT
    useEffect(() => {
        let tempAmount = 0;
        // SUM FIXED AMOUNT
        if (isFixed) {
            tempAmount += requestReimbursementFixed.amount;
        } else {
            if (requestReimbursementFixedList.length > 0) {
                requestReimbursementFixedList.map(item => {
                    tempAmount += item.amount;
                });
            }
        }
        // SUM MILEAGE AMOUNT
        tempAmount += requestReimbursementMileage.totalAmount;
        // SUM EXPENSES AMOUNT
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(item => {
                tempAmount += item.amount;
            });
            // ORDER EXPENSES BY DATE RECEIVE
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.sort((a, b) => {
                    return new Date(a.dateReceive) - new Date(b.dateReceive);
                });
            });
        }

        setGrossAmount(tempAmount);
    }, [displayFixed, displayMileage, addedExpense, requestReimbursementExpenseList]);

    // CHECK IF HAVE SOME CHANGES TO CHANGE MODAL MESSAGE
    const checkIfHaveChanges = (typeSend = null) => {
        // FIXED
        const hours = document.getElementsByClassName("hourDiv");
        const fixedAmount = $("#fixedAmount").val();
        // MILEAGE
        const origin = $("#origin").val();
        const destination = $("#destination").val();
        const km = $("#km").val();
        const number = $("#number").val();
        const distance = $("#distance").val();
        // EXPENSE
        const accounting = $("#accounting").val();
        const expenseAmount = $("#expenseAmount").val();
        if ((fixedAmount && fixedAmount !== "") || (origin && origin !== "") || (destination && destination !== "") || (km && km !== "") || (number && number !== "") || (distance && distance !== "") || (accounting && accounting !== "") || (expenseAmount && expenseAmount !== "")) {
            setTimeout(() => {
                $("#sendMessageDiv").html(typeSend === null ? t('validationMessage.sendReimbursementNotSaved') : t('validationMessage.sendReimbursementNotSavedSave'));
            }, 80);
        }
        if (hours) {
            const hourArray = Array.from(hours);
            hourArray.map(input => {
                if ($(input).find("input").val() !== "") {
                    setTimeout(() => {
                        $("#sendMessageDiv").html(typeSend === null ? t('validationMessage.sendReimbursementNotSaved') : t('validationMessage.sendReimbursementNotSavedSave'));
                    }, 80);
                }
            });
        }
    }

    const messageCostBearer = InputMessage(infoResponse && infoResponse.costBearers ? infoResponse?.costBearers : null, requestReimbursement.costBearerId);

    return (
        <>
            {permissionCreate ?
                <Col lg={12} className="px-0">
                    <CustomButton text={t('button.back')} icon={'bx bx-chevron-left'} buttonSize={'small'} customClasses={'mb-3 ms-3 backButton'}
                        onClick={() => {navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)}}
                    />
                </Col>
            : ""}
            {infoResponse ?
                <>
                    {infoResponse.userBankAccount && (infoResponse.userBankAccount.clearingNumber === undefined || infoResponse.userBankAccount.accountNumber === undefined) ?
                        <Col lg={10} className="box-red mt-2 p-1 mx-auto">
                            {t('validationMessage.needAccount')} <Link className='underline' to={USER_PATHS.SETTINGS}>{t('validationMessage.goAddAccount')}</Link>
                        </Col>
                    :
                        <>
                            {checkUnsaved === true ? <CustomUnsavedModal backButtonUrl={REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST} /> : ''}
                            <CustomModal isOpen={displayDeleteReimbursementModal} toggle={() => setDisplayDeleteReimbursementModal(!displayDeleteReimbursementModal)} body={modalContentDeleteReimbursementModal} footer={modalFooterDeleteReimbursementModal} />
                            <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)} body={modalDeleteContent} footer={modalDeleteFooter} />
                            <CustomModal isOpen={displaySendModal} toggle={() => setDisplaySendModal(!displaySendModal)} body={modalSendContent} footer={modalSendFooter} />
                            <Col lg={6} id="formReimbursement">
                                <Card className="card-backend p-4">
                                    <Row>
                                        <Col xs='auto'>
                                            <h2 className='rokkitt-font'> {t('reimbursement.reportProcessing')}</h2>
                                        </Col>
                                        <InfoPageMessage message={t('infoBox.reportProceeding')}/>
                                    </Row>
                                    <Row>
                                        <Col lg={6} onClick={() => $('.react-datepicker__tab-loop').removeClass('d-none')}>
                                            <label className="d-block form-label">
                                                {requestReimbursement.toDate !== null ? t('modelLabel.fromDateReimbursement') : t('modelLabel.dateReimbursement')}: </label>
                                            <div className="position-relative">
                                                <CustomInput model={RequestReimbursementV2} attribute='date' data={requestReimbursement.date} customChange={(e) => HandleChangeCommon(e, 'date')} />
                                                <div id="ErrorFormDate" className="error tooltip-custom show-custom d-none" style={{ color: '#f56e6e' }}>{t('validationMessage.dateMustBeLessThanToDate')}</div>
                                            </div>
                                            {requestReimbursement.toDate !== null ?
                                                <CustomInput model={RequestReimbursementV2} attribute='toDate' data={requestReimbursement.toDate} minDate={requestReimbursement.date} customChange={(e) => HandleChangeCommon(e, 'toDate')} />
                                            : ''}
                                            <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <label>{t('modelLabel.multipleDayEvent')}: </label>
                                                <input style={{ marginTop: '4px' }} id="checkInput" checked={requestReimbursement.toDate !== null ? true : false} type="checkbox" onChange={(e) => HandleChangeCommon(e, 'check')} />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <CustomInput model={RequestReimbursementV2} attribute='orgId' data={selectedOrgId} customChange={(e) => HandleChangeCommon(e, 'orgId')} customOptions={orgs} />
                                        </Col>
                                        <Col lg={6}>
                                            <CustomInput model={RequestReimbursementV2} attribute='purpose' data={requestReimbursement.purpose} customChange={(e) => HandleChangeCommon(e, 'purpose')} />
                                        </Col>
                                        <Col lg={6}>
                                            {costBearers && costBearers.costBearer && selectedOrgId !== "" ?
                                                <>
                                                    <CustomInput model={RequestReimbursementV2} attribute='costBearerId' data={selectedCostBearerId} customChange={(e) => HandleChangeCommon(e, 'costBearerId')} customOptions={costBearers} />
                                                    {messageCostBearer}
                                                </>
                                            : ''}
                                        </Col>
                                    </Row>
                                    <div id="forms">
                                        {/* FIXED COMPONENT */}
                                        {displayFixed ?
                                            <ReimbursementFixed data={requestReimbursementFixedList} dataFixed={requestReimbursementFixed} onChange={HandleChangeFixed} onValidate={HandleValidateFixed} onCancel={HandleCancelFixed} editMode={editModeFixed} isFixed={isFixed} />
                                        : ''}
                                        {/* MILEAGE COMPONENT */}
                                        {displayMileage ?
                                            <ReimbursementMileage data={requestReimbursementMileage} onChange={HandleChangeMileage} onValidate={HandleValidateMileage} onCancel={HandleCancelMileage} editMode={editModeMileage} />
                                        : ''}
                                        {/* EXPENSE COMPONENT */}
                                        {displayExpense ?
                                            <ReimbursementExpense data={requestReimbursementExpense} onChange={HandleChangeExpense} onValidate={HandleValidateExpense} onCancel={HandleCancelExpense} editMode={editModeExpense} accountingJson={accountings} requestName={requestName} extraField={extraField} setIsDeletedFile={setIsDeletedFile} setIsDeletedExtraFile={setIsDeletedExtraFile} extraField2={extraField2} localDisplay={localDisplayExpense} setLocalDisplay={setLocalDisplayExpense} /> : ''}
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} id="viewReimbursement">
                                {draftResponse && draftResponse.denied ?
                                    <Card className="card-backend p-4 mb-3">
                                        <Row>
                                            <Col lg={12} className="text-center">
                                                <h2 className="text-red rokkitt-font">{t('reimbursement.deniedMessageCreate')}</h2>
                                            </Col>
                                            <Col lg={12} className="mb-3">
                                                <Card className="bg-card-grey p-3">
                                                    <h5 className='rokkitt-font'>{t('attest.deniedBy')}</h5>
                                                    {draftResponse.denied.approvedDeniedByName}
                                                    <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                                    {draftResponse.denied.reason}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card>
                                : ""}
                                <Card className="card-backend p-4">
                                    <Row>
                                        <Col lg={12} className="text-start">
                                            <h2 className='rokkitt-font'> {t('modelLabel.added')}</h2>
                                            <h5 className="rokkitt-font">{t('reimbursement.reportProcessings')}</h5>
                                        </Col>
                                        <Col lg={12}>
                                            {/* COMMON */}
                                            {requestReimbursement?.date !== '' || requestReimbursement?.costBearerId !== '' || requestReimbursement?.purpose !== '' || requestReimbursement?.orgId !== '' ?
                                                <CustomReimbursementView data={requestReimbursement} costSelected={selectedCostBearerName} orgSelected={selectedOrgName} />
                                            : ''}
                                        </Col>
                                        <Col lg={12}>
                                            {/* FIXED */}
                                            {(requestReimbursementFixedList.length > 0 && requestReimbursementFixedList.some(item => item.amount > 0) && !displayFixed && !editModeFixed) || (!displayFixed && !editModeFixed && isFixed) ?
                                                <>
                                                    <h5 className='rokkitt-font mt-2'>{t('reimbursement.salary')}</h5>
                                                    <CustomReimbursementFixedView data={requestReimbursementFixedList} editMode={editModeFixed} dataFixed={requestReimbursementFixed}
                                                          isFixed={isFixed}
                                                          onClickDelete={() => {
                                                              setDisplayDeleteModal(true);
                                                              setReimbursementToDelete('Fixed');
                                                          }}
                                                          onClickEdit={(item) => {
                                                              $("#date, #toDate, #checkInput").prop("disabled", false);
                                                              setDisplayFixed(true);
                                                              if (item !== null) {
                                                                  setWasAddedFixed(false);
                                                              } else {
                                                                  setWasAddedFixed(true);
                                                              }
                                                              setEditModeFixed(true);
                                                          }}
                                                    />
                                                </>
                                            : ''}
                                        </Col>
                                        <Col lg={12}>
                                        {/* Mileage */}
                                        {!displayMileage && !editModeMileage && requestReimbursementMileage.from && requestReimbursementMileage.from !== "" ?
                                            <>
                                                <h5 className='rokkitt-font mt-2'>{t('reimbursement.mileage')}</h5>
                                                <CustomReimbursementMileageView data={requestReimbursementMileage} editMode={editModeMileage}
                                                    onClickDelete={() => {
                                                        setDisplayDeleteModal(true);
                                                        setReimbursementToDelete('Mileage');
                                                    }}
                                                    onClickEdit={() => {
                                                        setDisplayMileage(true);
                                                        setEditModeMileage(true);
                                                    } }
                                                />
                                            </>
                                        : ''}
                                        </Col>
                                        <Col lg={12}>
                                        {/* EXPENSE */}
                                        {requestReimbursementExpenseList.length > 0 && addedExpense && !editModeExpense ?
                                            <>
                                                <h5 className='rokkitt-font mt-2'>{t('reimbursement.expenseTitle')}</h5>
                                                <CustomReimbursementExpenseView data={requestReimbursementExpenseList} accountingResponse={infoResponse.accountings} editMode={editModeExpense}
                                                    onClickDelete={(item,index) => {
                                                        setDisplayDeleteModal(true);
                                                        setReimbursementToDelete('Expense');
                                                        setExpenseIndex(index);
                                                    }}
                                                    onClickEdit={(item,index) => {
                                                        if (infoResponse.accountingsIva && Array.isArray(infoResponse.accountingsIva) && infoResponse.accountingsIva.length > 0) {
                                                            infoResponse.accountingsIva.map(item2 => {
                                                                if (item2.accountingId === item.accountingId) {
                                                                    item.vatAmount = null;
                                                                }
                                                            });
                                                        }
                                                        HandleChangeExpense({target: {value: item.accountingId}}, 'accountingId');
                                                        setRequestReimbursementExpense(item);
                                                        setExpenseIndex(index);
                                                        setEditModeExpense(true);
                                                    }}
                                                />
                                            </>
                                        : ''}
                                        </Col>
                                        <Col lg={12}>
                                        {/* SEND FORM */}
                                            <Row className="text-center pt-4">
                                                {grossAmount > 0 && ((requestReimbursementFixedList.length > 0 && !displayFixed) || !displayMileage || (addedExpense === true && requestReimbursementExpenseList.length > 0 )) && !editModeFixed && !editModeMileage && !editModeExpense ?
                                                    <Col lg={12} className="pb-4 rokkitt-font fs-5">
                                                        <span className="fw-bold fs-5 text-green"> {t('modelLabel.grossAmount')}: </span> {ToCustomDecimal(grossAmount)} kr
                                                    </Col>
                                                : ''}
                                                <Col lg={12} className="text-center pb-3">
                                                    {displaySpinner === true ?
                                                        <>
                                                            {!isSend ? t('validationMessage.savingReimbursement') : t('validationMessage.sendingReimbursement')} <Spinner color='success'/> <br/>
                                                        </>
                                                    : ""}
                                                    {grossAmount <= 0 ? t("reimbursement.sendInfo") : ""}
                                                </Col>
                                                <Col lg={12} className="d-flex justify-content-center justify-content-lg-end">
                                                    {id !== "" ?
                                                        <Button className=" rounded-2 box-red border-0  position-relative me-2 d-flex align-items-center" onClick={() => {
                                                            setDisplayDeleteReimbursementModal(true);
                                                        }}>
                                                            <i className="bx bx-x-circle fs-5 pe-1"/> {t("button.delete")}
                                                        </Button>
                                                    : ''}
                                                    <CustomSubmitButton customClass="me-2" text={t('button.saveReimbursement')} id='saveReimbursement' icon='bx bx-save pe-2' doClick={false}
                                                        customClick={async () => {
                                                            if (CheckRequired()) {
                                                                $("#forms").addClass("d-none");
                                                                setIsSend(false);
                                                                checkIfHaveChanges("save");
                                                                setDisplaySendModal(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomSubmitButton text={t('button.send')} id='submitReimbursement' icon='bx bx-send pe-2'
                                                        customClick={async () => {
                                                            if (CheckRequired()) {
                                                                $("#forms").addClass("d-none");
                                                                setIsSend(true);
                                                                checkIfHaveChanges();
                                                                setDisplaySendModal(true);
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </>
                    }
                </>
            : ""}
        </>
    );
}