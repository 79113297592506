import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class AdminResponseAttestModel {
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    orgName = '',
                    orgType = '',
                    costPlace = '',
                    model = '',
                    modelId = '',
                    destinationOrgId = '',
                    destinationOrgName = '',
                    modelInfo = [],
                    amount = '',
                    status = '',
                    reason = '',
                    recipient = '',
                    approvedDeniedByName = '',
                    createdAt =  '',
                    createdBy = '',
                    createdByName = '',
                    updatedAt = '',
                    updatedBy = '',
                    updatedByName = '',
                    modelStartDate = '',
                    modelVisualId = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.orgType = orgType;
        this.costPlace = costPlace;
        this.model = model;
        this.modelId = modelId;
        this.destinationOrgId = destinationOrgId;
        this.destinationOrgName = destinationOrgName;
        this.modelInfo = modelInfo;
        this.amount = amount;
        this.status = status;
        this.reason = reason;
        this.recipient = recipient;
        this.approvedDeniedByName = approvedDeniedByName;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.createdByName = createdByName;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.updatedByName = updatedByName;
        this.modelStartDate = modelStartDate;
        this.modelVisualId = modelVisualId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const createdBy = i18n.t('modelLabel.createdBy');

        const attributes = {
            id :{
                'label': "ID",
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.org'),
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': i18n.t('modelLabel.org'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            orgType :{
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                'sortField': 'orgType',
                'value': (item) => item.orgType || "",
            },
            costPlace :{
                'label': i18n.t('modelLabel.KST'),
                'sortable': true,
                'sortField': 'costPlace',
                'value': (item) => item.costPlace || "",
            },
            model :{
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                'sortField': 'model',
                'value': (item) => item.model === 'Förrättning' ? i18n.t('reimbursement.reportProcessing') : item.model === 'Arvode' ? i18n.t('honorarium.honorarium') : item.model === 'Betala' ? i18n.t('payReceive.pay') : item.model === 'Intern transaktion' ? i18n.t('internalTransaction.internalTransaction') : item.model === 'Faktura' ? i18n.t('invoice.invoice') : item.model || "",
                'type': 'select' ,
            },
            modelId :{
                'label': i18n.t('modelLabel.typeId'),
                'sortable': true,
                'sortField': 'modelId',
                'value': (item) => item.modelId || "",
            },
            destinationOrgId :{
                'label': i18n.t('modelLabel.orgDestination'),
                'sortable': true,
                'sortField': 'destinationOrgId',
                'value': (item) => item.destinationOrgId || "",
            },
            destinationOrgName :{
                'label': i18n.t('modelLabel.orgDestination'),
                'sortable': true,
                'sortField': 'destinationOrgName',
                'value': (item) => item.destinationOrgName || "",
            },
            modelInfo: {
                'label': 'Model Info',
                'sortable': true,
                'sortField': 'modelInfo',
                'value': (item) => item.modelInfo || "",
            },
            amount :{
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                'sortField': 'amount',
                'insideClass' : (item)=> item.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.amount ? ToCustomDecimal(item.amount) +' kr' : "",
                'thClass': 'ps-5',
                'type': 'number' ,
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': "status",
                'insideClass' : (item)=> item.status === 'Godkänd' ? 'box-green me-4 fixed-width' : (item.status === 'Utbetald' ? "box-green me-4 fixed-width" :(item.status === 'Nekad' ? "box-red me-4 fixed-width" : 'box-custom-grey me-4 fixed-width')),
                'value': (item) => item.status === 'Skickat' ? i18n.t('reimbursement.sent') : item.status === 'Nekad' ? i18n.t('attest.denied') : item.status === 'Godkänd' ? i18n.t('attest.approved') : item.status === 'Utbetald' ? i18n.t('modelLabel.paidOut') : '' || "",
                'type': 'select' ,
            },
            reason :{
                'label': i18n.t('modelLabel.reason'),
                'sortable': true,
                'sortField': "reason",
                'value': (item) => item.reason || "",
            },
            recipient :{
                'label': i18n.t('modelLabel.recipient'),
                'sortable': true,
                'sortField': "recipient",
                'value': (item) => item.recipient || "",
            },
            approvedDeniedByName :{
                'label': i18n.t('modelLabel.approvedDeniedByName'),
                'sortable': true,
                'sortField': "approvedDeniedByName",
                'value': (item) => item.approvedDeniedByName || "",
            },
            createdAt :{
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : ''|| "",
                'type': 'dateTime' ,
            },
            createdBy :{
                'label': createdBy,
                'sortable': true,
                'sortField': 'createdBy',
                'value': (item) => item.createdBy || "",
                'type': 'dateTime' ,
            },
            createdByName :{
                'label': i18n.t('modelLabel.recipient'),
                'sortable': true,
                'sortField': 'createdByName',
                'value': (item) => item.model === 'Arvode' ? item.recipient : item.createdByName || "",
            },
            updatedAt :{
                'label': i18n.t('modelLabel.approvedDeniedAt'),
                'sortable': true,
                'sortField': 'updatedAt',
                'value': (item) => item.updatedAt ? ChangeDateFormat('dateTime', item.updatedAt) : ''|| "",
                'type': 'dateTime' ,
            },
            updatedBy :{
                'label': 'Updated By',
                'sortable': true,
                'sortField': 'updatedBy',
                'value': (item) => item.updatedBy || "",
            },
            updatedByName :{
                'label': 'Updated By Name',
                'sortable': true,
                'sortField': 'updatedByName',
                'value': (item) => item.updatedByName || "",
            },
            modelStartDate :{
                'label': i18n.t('modelLabel.dateReimbursement'),
                'sortable': true,
                'sortField': 'modelStartDate',
                'value': (item) => item.modelStartDate ? ChangeDateFormat('date', item.modelStartDate) : ''|| "",
                'type': 'dateTime' ,
            },
            modelVisualId :{
                'label': i18n.t('modelLabel.typeId'),
                'sortable': true,
                'sortField': 'modelVisualId',
                'value': (item) => item.modelVisualId || "",
            },
        }
        return attributes;
    }
}
export default AdminResponseAttestModel;