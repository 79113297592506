import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {Button, Col, Input, Label, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import SuperAdminResponseTransaction from "../Models/Response/SuperAdminResponseTransaction";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import React, {useEffect, useState} from "react";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";

const SuperAdminTransactionList = () => {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    // MODAL VALUES
    const [displayModal, setDisplayModal] = useState(false);
    const [modalContent, setModalContent] = useState("");

    // SPINNER DISPLAY
    const [displaySpinner, setDisplaySpinner] = useState(false);

    // CONSENT CODE
    const [code, setCode] = useState("");
    const [errorCode, setErrorCode] = useState(false);

    // RESPONSES
    const [responseError, setResponseError] = useState("");
    const [responseRenew, setResponseRenew] = useState("");
    const [responseAuthorize, setResponseAuthorize] = useState("");
    const [responseTransactions, setResponseTransactions] = useState("");


    // RENEW CONSENT
    const RenewConsent = async () => {
        const apiCallOptions = {
            method: "get",
            url: '/open-payment/admin/consent/renew',
        };
        await ApiCall(apiCallOptions, setResponseRenew, setResponseError);
    };

    useEffect(() => {
        if (responseRenew !== "" && responseRenew && responseRenew.id) {
            FlashMessage(t('validationMessage.renewConsentSuccessfully'), 'flash-messageSuccess');
            setTimeout(() => {
                CustomReload();
            }, 2300);
        }
    }, [responseRenew]);

    // AUTHORIZE CONSENT
    const AuthorizeConsent = async () => {
        const apiCallOptions = {
            method: "get",
            url: '/open-payment/admin/consent/authorize',
        };
        await ApiCall(apiCallOptions, setResponseAuthorize, setResponseError);
    };

    useEffect(() => {
        if (responseAuthorize !== "" && responseAuthorize) {
            FlashMessage(t('validationMessage.consentAuthorizationPage'), 'flash-messageSuccess');
            setTimeout(() => {
                const link = document.createElement('a');
                link.href = responseAuthorize;
                link.rel = 'noopener noreferrer';
                link.target = '_blank';
                link.click();
                setDisplaySpinner(false);
            }, 2300);
        }
    }, [responseAuthorize]);

    // UPDATE TRANSACTIONS
    const UpdateTransactions = async () => {
        // GET TODAY IN STRING
        const today = new Date();
        today.setDate(today.getDate() - 88);
        const daysBefore = ChangeDateFormat("date", today.toString("yyyy-MM-dd"));
        const apiCallOptions = {
            method: "get",
            url: '/open-payment/admin/account/transaction-list?status=both&fromDate=' + daysBefore
        };
        await ApiCall(apiCallOptions, setResponseTransactions, setResponseError);
    };

    useEffect(() => {
        if (responseTransactions !== "" && responseTransactions) {
            FlashMessage(t('validationMessage.updateTransactionsSuccessfully'), 'flash-messageSuccess');
            setTimeout(() => {
                CustomReload();
            }, 2300);
        }
    }, [responseTransactions]);

    // ERROR RESPONSE
    useEffect(() => {
        if (responseError !== "") {
            if (responseError.includes("Something went wrong") || responseError.includes("Något gick fel")) {
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
            else if (responseError.includes("ConsentExpired")) {
                FlashMessage(t('validationMessage.consentExpired'), 'flash-messageError');
            }
            else if (responseError.includes("ConsentNotFound")) {
                FlashMessage(t('validationMessage.consentNotFound'), 'flash-messageError');
            }
            else if (responseError.includes("AccountIdNotValid")) {
                FlashMessage(t('validationMessage.accountIdNotValid'), 'flash-messageError');
            }
            else if (responseError.includes("ServiceUnavailableTryAgain")) {
                FlashMessage(t('validationMessage.serviceUnavailableTryAgain'), 'flash-messageError');
            } else if (responseError.includes("ConsentRejected")) {
                FlashMessage(t('validationMessage.consentRejected'), 'flash-messageError');
            }
            setTimeout(() => {
                setDisplaySpinner(false);
                setResponseError("");
            }, 2300);
        }
    }, [responseError]);

    // LIST
    const renderActionColumn = (item) => {
        return (
            <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false} id={item.id} onClick={() => {
                setDisplayModal(true);
                UpdateModalContent(item);
            }} />
        );
    }

    const renderButtonColumn = () => {
        return (
            <Col lg={12} className="mt-4 px-3">
                <Button className="btn-submit-kassan btn-sm rounded" disabled={displaySpinner} onClick={() => {
                    setDisplaySpinner(true);
                    UpdateTransactions();
                }}>
                    <i className="bx bx-sync pe-2 align-middle size-icon-custom-button"/>{t('modelLabel.updateTransactions')}
                </Button>
            </Col>
        );
    }

    const ignoreFields = ["Id", "OpenPaymentAccountId", "OpenPaymentTransactionId", "Status", "RemittanceInformationStructured", "TransactionListId", "BicFi"];
    const columns = [
        "valueDate",
        "remittanceInformationUnstructured",
        "bookingDate",
        "displayStatus",
        "transactionAmount",
        "balanceAfterTransaction"
    ];
    const listOptions = {
        "model": SuperAdminResponseTransaction,
        "columns": columns,
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/open-payment/superadmin/transaction/search/filter",
        "nameFile": t('modelLabel.transactions'),
        "ignoreFields" : ignoreFields,
        "filterTestIsAvailable": true,
        "urlGetSearch" : "/open-payment/superadmin/transaction/search/filter",

    };

    // MODAL
    const UpdateModalContent = (item) => {
        setModalContent(
            <>
                <div className="text-end me-3">
                    <span className="cursor-pointer-custom" onClick={() => setDisplayModal(false)}>
                        X
                    </span>
                </div>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        MongoDb ID:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.id}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.openPaymentAccountId')}:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.openPaymentAccountId ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.openPaymentTransactionId')}:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.openPaymentTransactionId ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.bookedDate')}:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.bookingDate ? ChangeDateFormat("date", item.bookingDate) : ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.openPaymentStatus')}:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.status ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.status')}:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.displayStatus ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.amount')}:
                    </Col>
                    <Col className="col-6 text-start">
                        <div className={`${item.transactionAmount && item.transactionAmount.amount ? item.transactionAmount.amount >= 0 ? 'text-green' : 'text-red' : ""}`}>{item.transactionAmount && item.transactionAmount.amount ?
                            ToCustomDecimal(parseFloat(item.transactionAmount.amount)) + " " + (item.transactionAmount.currency === "EUR" ? "€" : (item.transactionAmount.currency === "SEK" ? "kr" : item.transactionAmount.currency))
                            : ""}</div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.balanceAfterTransaction')}:
                    </Col>
                    <Col className="col-6 text-start">
                        <div className={`${item.balanceAfterTransaction && item.balanceAfterTransaction.balanceAmount && item.balanceAfterTransaction.balanceAmount.amount ? item.balanceAfterTransaction.balanceAmount.amount >= 0 ? 'text-green' : 'text-red' : ""}`}>{item.balanceAfterTransaction && item.balanceAfterTransaction.balanceAmount && item.balanceAfterTransaction.balanceAmount.amount ?
                            ToCustomDecimal(parseFloat(item.balanceAfterTransaction.balanceAmount.amount)) + " " + (item.balanceAfterTransaction.balanceAmount.currency === "EUR" ? "€" : (item.balanceAfterTransaction.balanceAmount.currency === "SEK" ? "kr" : item.balanceAfterTransaction.balanceAmount.currency))
                            : ""}</div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('button.info')}:
                    </Col>
                    <Col className="col-6 text-start pe-5">
                        {item.remittanceInformationUnstructured ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('button.info')} 2:
                    </Col>
                    <Col className="col-6 text-start pe-5">
                        {item.remittanceInformationStructured ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        {t('modelLabel.transactionListId')}:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.transactionListId ?? ""}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-6 fw-bold text-start ps-5">
                        BicFi:
                    </Col>
                    <Col className="col-6 text-start">
                        {item.bicFi ?? ""}
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <Col lg="12" className='card card-backend p-4 mb-5'>
            <CustomModal isOpen={displayModal} toggle={() => setDisplayModal(!displayModal)} body={modalContent}/>
            <Row>
                <Col className="col-4">
                    <h2 className="pb-2 ps-1 rokkitt-font">{t('modelLabel.transaction')}</h2>
                </Col>
                <Col className="col-8 text-end">
                    <InfoPageMessage message={t('openPayment.infoMessage')}/>
                    <div className="d-inline-flex">
                        {displaySpinner ?
                            <>
                                {t('validationMessage.sendingConsent')}
                                <Spinner color='success' className="ms-2 me-3"/>
                            </>
                            : ""}
                        <span className="fw-bold mt-1">
                    {t('modelLabel.consent')}:
                    </span>
                        <Button className="btn-submit-kassan ms-2 me-2" disabled={displaySpinner} onClick={() => {
                            setDisplaySpinner(true);
                            RenewConsent();
                        }}>
                            {t('button.renew')}
                        </Button>
                        <Button className="btn-submit-kassan me-2" disabled={displaySpinner} onClick={() => {
                            setDisplaySpinner(true);
                            AuthorizeConsent();
                        }}>
                            {t('button.authorize')}
                        </Button>
                    </div>
                </Col>
            </Row>
            <SortableList
                listOptions={listOptions}
            />
        </Col>
        );
};

export default SuperAdminTransactionList;