import {  Button, Card, Col, Row } from 'reactstrap';
import LogoLRF from "../../AppSystem/assets/img/LRF_Logga.svg"
import Check from "../../AppSystem/assets/img/swedbank/check.png"
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { use } from 'react';
import useApiCallService from '../../Common/Helpers/ApiCall';
import { API_URL } from '../../config';
import { FlashMessage } from '../../Common/Helpers/FlashMessage';
import { CustomReload } from '../../AppSystem/Helpers/CustomReload';



const CheckoutPaymentCompleted = () => {
  const { ApiCall } = useApiCallService();
  const [paymentIdResult, setPaymentIdResult] = useState(null);
  const [typeResult, setTypeResult] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState(null);
  const [errorMessageResult, setErrorMessageResult] = useState(null);
  const [email, setEmail] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("email") || '';
  });
  const { t } = useTranslation();

  useEffect(() => { 
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get('type');
    const paymentId = searchParams.get('payment_id');
    const emailUrl = searchParams.get('email');

    if (emailUrl) {
      setEmail(emailUrl);
    }
    
    setPaymentIdResult(paymentId);
    setTypeResult(type);

    const handleApiResponse  = (apiResponse) => {
      if (apiResponse) {
        
        setReceiptUrl(API_URL + apiResponse);

      }else{
        //FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
      }  
    };

    const fetchGetSwedbankUrl = async (paymentId) => {
      try {
          const apiCallOptions = {
              "method" : "get",
              "url" : `/swedbank/receipt?swedbankId=${paymentId}&type=${type}`,
            };
         await ApiCall(apiCallOptions, handleApiResponse);
      } catch (error) {
        FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
      }
    };
    fetchGetSwedbankUrl(paymentId);
}, []);



const handleSend = async () => {

  const handleApiResponse  = (apiResponse) => {
    if (apiResponse) {    
      FlashMessage('Du kommer inom kort att få ett e-postmeddelande med ditt kvitto bifogat.', 'flash-messageSuccess');

    }
    else{
      FlashMessage("Det uppstod ett fel vid sändningen av e-postmeddelandet. Försök igen senare.", 'flash-messageError');
    }  
  };

  if (email) {
    try {

      const apiCallOptions = {
        method: "post",
        url: "/swedbank/receipt-email",
        data: {
          Email: email,      
          SwedbankId: paymentIdResult, 
          Type: typeResult,
        },    
      };

      await ApiCall(apiCallOptions, handleApiResponse);
    } catch (error) {
      FlashMessage("Det uppstod ett fel vid sändningen av e-postmeddelandet. Försök igen senare.", 'flash-messageError');
    }
    
  } else {
    FlashMessage("Ogiltig e-post. Försök igen senare.", 'flash-messageError');
  }
};
    
console.log('apiResponse', receiptUrl);
  return (
<Row className='d-flex justify-content-center pt-5 align-items-center vh-100 bg-cream' >
  <Col lg={9} >
    
      <Row className='d-flex justify-content-center px-4 p-3 bg-white rounded-3 align-items-center'>
          <Col lg={6} className='d-flex justify-content-center pt-5'>
            <img src={LogoLRF} height="90" />
          </Col>
          <Col xs={12} lg={12} className='d-flex justify-content-center text-center pt-5'>
             <h2>Din betalning genomfördes framgångsrikt</h2>
          </Col>         
          <Col lg={3} className='d-flex justify-content-center '>
              <img src={Check} alt="" height="100" width="100" />
          </Col>

          <Col lg={3} className="pt-5 pb-5 ">
              <div className='pb-3'>
                  <a href={receiptUrl} target="_blank" rel="noopener noreferrer" className="text-info-box">
                  <i className="bx bx-info-circle me-2 text-info-box"></i> Klicka här för att ladda ner ditt kvitto.
                  </a>
              </div>
              
              <label htmlFor="emailInput" className="mb-2">
                Ange din e-postadress om du vill få ditt kvitto via e-post.
              </label>
              <input
                id="emailInput"
                type="email"
                placeholder="Ange din e-postadress"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control mb-2 mt-2"
                style={{ maxWidth: "300px" }}
              />
              <Button className="box-green border-0" variant="success" onClick={handleSend}>
              Skicka kvitto
              </Button>
          </Col>
      </Row>
    
  </Col>
</Row>
  );
};

export default CheckoutPaymentCompleted;
