import i18n from "i18next";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";
import { ToCustomDecimal } from "../../AppSystem/Helpers/ToCustomDecimal";

class ResponseEconomicReportModel {
    constructor({
                    amount = '',
                    model = '',
                    orgName = '',
                    orgType = '',
                    costBearerName = '',
                    costPlace = '',
                    createdAt = '',
                    createdByName = '',
                } = {}) {
        this.amount = amount;
        this.model = model;
        this.orgName = orgName;
        this.orgType = orgType;
        this.costBearerName = costBearerName;
        this.costPlace = costPlace;
        this.createdAt = createdAt;
        this.createdByName = createdByName;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const userName = i18n.t('modelLabel.name');
        const orgName = i18n.t('modelLabel.org');
        const date = i18n.t('modelLabel.date');
        const status = i18n.t('modelLabel.status');
        const createdAt = 'Datum/tid';
        const amount = i18n.t('modelLabel.amount');

        const attributes = {
            amount :{
                'label': amount,
                'sortable': true,
                'sortField': 'amount',
                'insideClass' : (item)=> item.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                'value': (item) => item.amount ? ToCustomDecimal(item.amount) +' kr' : "",
                'type': 'number',
                'thClass': 'ps-5'
            },
            model :{
                'label': 'Transaktionstyp',
                'sortable': true,
                'sortField': 'model',
                'value': (item) => item.model === 'Förrättning' ? i18n.t('reimbursement.reportProcessing') : item.model === 'Arvode' ? i18n.t('honorarium.honorarium') : item.model === 'Betala' ? i18n.t('payReceive.pay') : item.model === 'Intern transaktion' ? i18n.t('internalTransaction.internalTransaction') : item.model === 'Faktura' ? i18n.t('invoice.invoice') : item.model || "",
                'type': 'selectMulti',
            },
            orgName :{
                'label': orgName,
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            orgType :{
                'label': 'Organisationstyp',
                'sortable': true,
                'sortField': 'orgType',
                'value': (item) => item.orgType || "",
            },
            costBearerName :{
                'label': 'Aktivitetsområde',
                'sortable': true,
                'sortField': 'costBearerName',
                'value': (item) => item.costBearerName || "",
                'type': 'selectMulti',
            },
            costPlace :{
                'label': 'KST',
                'sortable': true,
                'sortField': 'costPlace',
                'value': (item) => item.costPlace || "",
                'type': 'selectMulti',
            },
            createdAt :{
                'label': createdAt,
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : "" || "",
                'type': 'dateTime',
            },
            createdByName :{
                'label': 'Person',
                'sortable': true,
                'sortField': 'createdByName',
                'value': (item) => item.createdByName || "",
            },
        }

        return attributes;
    }
}

export default ResponseEconomicReportModel;