import {useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {Button, Col, Row, Input} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import AdminResponseOrgModel from "../Models/Response/AdminResponseOrgModel";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";
import { FlashMessage } from "../../Common/Helpers/FlashMessage";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";

export function AdminOrganization() {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { t } = useTranslation()
    //const [filter, setFilter] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [orgId, setOrgId] = useState("");
    const { ApiCall } = useApiCallService();
    //const [apiResponse, setApiResponse] = useState(null);
    const [apiResponseEmail, setApiResponseEmail] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


   /* const fetchItems = async (params, currentPage, itemsPerPage) => {
        let url = '/org/admin/search'
        url += `?page=${currentPage}&perPage=${itemsPerPage}`;

        const finalUrl = UrlStructure({params,filter,url});

        const apiCallOptions = {
            method: "get",
            url: finalUrl,
        };
        try {
            await ApiCall(apiCallOptions, setApiResponse);
            return apiResponse;
        } catch (error) {
            console.log(error);
        }
    };
    const handleFilterChange = (newFilters) => {
        // GLOBAL SEARCH
        setFilter(`searchCriteria=${newFilters.searchCriteria}`);

    };*/
    const GetOrgRoten = async () => {
        try {
            setIsButtonDisabled(true);
            const apiCallOptions = {
                method: "get",
                url: "/roten/organization-list",
            };
            try {
                ApiCall(apiCallOptions, setApiResponseEmail, setErrorMessage);  
                FlashMessage('Förfrågan har behandlats, denna process kan ta upp till 1 minut (eller mer). ', 'flash-messageSuccess');
                setIsButtonDisabled(false);
                setTimeout(()=>{
                    CustomReload();
                },4000);           
            } catch (error) {
                console.log(error);
                FlashMessage('Det uppstod ett problem, försök igen senare. ', 'flash-messageSuccess');
                setIsButtonDisabled(false);
            }
        } catch (err) {
            console.log(err)
        }
    }

    const updateOrgCronjob = async () => {
        try {
            FlashMessage('Uppgradering av organisationer', 'flash-messageSuccess');
            const apiCallOptions = {
                method: "post",
                url: `/roten/update-org-cronjob?orgId=${orgId || ''}`,
            };
            try {
                ApiCall(apiCallOptions, (response) => {
                    FlashMessage('Organisationen har uppdaterats', 'flash-messageSuccess');
                    setOrgId("");
                }, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage('Det uppstod ett problem, försök igen senare. ', 'flash-messageError');
            }
        } catch (err) {
            console.log(err);
        }
    };    
    
    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false}
                              id={item.id}
                              onClick={() => {
                                  //setIndividualUserId(item);
                                  const newUrl = `${ADMIN_PATHS.ADMIN_ORGANIZATION_INFO}?id=${item.id}`;
                                  navigate(newUrl);
                              }}
                />
            </>
        );
    }
    const columns = ["visualId", 'name', 'type', 'createdAt'];

    if (isSuperAdmin === true) {
        columns.unshift('id');
    }
    const listOptions = {
        "model": AdminResponseOrgModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "filterTestIsAvailable": true,
        "urlGetListItemsToExport": "/org/admin/search/filter",
        "nameFile": t('modelLabel.org'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/org/admin/search/filter",
    };


    return(
        <>
            <Col lg="12" className='card card-backend p-4 mb-5'>
                <Row>
                    <Col lg={9}>
                        <h2 className="pb-2 rokkitt-font">{t('adminLabel.organizations')}</h2>
                    </Col>
                    <Col lg={3} className="text-end d-flex align-items-center justify-content-end">
                        <Button className="bg-green mb-2 mt-2" disabled={isButtonDisabled} onClick={GetOrgRoten} style={{ minWidth: "200px", whiteSpace: "nowrap" }}>
                            Få organisationer
                        </Button>
                        <Button className="bg-green mb-2 mt-2 ms-2" onClick={updateOrgCronjob} style={{ minWidth: "210px", whiteSpace: "nowrap" }}>
                            Uppdatera organisationer
                        </Button>
                        <Input
                            type="text"
                            placeholder="Ange orgId"
                            value={orgId}
                            onChange={(e) => setOrgId(e.target.value)}
                            className="me-2 ms-2"
                            style={{ width: "150px" }}
                        />
                    </Col>
                </Row>              
                <SortableList
                   // fetchDataFunction={fetchItems}
                    listOptions={listOptions}
                   // handleFilterChange={handleFilterChange}
                    //type="model"
                />
            </Col>

        </>);
}