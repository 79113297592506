import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {Button, Col} from "reactstrap";
import {useTranslation} from "react-i18next";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponseSieModel from "../../ExportSie/Models/ResponseSieModel";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_PATHS } from "../../config";

const AdminReportTaxAuthority = () => {
    const defaultStartDate = new Date();
    defaultStartDate.setHours(0);
    defaultStartDate.setMinutes(1);
    defaultStartDate.setSeconds(0);
    defaultStartDate.setMilliseconds(0);
    const defaultEndDate = new Date();
    defaultEndDate.setHours(23);
    defaultEndDate.setMinutes(59);
    defaultEndDate.setSeconds(59);
    defaultEndDate.setMilliseconds(0);
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();

    const handleDownloadApiResponse  =  (response, name) => {
        let file;
        if (response instanceof Blob) {
          file = response;
          
        } else {
          file = new Blob([response]);
        }
        let fileName = name;

        if (!name || name.trim() === "") {
            fileName = "file.pdf";
        } else {
            fileName = name;
        }

        const urlToFile = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = urlToFile;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        setTimeout(() => {
          CustomReload();
        }, 2000);
    }

    const DownloadFile = async (id, fileName) => {
        let url = `/file?id=${id}`        
        const apiCallOptions = {
            method: "get",
            url: url,
            dataTypes: 'blob',
            responseType: 'blob'
        };
        try {
            await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response, fileName));

        } catch (error) {
            console.log(error);
        }
    };
    const renderActionColumn = (item) => {
        return (
            <>
                <Button className="rounded me-2 border-0 text-green bg-green-light position-relative"
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.ADMIN_REPORT_TAX_INFO}?id=${encodeURIComponent(item.markdownString)}`);
                        }} >
                    <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('button.info') }
                    </div>
                </Button>
                <Button className="bg-green-light text-green border-0 position-relative" id={item.id} onClick={() => {
                    DownloadFile(item.id, item.fileName);
                }}>
                    <i className="bx bx-down-arrow-alt fs-5 align-middle pb-1" />
                    <div className="tooltip-custom-button-table">
                        {t('modelLabel.download')}
                    </div>
                </Button>
            </>
        );
    }

    const columns = ['generated','fileName','createdAt'];

    const listOptions = {
        "model": ResponseSieModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/export-data/admin/search-tax-authority/filter",
        "nameFile": t('modelLabel.taxAuthority'),
        "ignoreFields" : columns,
        "modelType" : "TaxAuthority",
        "filterTestIsAvailable": true,
        "urlGetSearch" : "/export-data/admin/search-tax-authority/filter",
    };
      return (
        <>        
          <Col lg="12" className='card card-backend p-4 mb-5'>
                <h2 className="pb-2 rokkitt-font">{t('modelLabel.taxAuthority')}</h2>
                <SortableList
                    listOptions={listOptions}
                />
            </Col>
        </>
    );
};

export default AdminReportTaxAuthority;