import { Card, Col, Row } from "reactstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import RequestPay from "../Models/Request/RequestPay";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import SortableList from "../../Common/Components/Lists/SortableList";
import AccountingAccountModel from "../../Accounting/Models/Response/ResponseAccountingAccountModel";
import {StringToLowerCaseFirst} from "../../Common/Helpers/StringToLowerCaseFirst";
import ResponsePay from "../Models/Response/ResponsePay";
import {CreateFormPay} from "../Components/CreateFormPay";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {PAY_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {LoadingData} from "../../Common/Components/LoadingData";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {useSelector} from "react-redux";

export function Pay () {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {ApiCall} = useApiCallService();
    const { orgData } = useSelector(state => state.auth);

    // API REQUEST
    const [requestPay, setRequestPay] = useState(new RequestPay());
    const completeSchema = RequestPay.getAttributes(null, true);

    // API RESPONSE
    const [responsePay, setResponsePay] = useState(new ResponsePay());

    // FILE RESPONSE
    const [fileResponse, setFileResponse] = useState(null);

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);

    // ACCOUNTING RESPONSES
    const [accountingResponse, setAccountingResponse] = useState('');
    const [aSettingResponse, setASettingResponse] = useState(null);
    const [aExtraResponse, setAExtraResponse] = useState(null);

    // ACCOUNTING EXTRA FIELD
    const [fileType, setFileType] = useState("");
    const [requestName , setRequestName] = useState("");
    const [extraField , setExtraField] = useState(null);

    const [type, setType] = useState("Bankkonto");

    const [displaySpinner, setDisplaySpinner] = useState(false);

    const [isDeletedFile, setIsDeletedFile] = useState(false);
    const [isDeletedExtraFile, setIsDeletedExtraFile] = useState(false);

    let permissionCreate = false;
    if (CheckUserPermission("pay.create") || CheckUserPermission("pay")) {
        permissionCreate = true;
    }

    
    // API CALL TO GET COST BEARER
    useEffect(() => {
        setRequestPay((prevFormData) => ({
            ...prevFormData,
            type: "Bankkonto",
        }));
        const handleCostResponse = (costResponse) => {
            setCostResponse(costResponse);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list-org?id=" + orgData.orgId,
                };
                await ApiCall(apiCallOptions, handleCostResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING
    useEffect(() => {
        const handleAccountingResponse  = (accountingResponse) => {
            setAccountingResponse(accountingResponse);
        };
        const AccountingApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/list",
                };
                await ApiCall(apiCallOptions, handleAccountingResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        AccountingApiCall();
    }, []);

    const costBearerArray = [];
    if (costResponse !== null) {
        costResponse.forEach((item) => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
    }

    const accountingArray = [];
    if (accountingResponse !== null && accountingResponse !== '') {
        accountingResponse.forEach((item) => {
            const accountingModel = new AccountingAccountModel(item);
            accountingArray.push(accountingModel);
        });
    }

    // API CALL TO GET ACCOUNTING SETTINGS
    useEffect(() => {
        if (requestPay.accountingId !== "") {
            const ASettingApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/accounting/setting?accountingId=${requestPay.accountingId}&name=iva`,
                    };
                    await ApiCall(apiCallOptions, setASettingResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            ASettingApiCall();
        }
    }, [requestPay.accountingId]);

    // API CALL TO GET ACCOUNTING EXTRA
    useEffect(() => {
        if (requestPay.accountingId !== "") {
            const AExtraApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/accounting/extra?accountingId=${requestPay.accountingId}`,
                    };
                    await ApiCall(apiCallOptions, setAExtraResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            AExtraApiCall();
        }
    }, [requestPay.accountingId]);

    // UPDATE VAT AMOUNT
    useEffect(() => {
        let vatAmountValue = requestPay.vatAmount;
        if (aSettingResponse !== null && aSettingResponse.value === "false") {
            vatAmountValue = null;
        } else {
            vatAmountValue = 0;
        }
        setRequestPay((prevFormData) => ({
            ...prevFormData,
            vatAmount: vatAmountValue,
        }));
    }, [aSettingResponse]);

    // UPDATE ADDITIONAL INFO
    useEffect(() => {
        let additionalInfoValue = requestPay.additionalInfo;
        if (aExtraResponse !== null && aExtraResponse.extraFields && aExtraResponse.extraFields.length > 0) {
            const requestNameLower = StringToLowerCaseFirst(aExtraResponse.extraFields[0].requestName);
            additionalInfoValue = {
                [requestNameLower]: requestPay.additionalInfo !== null && Object.keys(requestPay.additionalInfo).length > 0 && requestPay.additionalInfo[requestNameLower] !== "" ? requestPay.additionalInfo[requestNameLower] : "",
            };
             setRequestName(requestNameLower);
             setExtraField(aExtraResponse.extraFields[0]);
        } else {
            if (requestPay.additionalInfo !== null && Object.keys(requestPay.additionalInfo).length > 0 && requestPay.additionalInfo[requestName] !== "") {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpense.additionalInfo[requestName]);
            }
            additionalInfoValue = null;
        }
        setRequestPay((prevFormData) => ({
            ...prevFormData,
            additionalInfo: additionalInfoValue,
        }));
    }, [aExtraResponse]);

    // UPDATE FILE UPLOADED
    useEffect(() => {
        if (fileResponse !== null) {
            if (fileType === "fileId") {
                setRequestPay((prevFormData) => ({
                    ...prevFormData,
                    [fileType]: fileResponse.id,
                }));
            } else {
                setRequestPay((prevFormData) => ({
                    ...prevFormData,
                    additionalInfo: {
                        [fileType]: fileResponse.id
                    }
                }));
            }
        }
    }, [fileResponse]);

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (responsePay && responsePay.id !== "") {
            FlashMessage(t('validationMessage.paySuccessfully'), 'flash-messageSuccess');
            setTimeout(() => {
                navigate(`${PAY_PATHS.PAY_INFO}?id=${responsePay.id}`);
            }, 2000);
        }
    }, [responsePay]);

    // DELETE FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedFile === true) {
            setRequestPay((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                fileName: "",
            }));
        }
    }, [isDeletedFile]);

    // DELETE EXTRA FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedExtraFile === true) {
            setRequestPay((prevFormData) => ({
                ...prevFormData,
                additionalInfo: {
                    [requestName]: ""
                },
                additionalFileExtension: "",
                additionalFileName: "",
            }));
        }
    }, [isDeletedExtraFile]);

    // HANDLE FILE
    const HandleUploadFile = async (e, setFileResponse, fileInfo) => {
        const formData = new FormData();
        let isValidFile = false;
        e.map(item => {
            if (item.type === 'image/png' || item.type === 'image/jpg' || item.type === 'image/gif' || item.type === 'image/jpeg' || item.type === "application/pdf" || item.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || item.type === "application/msword" || item.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item.type === "application/vnd.ms-excel" || item.type === "text/plain") {
                formData.append('file', item);
                isValidFile = true;
                if (fileInfo === "fileId") {
                    setRequestPay((prevFormData) => ({
                        ...prevFormData,
                        fileExtension: item.type,
                        fileName: item.name,
                    }));
                } else {
                    setRequestPay((prevFormData) => ({
                        ...prevFormData,
                        additionalFileExtension: item.type,
                        additionalFileName: item.name,
                    }));
                }
            }
        })
        if (isValidFile) {
            const apiCallOptions = {
                method: "post",
                url: "/file",
                data: formData,
                dataTypes: "file"
            };
            try {
                await ApiCall(apiCallOptions, setFileResponse);
            } catch (error) {
                console.log('error', error);
            }
        } else {
            setFileResponse(null);
        }
    };

    // HANDLE CHANGE
    const HandleChange = async (e, name) => {
        let costBearerIdValue = requestPay.costBearerId;
        let accountingIdValue = requestPay.accountingId;
        let typeValue = requestPay.type;
        let dateValue = requestPay.date;
        let creditorNameValue = requestPay.creditorName;
        let clearingNumberValue = requestPay.clearingNumber;
        let accountNumberValue = requestPay.accountNumber;
        let bgValue = requestPay.bG;
        let pgValue = requestPay.pG;
        let ocrValue = requestPay.oCR;
        let messageValue = requestPay.message;
        let descriptionValue = requestPay.description;
        let amountValue = requestPay.amount;
        let vatAmountValue = requestPay.vatAmount;
        let fileIdValue = requestPay.fileId;
        let programValue = requestPay.additionalInfo !== null && Object.keys(requestPay.additionalInfo).length > 0 && requestPay.additionalInfo.program !== "" ? requestPay.additionalInfo.program : "";
        if (name === "costBearerId") {
            if (costResponse !== null) {
                costBearerIdValue = e.target.value;
            }
        }
        if (name === "accountingId") {
            if (accountingResponse !== null  && accountingResponse !== '') {
                accountingIdValue = e.target.value;
            }
        }
        if (name === "bankPayment") {
            setType('Bankkonto')
            typeValue = "Bankkonto";
            bgValue = null;
            pgValue = null;
            ocrValue = null;
            messageValue = null;
            clearingNumberValue = "";
            accountNumberValue = "";
        }
        if (name === "giroPayment") {
            setType('Bankgiro')
            typeValue = "Bankgiro";
            clearingNumberValue = null;
            accountNumberValue = null;
            pgValue = null;
            bgValue = "";
            ocrValue = "";
            messageValue = "";
        }
        if (name === "plusPayment") {
            setType('Plusgiro')
            typeValue = "Plusgiro";
            clearingNumberValue = null;
            accountNumberValue = null;
            bgValue = null;
            pgValue = "";
            ocrValue = "";
            messageValue = "";
        }
        if (name === "date") {
            dateValue = ChangeDateFormat('date', e);
        }
        if (name === "noDate") {
            dateValue = null;
        }
        if (name === "creditorName") {
            creditorNameValue = e.target.value;
        }
        if (name === "clearingNumber") {
            clearingNumberValue = e.target.value;
            bgValue = null;
            pgValue = null;
            ocrValue = null;
        }
        if (name === "accountNumber") {
            accountNumberValue = e.target.value;
            bgValue = null;
            pgValue = null;
            ocrValue = null;
            messageValue = null;
        }
        if (name === "bG") {
            bgValue = e.target.value;
            clearingNumberValue = null;
            accountNumberValue = null;
            pgValue = null;
        }
        if (name === "pG") {
            pgValue = e.target.value;
            clearingNumberValue = null;
            accountNumberValue = null;
            bgValue = null;
        }
        if (name === "oCR") {
            ocrValue = e.target.value;
            clearingNumberValue = null;
            accountNumberValue = null;
            messageValue = null;
        }
        if (name === "message") {
            messageValue = e.target.value;
            clearingNumberValue = null;
            accountNumberValue = null;
            ocrValue = null;
        }
        if (name === "selectMessage") {
            messageValue = "";
            ocrValue = null;
        }
        if (name === "selectOCR") {
            ocrValue = "";
            messageValue = null;
        }
        if (name === "description") {
            descriptionValue = e.target.value;
        }
        if (name === "amount") {
            if (e && e.target && e.target.value !== "") {
                amountValue = CheckDecimal(e.target.value);
            }
            else {
                amountValue = 0;
            }
        }
        if (name === "vatAmount") {
            if (e && e.target && e.target.value !== "") {
                vatAmountValue = CheckDecimal(e.target.value);
            } else {
                vatAmountValue = 0;
            }
        }
        if (name === "fileId") {
            HandleUploadFile(e, setFileResponse, "fileId");
            setFileType("fileId");
        }
        if (name === "dynamic") {
            if (e && Array.isArray(e)) {
                HandleUploadFile(e, setFileResponse, "dynamic");
                setFileType(requestName);
            } else {
                if (e && e.target && e.target.value !== "") {
                    programValue = e.target.value;
                    setRequestPay((prevFormData) => ({
                        ...prevFormData,
                        additionalInfo: {
                            program : programValue
                        }
                    }));
                }
            }
        }
        setRequestPay((prevFormData) => ({
            ...prevFormData,
            costBearerId: costBearerIdValue,
            accountingId: accountingIdValue,
            type: typeValue,
            date: dateValue,
            creditorName: creditorNameValue,
            clearingNumber: clearingNumberValue,
            accountNumber: accountNumberValue,
            bG: bgValue,
            pG: pgValue,
            oCR: ocrValue,
            message: messageValue,
            description: descriptionValue,
            amount: amountValue,
            vatAmount: vatAmountValue
        }));
    };

    // HANDLE VALIDATE
    const HandleValidate = async () => {
        try {
            if (type !== "Bankkonto" && requestPay.oCR === "") {
                requestPay.oCR = null;
            }
            await completeSchema.validate(requestPay, { abortEarly: false });
            setDisplaySpinner(true);
            const apiCallOptions = {
                method: "post",
                url: "/pay",
                data: requestPay,
            };
            try {
                await ApiCall(apiCallOptions, setResponsePay);
            } catch (error) {
                console.log(error);
                FlashMessage( t('validationMessage.sendError') , 'flash-messageError');
                $("#submit").prop("disabled", false);
            }
        } catch (err) {
            console.log(err)
            $("#submit").prop("disabled", false);
        }
    }

    //// LIST
    // API CALL TO GET PAY LIST
 /*   const fetchItems = async (params, currentPage, itemsPerPage) => {
        let url = '/pay/search'
        url += `?page=${currentPage}&perPage=${itemsPerPage}`;

        const finalUrl = UrlStructure({params,filter,url});
        const apiCallOptions = {
            method: "get",
            url: finalUrl,
        };
        try {
            await ApiCall(apiCallOptions, setResponsePayList);
            return responsePayList;
        } catch (error) {
            console.log(error);
        }
    };

    const handleFilterChange = (newFilters) => {
        // GLOBAL SEARCH
        setFilter(`searchCriteria=${newFilters.searchCriteria}`);
    };*/

    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon="bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4} buttonColor="#cde0da" tooltipText={t('button.info')} customClasses="rounded border-0" buttonBorder={false} onClick={() => { navigate(`${PAY_PATHS.PAY_INFO}?id=${item.id}`) }} />
            </>
        );
    }

    const columns = ["visualId", 'type', 'date', 'description', 'amount', 'createdByName', 'status'];
    const listOptions = {
        "model": ResponsePay,
        'columns': [
            "visualId",
            'type',
            "date",
            {
                "field" : "description",
                "label" : t('modelLabel.description'),
                "tdClass": (item) => "auto-wrap-td",
                "value": (item) => item.description || ""
            },
            {
                "field" : "amount",
                "label" : t('modelLabel.amount'),
                "sortable": true,
                "thClass": "ps-5",
                'insideClass' : (item)=> item.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                "value": (item) => item.amount ? ToCustomDecimal(item.amount) + ' kr' : "",
                'type': 'number' ,
            },
            'createdByName',
            "status",
            
        ],
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/pay/search/filter",
        "nameFile": t('payReceive.pay'),
        "ignoreFields" : columns,
        "filterTestIsAvailable": true,
        "urlGetSearch" : "/pay/search/filter",
        "modelType" : "Pay",
        "modelExport" : "Pay"
    };

    return(
        <>
            <Col lg={12} className="px-0">
                {permissionCreate === true ?
                    <Row className="px-custom-pay">
                        <Col lg={12} className="py-4 card-backend px-2 px-lg-4">
                            <Row>
                                <Col xs='auto'>
                                    <h2 className="fw-bold rokkitt-font ps-4 ps-lg-0">
                                        {t('payReceive.pay')}
                                    </h2>
                                </Col>
                                <InfoPageMessage message={t('payReceive.selectPayment')}/>
                            </Row>

                            {/*<p className="px-4 ps-lg-0"> {t('payReceive.selectPayment')}</p>*/}
                            {accountingResponse !== '' && accountingResponse !== null ?
                                <CreateFormPay type={type} data={requestPay} costBearerArray={costBearerArray} accountingArray={accountingArray} onChange={HandleChange} extraField={extraField} onValidate={HandleValidate} displaySpinner={displaySpinner} setIsDeletedFile={setIsDeletedFile} setIsDeletedExtraFile={setIsDeletedExtraFile}/>
                                :
                                <LoadingData data={accountingResponse}/>
                            }

                        </Col>
                    </Row>
                    : ''}

                <Row className="pb-5">
                    <Col lg={12} className="pt-4">
                        <Card className="card-backend p-4">
                            <h2 className="fw-bold rokkitt-font"> {t('payReceive.transfers')}</h2>
                            <SortableList
                                //key={updateList}
                                listOptions={listOptions}
                            />
                        </Card>
                    </Col>
                    {/*Delete this part if it is not used at the end*/}
                    {/*<Col lg={4}>
                        <Row className="px-3">
                            <Col lg={12} className="mt-4 card-backend py-5 text-center">
                                <h4 className="fw-bold rokkitt-font fs-3"> {t('payDirectTransmission.managing')}</h4>
                                <Col lg={12} className="d-flex justify-content-center py-4">
                                    <img src={ButtonImg} className="w-25"/>
                                </Col>

                                <div>
                                    {t('payDirectTransmission.selectInvoice')}
                                </div>

                            </Col>
                        </Row>
                    </Col>*/}
                </Row>
            </Col>
        </>
    );
}