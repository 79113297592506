import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import ResponseQueuedPaymentsModel from "./ResponseQueuedPaymentsModel";
import i18n from "i18next";

class AdminResponseHonorarium {
    constructor({
                    id = '',
                    visualId = '',
                    userId = '',
                    userName = '',
                    orgId = '',
                    orgName = '',
                    costPlace = '',
                    costBearerId = '',
                    costBearerName = '',
                    annualHonorarium = 0,
                    paymentInterval = 12,
                    firstPayment = "",
                    queuedPayments = [],
                    status = '',
                    reason = '',
                    approvedDeniedByName = '',
                    createdAt = "",
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.userId = userId;
        this.userName = userName;
        this.orgId = orgId;
        this.orgName = orgName;
        this.costPlace = costPlace;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.annualHonorarium = annualHonorarium;
        this.paymentInterval = paymentInterval;
        this.firstPayment = firstPayment //? ChangeDateFormat('date', firstPayment) : '' ;
       /* this.queuedPayments = queuedPayments.map(payment => ({
            date: payment.date || "",
            value: payment.value || 0,
        }));*/
        this.queuedPayments = queuedPayments?.map(queued => new ResponseQueuedPaymentsModel(queued));
        this.status = status;
        this.reason = reason;
        this.approvedDeniedByName = approvedDeniedByName;
        this.createdAt = createdAt;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes =  {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            userId: {
                'label': 'User ID',
                'sortable': true,
                'sortField': 'userId',
                'value': (item) => item.userId || "",
            },
            userName: {
                'label': i18n.t('modelLabel.recipient'),
                'sortable': true,
                'sortField': 'userName',
                'value': (item) => item.userName || "",
            },
            orgId: {
                'label': 'Organization ID',
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName: {
                'label': i18n.t('modelLabel.org'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            costPlace: {
                'label': i18n.t('modelLabel.costPlace'),
                'sortable': true,
                'sortField': 'costPlace',
                'value': (item) => item.costPlace || "",
            },
            costBearerId: {
                'label': 'CostBearer ID',
                'sortable': true,
                'sortField': 'costBearerId',
                'value': (item) => item.costBearerId || "",
            },
            costBearerName: {
                'label': i18n.t('modelLabel.costBearer'),
                'sortable': true,
                'sortField': 'costBearerName',
                'value': (item) => item.costBearerName || "",
            },
            annualHonorarium: {
                'label': i18n.t('honorarium.annual'),
                'sortable': true,
                'sortField': 'annualHonorarium',
                'thClass': 'ps-5',
                'value': (item) => item.annualHonorarium || "",
                'type': 'number'
            },
            paymentInterval:{
                'label': i18n.t('honorarium.payment'),
                'sortable': true,
                'sortField': 'paymentInterval',
                'value': (item) => {
                    const interval = item.paymentInterval || ""
                    return interval === 1 ? i18n.t('modelLabel.yearly') :
                        interval === 12 ? i18n.t('modelLabel.monthly') :
                        interval === 4 ? i18n.t('modelLabel.daily') :
                            '';
                },
                'type': 'select'
            },
            firstPayment: {
                'label': i18n.t('honorarium.first'),
                'sortable': true,
                'sortField': 'firstPayment',
                'value': (item) => ChangeDateFormat('date', item.firstPayment) || '',
                'type': 'dateTime'
            },
            queuedPayments: {
                'label': 'Queued Payments',
                'sortable': true,
                'sortField': 'queuedPayments',
                'value': (item) => item.queuedPayments || [],
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': "status",
                'insideClass' : (item)=> item.status === 'Godkänd' ? 'box-green me-4 fixed-width' : (item.status === 'Utbetald' ? "box-green me-4 fixed-width": (item.status === 'Nekad' ? "box-red me-4 fixed-width" : 'box-custom-grey me-4 fixed-width' )),
                'value': (item) => item.status === 'Skickat' ? i18n.t('reimbursement.sent') : item.status === 'Nekad' ? i18n.t('attest.denied') : item.status === 'Godkänd' ? i18n.t('attest.approved') : item.status === 'Utbetald' ? i18n.t('modelLabel.paidOut'): '' || "",
                'type': 'select'
            },
            reason :{
                'label': i18n.t('modelLabel.reason'),
                'sortable': true,
                'sortField': "reason",
                'value': (item) => item.reason || "",
            },
            approvedDeniedByName :{
                'label': i18n.t('modelLabel.approvedDeniedByName'),
                'sortable': true,
                'sortField': "approvedDeniedByName",
                'value': (item) => item.approvedDeniedByName || "",
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : "" || "",
                'type': 'dateTime'
            }
        };

        return attributes;
    }
}

export default AdminResponseHonorarium;