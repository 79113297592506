import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Card, Col} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import AdminResponseOpenPaymentModel from "../Models/Response/AdminResponseOpenPaymentModel";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import { CustomModal } from "../../Common/Components/Modal/CustomModal";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";
import { FlashMessage } from "../../Common/Helpers/FlashMessage";

export function BankTransferHistoryList ( ) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    //RESPONSE SEARCH
    const [responseOpenPaymentList, setResponseOpenPaymentList] = useState('');

    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [paymentDetailsList, setPaymentDetailsList] = useState([]);
    const [paymentId, setPaymentId] = useState('');
    const [titlePaymentDetails, setTitlePaymentDetails] = useState('');

    const idToShowPaymentDetails = new URLSearchParams(window.location.search).get('paymentId');

    useEffect(() => {
        if (idToShowPaymentDetails !== null && responseOpenPaymentList?.items?.length > 0) {
            const foundItem = responseOpenPaymentList.items.find(
                (item) => item.id === idToShowPaymentDetails
            );
    
            if (foundItem) {
                setTitlePaymentDetails(foundItem.visualId + ' - ' + foundItem.creditorName);
                setPaymentDetailsList(foundItem.paymentDetails);
                setPaymentId(foundItem.id);                           
                setShowPaymentDetails(true);
            }
        }
    }, [responseOpenPaymentList]);

    const renderActionColumn = (item) => {
        return (
            <>
            {item.paymentDetails  ?
                <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                            onClick={() => {
                                if(item.paymentDetails){
                                setPaymentDetailsList(item.paymentDetails);
                                setPaymentId(item.id);
                                setShowPaymentDetails(true); 
                            }else{
                                FlashMessage('Det är inte möjligt att se denna betalning, eftersom den tillhör den tidigare uppdateringen, för att se den gå till attesten eller direkt till roten av modellen t.ex: Ersättningsbegäran ,Årligt arvode ,Betala Faktura etc...', 'flash-messageError');
                            }                           
                            }} >
                        {item.paymentDetails.length} <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                        <div className="tooltip-custom-button">
                        {t('button.info')}
                        </div>
                 </button>   
                : 
                <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.id}`);
                        }} >
                    <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('button.info')}
                    </div>
                </button>
            } 
            </>   
        );
    }
    
    const columns = [ "visualId", 'model','creditorName','creditorAccountIban','updatedAt', 'status', 'instructedAmount'];
    const listOptions = {
        "model": AdminResponseOpenPaymentModel,
        'columns':[
            "visualId", 'model','creditorName','creditorAccountIban','updatedAt', //añadir product, creditorAccountIban,instructedAmount
            {
                "field" : "status",
                "label" : t('modelLabel.status'),
                'insideClass' : (item)=> item.status === 'Betald' ?  'box-green' : 'box-red',
                "value": (item) => item.status === 'Betald' ? t('modelLabel.paidOut') :  item.status === 'Nekad' ? t('attest.denied') :t('modelLabel.unpaid') || "",
                'type': 'select' ,
            },
            {
                "field" : "instructedAmount",
                "label" : t('modelLabel.amount'),
                'insideClass' : (item)=> item.instructedAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.instructedAmount ? ToCustomDecimal(item.instructedAmount) +' kr' : "",
                'thClass': 'ps-5',
                'type': 'number',
            }
        ],
        "actions": {
        "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "filterTestIsAvailable": true,
        "urlGetListItemsToExport": "/open-payment/admin/search-paid/filter",
        "nameFile": t('payReceive.bankAccount'),
        "modelType" : "BankTransferHistory",
        "ignoreFields" : columns,
        "urlGetSearch" : "/open-payment/admin/search-paid/filter",

    };

    const contentPaymentDetails = (
        <div className="payment-details-container" style={{ width: '1000px', margin: '0 auto' }}>
            <h4 className="col fw-bold rokkitt-font" style={{ marginBottom: '20px' }}>{titlePaymentDetails}</h4>
            <div className="row fw-bold mb-2 border-bottom pb-2">
                <div className="col">{t('modelLabel.type')}</div>
                <div className="col">{t('modelLabel.orgName')}</div>
                <div className="col">{t('modelLabel.createdAt')}</div>
                <div className="col">{t('modelLabel.amount')}</div>
                <div className="col">{t('button.info')}</div>
            </div>
            {paymentDetailsList.map((item, index) => (
                <div className="row mb-2" key={'payment-' + index}>
                    <div className="col">{item.visualId <= 0 ? 0 : item.visualId}</div>
                    <div className="col">
                        {item.model === 'Förrättning' ? t('reimbursement.reportProcessing') :
                         item.model === 'Arvode' ? t('honorarium.honorarium') :
                         item.model === 'Betala' ? t('payReceive.pay') :
                         item.model === 'Intern transaktion' ? t('internalTransaction.internalTransaction') :
                         item.model}
                    </div>
                    <div className="col">{item.orgName}</div>
                    <div className="col">{item.createdAt ? ChangeDateFormat('dateTime', item.createdAt): ''}</div>
                    <div className="col">{item.instructedAmount ? ToCustomDecimal(item.instructedAmount) : 0} kr</div>
                    <div className="col">   
                        <button id={item.modelId} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                            onClick={() => {
                                navigate(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.modelId}&paymentId=${paymentId}&model=${item.model}&amount=${item.instructedAmount}&from=history`);
                            }} >
                            <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                            <div className="tooltip-custom-button">
                                {t('button.info')}
                            </div>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
    

    const contentPaymentFooterDeny = (
        <>
           
            <Button className="box-red border-0" onClick={() => {
                let url = new URL(window.location.href);
                url.searchParams.delete('paymentId');
                window.history.replaceState({}, document.title, url.toString());
                setShowPaymentDetails(false);
                
            }}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
        <CustomModal isOpen={showPaymentDetails} toggle={() => setShowPaymentDetails(!showPaymentDetails)} body={contentPaymentDetails} footer={contentPaymentFooterDeny} size='xl'/>
            <Col lg={12} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'mb-3 ms-3 backButton'}
                    onClick={() => {
                        navigate(ADMIN_PATHS.BANK_TRANSFERS_LIST)
                    }}
                />
            </Col>
            <Col lg={12} className=' pb-5 px-0'>
                <Card className='card-backend p-4'>
                    <h2 className="fw-bold rokkitt-font">{t('openPayment.historyBankList')}</h2>
                    <SortableList
                        listOptions={listOptions}
                        setApiCallDone={setResponseOpenPaymentList}
                    />
                </Card>

            </Col>

        </>
    );
}