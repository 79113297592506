import ResponseReimbursementFixed from "./ResponseReimbursementFixed";
import ResponseReimbursementMileage from "./ResponseReimbursementMileage";
import ResponseReimbursementExpense from "./ResponseReimbursementExpense";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";

class ResponseReimbursement {
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    orgName = '',
                    userId = '',
                    date = ChangeDateFormat('date', new Date()),
                    toDate = ChangeDateFormat('date', new Date()),
                    purpose = '',
                    costBearerId = '',
                    costBearerName = '',
                    status = '',
                    reason = '',
                    approvedDeniedByName = '',
                    fixeds =  new ResponseReimbursementFixed(),
                    mileage = new ResponseReimbursementMileage(),
                    expenses = new ResponseReimbursementExpense(),
                    grossAmount = 0,
                    createdAt = ChangeDateFormat('dateTime', new Date()),
                    newReimbursementId = "",
                    recipientAccount = '',
                    paidDateTime = ChangeDateFormat('dateTime', new Date())
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.userId = userId;
        this.date = date ? ChangeDateFormat('date', date) : '' ;
        this.toDate = toDate ? ChangeDateFormat('date', toDate) : '' ;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.status = status;
        this.reason = reason;
        this.approvedDeniedByName = approvedDeniedByName;
        this.fixeds =  new ResponseReimbursementFixed(fixeds);
        this.mileage =  new ResponseReimbursementMileage(mileage);
        this.expenses = new ResponseReimbursementExpense(expenses);
        this.grossAmount = grossAmount;
        this.createdAt = createdAt ? ChangeDateFormat('dateTime', createdAt) : '' ;
        this.newReimbursementId = newReimbursementId;
        this.recipientAccount = recipientAccount;
        this.paidDateTime = paidDateTime ? ChangeDateFormat('dateTime', paidDateTime) : '' ;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes = {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            orgId :{
                'label': 'Org Id',
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName: {
                'label': i18n.t('modelLabel.orgName'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            userId :{
                'label': 'User Id',
                'sortable': true,
                'sortField': 'userId',
                'value': (item) => item.userId || "",
            },
            date :{
                'label': i18n.t('modelLabel.date'),
                'sortable': true,
                'sortField': "date",
                'value': (item) => item.date ? ChangeDateFormat('date', item.date) : '',
                'type': 'dateTime' ,
            },
            toDate :{
                'label': i18n.t('modelLabel.date'),
                'sortable': true,
                'sortField': "toDate",
                'value': (item) => item.toDate ? ChangeDateFormat('date', item.toDate) : '',
            },
            purpose :{
                'label': i18n.t('reimbursement.purpose'),
                'sortable': true,
                'sortField': "purpose",
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': 'Aktivitets typ',
                'sortable': true,
                'sortField': "costBearerId",
                'value': (item) => item.purpose || "",
            },
            costBearerName :{
                'label': i18n.t('reimbursement.costBearer'),
                'sortable': true,
                'sortField': "costBearerName",
                'value': (item) => item.costBearerName || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': "status",
                'insideClass' : (item)=> item.status === 'Godkänd' ? 'box-green me-4 fixed-width':  (item.status === 'Utbetald' ? 'box-green me-4 fixed-width' : (item.status === 'Nekad' ? "box-red me-4 fixed-width" : (item.status === 'Förslag' ? 'box-orange me-4 fixed-width': "box-custom-grey me-4 fixed-width"))),
                'value': (item) => item.status === 'Skickat' ? i18n.t('reimbursement.sent') : item.status === 'Förslag' ? i18n.t('reimbursement.draft') : item.status === 'Nekad' ? i18n.t('attest.denied') : item.status === 'Godkänd' ? i18n.t('attest.approved') : item.status === 'Utbetald' ? i18n.t('modelLabel.paidOut') : '' || "",
                'type': 'select' ,
            },
            reason :{
                'label': i18n.t('modelLabel.reason'),
                'sortable': true,
                'sortField': "reason",
                'value': (item) => item.reason || "",
            },
            approvedDeniedByName :{
                'label': i18n.t('modelLabel.approvedDeniedByName'),
                'sortable': true,
                'sortField': "approvedDeniedByName",
                'value': (item) => item.approvedDeniedByName || "",
            },
            fixeds :{
                'label': 'fixed',
                'sortable': true,
                'sortField': "fixed",
                'value': (item) => item.fixeds || "",
            },
            mileage :{
                'label': 'mileage',
                'sortable': true,
                'sortField': "mileage",
                'value': (item) => item.mileage || "",
            },
            expenses :{
                'label': 'expenses',
                'sortable': true,
                'sortField': "expenses",
                'value': (item) => item.expenses || "",
            },
            grossAmount :{
                'label': 'grossAmount',
                'sortable': true,
                'sortField': 'grossAmount',
                'value': (item) => item.grossAmount,             
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : '',
                'type': 'dateTime' ,
            },
            newReimbursementId: {
                'label': i18n.t("reimbursement.newReimbursementId"),
                'sortable': true,
                'sortField': 'newReimbursementId',
                'value': (item) => item.newReimbursementId || '',
            },
            recipientAccount :{
                'label': i18n.t('modelLabel.recipientAccount'),
                'sortable': true,
                'sortField': "recipientAccount",
                'value': (item) => item.recipientAccount || "",
            },
            paidDateTime: {
                'label': i18n.t('modelLabel.paidDateTime'),
                'sortable': true,
                'sortField': 'paidDateTime',
                'value': (item) => item.paidDateTime ? ChangeDateFormat('dateTime', item.paidDateTime) : '',
            },
        }

        return attributes;
    }
}

export default ResponseReimbursement;