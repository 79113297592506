import * as yup from "yup";
import {string} from "yup";
import i18n from 'i18next';
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";
class ResponseQRCode{
    constructor({
                    id = '',
                    visualId = '',
                    orgId = '',
                    orgName = '',
                    title = '',
                    name = '',
                    description = '',
                    costBearerId = '',
                    costBearerName = '',
                    accountingId = '',
                    accountingName = '',
                    thereIsComment = false,
                    thereIsCustomAmount = false,
                    fileId = '',
                    fileUrl = '',
                    filePDFId = '',
                    filePDFUrl = '',
                    defaultAmounts = [],
                    status = '',
                    totalPaid = '',
                    totalPayments = '',
                    createdByName = '',
                    createdAt = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.title = title;
        this.name = name;
        this.description = description;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.accountingId = accountingId;
        this.accountingName = accountingName;
        this.thereIsComment = thereIsComment;
        this.thereIsCustomAmount = thereIsCustomAmount;
        this.fileId = fileId;
        this.fileUrl = fileUrl;
        this.filePDFId = filePDFId;
        this.filePDFUrl = filePDFUrl;
        this.defaultAmounts = defaultAmounts;
        this.status = status;
        this.totalPaid = totalPaid;
        this.totalPayments = totalPayments;
        this.createdByName = createdByName;
        this.createdAt = createdAt;
    }

    static getAttributes(filter = null) {

        const attributes = {
            id :{
                'value': (item) => item.id || "",
            },
            visualId:{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'clientId',
                'value': (item) => item.visualId || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.orgId'),
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': i18n.t('modelLabel.orgName'),
                'sortable': true,
                'sortField': 'orgName',
                'value': (item) => item.orgName || "",
            },
            title :{
                'label': i18n.t('QRCode.title'),
                'sortable': true,
                'sortField': 'title',
                'value': (item) => item.title || "",
            },
            name :{
                'label': i18n.t('modelLabel.name'),
                'sortable': true,
                'sortField': 'Name',
                'value': (item) => item.name || "",
            },
            description :{
                'label': i18n.t('modelLabel.description'),
                'sortable': true,
                'sortField': 'description',
                'value': (item) => item.description || "",
            },
            costBearerId: {
                'label': i18n.t('reimbursement.costBearer'),
                'sortable': true,
                'sortField': 'costBearerId',
                'value': (item) => item.costBearerId || "",
            },
            costBearerName: {
                'label': i18n.t('reimbursement.costBearerName'),
                'sortable': true,
                'sortField': 'costBearerName',
                'value': (item) => item.costBearerName || "",
            },
            accountingId: {
                'label': i18n.t('reimbursement.accountingId'),
                'sortable': true,
                'sortField': 'accountingId',
                'value': (item) => item.accountingId || "",
            },
            accountingName: {
                'label': i18n.t('reimbursement.accounting'),
                'sortable': true,
                'sortField': 'accountingName',
                'value': (item) => item.accountingName || "",
            },
            thereIsCustomAmount: {
                //'label': i18n.t('reimbursement.accounting'),
                'sortable': true,
                'sortField': 'thereIsCustomAmount',
                'value': (item) => item.thereIsCustomAmount || false,
            },
            thereIsComment: {
                //'label': i18n.t('reimbursement.accounting'),
                'sortable': true,
                'sortField': 'thereIsComment',
                'value': (item) => item.thereIsComment || false,
            },
            fileId: {
                'label': i18n.t('modelLabel.fileId'),
                'sortable': true,
                'sortField': 'fileId',
                'value': (item) => item.fileId || "",
            },
            fileUrl: {
                'label': i18n.t('modelLabel.fileName'),
                'sortable': true,
                'sortField': 'fileUrl',
                'value': (item) => item.fileUrl || "",
            },
            filePDFId: {
                'label': 'PDFid', //i18n.t('modelLabel.fileId')
                'sortable': true,
                'sortField': 'filePDFId',
                'value': (item) => item.filePDFId || "",
            },
            filePDFUrl: {
                'label': 'PDFurl', //i18n.t('modelLabel.fileName')
                'sortable': true,
                'sortField': 'filePDFUrl',
                'value': (item) => item.filePDFUrl || "",
            },
            defaultAmounts: {
                'label': i18n.t('QRCode.defaultAmounts'),
                'sortable': true,
                'sortField': 'defaultAmounts',
                'value': (item) => item.defaultAmounts || "",
            },
            status: {
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': 'status',
                'value': (item) => item.status || "",
            },
            totalPaid: {
                'label': i18n.t('QRCode.totalPaid'),
                'sortable': true,
                'sortField': 'totalPaid',
                'insideClass' : (item)=> item.totalPaid >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                'value': (item) => item.totalPaid ? ToCustomDecimal(item.totalPaid) +' kr' : "0 kr",
                'type': 'number'
            },
            totalPayments: {
                'label': i18n.t('QRCode.totalPayments'),
                'sortable': true,
                'sortField': 'totalPayments',
                'insideClass' :  (item)=> item.totalPaid ? 'text-end fixed-width ' : 'text-end fixed-width',
                'value': (item) => item.totalPayments || "0",
                'type': 'number'
            },
            createdByName: {
                'label': i18n.t('modelLabel.creatorName'),
                'sortable': true,
                'sortField': 'createdByName',
                'value': (item) => item.createdByName || "",
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : '',
                'type': 'dateTime'
            },
        }
        return attributes;
    }
}
export default ResponseQRCode;