import {Button, Card, Col, Row, Spinner} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import AdminResponseOpenPaymentModel from "../Models/Response/AdminResponseOpenPaymentModel";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import AdminResponseCreatePaymentModel from "../Models/Response/AdminResponseCreatePaymentModel";
import AdminResponseUpdateBasketStatusModel from "../Models/Response/AdminResponseUpdateBasketStatusModel";
import AdminRequestCreateBasketModel from "../Models/Request/AdminRequestCreateBasketModel";
import AdminRequestCreatePaymentModel from "../Models/Request/AdminResquestCreatePaymentModel";
import $ from 'jquery';
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";
import { SaveLocalStorage } from "../../Common/Helpers/SaveLocalStorage";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import AdminRequestCreatePaymentBGPGModel from "../Models/Request/AdminRequestCreatePaymentBGPLModel";
import { CustomButton } from "../../Common/Components/Button/CustomButton";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import AdminRequestAttestDenyModel from "../../Attest/Model/Request/AdminRequestAttestDenyModel";
import { CustomInput } from "../../Common/Components/Input/CustomInput";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";
import { FlashMessagePaymentDuplicated } from "../../Common/Helpers/FlashMessagePaymentsDuplicated";
import iconTabGreen from "../../AppSystem/assets/img/tab_g.svg";
import iconTabWhite from "../../AppSystem/assets/img/tab_w.svg";

export function PendingBankTransferList ( ) {

    const { t } = useTranslation()
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();

    // REQUEST
    const [requestCreatePayment, setRequestCreatePayment] = useState(new AdminRequestCreatePaymentModel());
    const [requestCreatePaymentBGPG, setRequestCreatePaymentBGPG] = useState(new AdminRequestCreatePaymentBGPGModel());
    const [requestCreateBasket, setRequestCreateBasket] = useState(new AdminRequestCreateBasketModel())

    //RESPONSE SEARCH
    const [responseOpenPaymentList, setResponseOpenPaymentList] = useState('');

    const [showButtonSing, setShowButtonSing] = useState(false);
    const [displaySpinner, setDisplaySpinner] = useState(false);

    //RESPONSE
    const [responseCreatePayment, setResponseCreatePayment] = useState(new AdminResponseCreatePaymentModel());
    const [responseUpdateBasketStatus, setResponseUpdateBasketStatus] = useState(new AdminResponseUpdateBasketStatusModel());

    // MODAL OPTIONS
    const [displayBulkPaymentModal, setDisplayBulkPaymentModal] = useState(false);
    const [showPendingPayment, setShowPendingPayment] = useState(false);
    const [paymentDetailsList, setPaymentDetailsList] = useState([]);
    const [titlePaymentDetails, setTitlePaymentDetails] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [pendingStatus, setPendingStatus] = useState('');
    const [messageError, setMessageError] = useState('');

    //CHECK PAYMENT
    const [openPaymentList, setOpenPaymentList] = useState([]);
    const [checked, setChecked] = useState([]);

    //CHECK MOUSE OVER
    const [isMouseOver, setIsMouseOver] = useState(false);

    // REVERSE PAYMENT
    const [showConfirmModalReverse, setShowConfirmModalReverse] = useState(false);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [errorReason, setErrorReason] = useState(false);
    const [apiRequestReversePayment, setApiRequestReversePayment] = useState(new AdminRequestAttestDenyModel());
    const [showConfirmModalApprove, setShowConfirmModalApprove] = useState(false);
    const completeSchemaDeny = AdminRequestAttestDenyModel.getAttributes( null, true);
    const [apiResponseReversePayment, setApiResponseReversePayment] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isAcceptDisabled, setIsAcceptDisabled] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
    const [duplicatePaymentsList, setDuplicatePaymentsList] = useState([]);

    const [hovered, setHovered] = useState(false);

    const idToShowPaymentDetails = new URLSearchParams(window.location.search).get('paymentId');
    const [intervalId, setIntervalId] = useState(null);

    const handleMouseEnter = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    let permissionDeny = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.open-payment") || CheckUserPermission("admin.open-payment.deny")) {
        permissionDeny = true;
    }
    let permissionSign = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.open-payment") || CheckUserPermission("admin.open-payment.sign")) {
        permissionSign = true;
    }

    let permissionDangerZone = false;
    if (CheckUserPermission("danger-zone") || CheckUserPermission("danger-zone.simulate")) {
        permissionDangerZone = true;
    }

    const startInterval = (type = null) => {
        if (intervalId) return;
        
        const id = setInterval(async () => {
            const savedList = LoadLocalStorage("openPaymentList");
            if (savedList) {
                const restoredList = JSON.parse(savedList);
                if (restoredList.length > 0) {
                    await UnlockedPayment(restoredList, type);                
                }
            }
        }, 2000); 
    
        setIntervalId(id);
    };

    const stopInterval = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
            localStorage.removeItem("openPaymentList");
        }
    };

    useEffect(() => {
        return () => stopInterval();
    }, []);
    
    const stopIntervalWithoutUnlocking = async () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        await UnlockedPayment([]);
    };
    const UnlockedPayment = async (list) => {
        const paymentsCollectionIds = list.map(item => item.id );
        const updatedBasketModel = new AdminRequestCreateBasketModel({ paymentsCollectionIds });
        const handleApical2 = (response) => {              
        }
            const apiCallOptions = {
                method: "post",
                url: "/open-payment/admin/payment-unlocked",
                data: updatedBasketModel
            };
            try {
                await ApiCall(apiCallOptions, handleApical2, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
    }

    const UnlockedAttestPayment = async (list) => {
        const paymentsCollectionIds =  list ;
        const updatedBasketModel = new AdminRequestCreateBasketModel({ paymentsCollectionIds });
        const handleApical2 = (response) => {              
        }
            let url =  "/open-payment/admin/payment-unlocked-attest";
            const apiCallOptions = {
                method: "post",
                url: url,
                data: updatedBasketModel
            };
            try {
                await ApiCall(apiCallOptions, handleApical2, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
    }

    useEffect(() => {     
        UnlockedAttestPayment([]);
    }, []);

    //DELETE LOCALSTORAGE
    const search = LoadLocalStorage("search");

    useEffect(() => {
        if (search) {
            localStorage.removeItem('search');
        }
    }, [search]);

    useEffect(() => {
        if (idToShowPaymentDetails !== null && responseOpenPaymentList?.items?.length > 0) {
            const foundItem = responseOpenPaymentList.items.find(
                (item) => item.id === idToShowPaymentDetails
            );
            if (foundItem) {

                setTitlePaymentDetails(foundItem.visualId + ' - ' + foundItem.creditorName);
                setPaymentDetailsList(foundItem.paymentDetails);
                setPaymentId(foundItem.id);
                setPaymentStatus(foundItem.status);
                setShowPaymentDetails(true);
            }
        }
    }, [responseOpenPaymentList]);
    const dataCheck = (e, id) => {
        const index = responseOpenPaymentList.items.findIndex(item => item.id === id);

        if (e.target.checked === false && openPaymentList.some(item => item.id === id) ) {  
            $('#checkInput').prop('checked', false);       
            const updatedList = openPaymentList.filter(item => item.id !== id);
            setChecked(prevData => {
                return prevData.filter((item, i) => item.id !== id);
            });
            setOpenPaymentList(updatedList); 
            
            UnlockedAttestPayment([id]);
            //stopInterval();       
        } else {
            LockedAttestPayments(id);
            setChecked(prevData => {
                return [
                    ...prevData,
                    {
                        id: id,
                    }
                ];
            });
            if (index !== -1) {
                setOpenPaymentList(prevList => [...prevList, responseOpenPaymentList.items[index]]);
            }                     
        }

        //aqui se construyen los tados para crear el pago con (createPayment)
        let selectedItem = responseOpenPaymentList.items[index];
        let instructedAmountValue;
        let creditorNameValue;
        let creditorAccountIbanValue;
        let creditorGiroValue;
        let invoiceReference;
        let debtorAccountMessage;
        let paymentCollectionIdValue;
        let messageValue;

        if (selectedItem) {
            if(selectedItem.paymentType === 'Bankkonto'){
                
                instructedAmountValue = {
                    amount:selectedItem.instructedAmount,
                };
                creditorNameValue = selectedItem.creditorName;
                creditorAccountIbanValue = {
                    ibanorbban: selectedItem.creditorAccountIban,
                    currency:'SEK',
                    clearingNumber:selectedItem.clearingNumber,
                }
                paymentCollectionIdValue = selectedItem.id;
                messageValue = selectedItem.visualId;
            }else if (selectedItem.paymentType === 'Bankgiro' || selectedItem.paymentType === 'Plusgiro') {
                
                invoiceReference = selectedItem.ocr;
                debtorAccountMessage = selectedItem.debtorAccountMessage;
                instructedAmountValue = {
                    amount:selectedItem.instructedAmount,
                };
                creditorNameValue = selectedItem.creditorName;
                creditorGiroValue = {
                    giroNumber: selectedItem.creditorAccountIban,
                    giroType:selectedItem.paymentType.toUpperCase(),
                }
                paymentCollectionIdValue = selectedItem.id;
                messageValue = selectedItem.visualId;
            }}                    

        if (selectedItem.paymentType === 'Bankkonto' ) {
            setRequestCreatePayment((prevFormData) => ({
                ...prevFormData,
                instructedAmount: instructedAmountValue,
                creditorName: creditorNameValue,
                creditorAccount: creditorAccountIbanValue,
                message: `LRF(${messageValue.toString()})`, //messageValue ,ahora solo permite 10 caracteres, mirar si eso esta bien o hay que cambiarlo porque si se le da el valor de reimbursment no funciona
                product: 'domestic',
                paymentCollectionId: paymentCollectionIdValue,
            }));
        } else if ((selectedItem.paymentType === 'Bankgiro' || selectedItem.paymentType === 'Plusgiro') ) {
            
            setRequestCreatePaymentBGPG((prevFormData) => ({
                ...prevFormData,
                instructedAmount: instructedAmountValue,
                creditorName: creditorNameValue,
                invoiceReference: selectedItem.ocr,
                debtorAccountMessage: `LRF(${messageValue.toString()})`,
                creditorGiro: {
                    giroNumber: selectedItem.creditorAccountIban,
                    giroType: selectedItem.paymentType.toUpperCase(), // Asigna BANKGIRO o PLUSGIRO
                },
                paymentCollectionId: paymentCollectionIdValue,
            }));
        }
    }

    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (apiResponseReversePayment !== null) {   
            setShowConfirmModalReverse(false);
            FlashMessage(t('validationMessage.paymentDenied'), 'flash-messageSuccess');
            setTimeout(()=>{
                CustomReload();
            },2000);
        }
    }, [apiResponseReversePayment]);

    // PUT APPROVED
    const handleValidation = async (type) => {
        try {

            await completeSchemaDeny.validate(apiRequestReversePayment, { abortEarly: false });
            const apiCallOptions = {
                method: "delete",
                url: "/open-payment/admin",
                data: apiRequestReversePayment,
            };

            try {
                await ApiCall(apiCallOptions, setApiResponseReversePayment, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
        } catch (err) {
            setErrorReason(true);
            console.log(err)
        }
    }

    //CREATE PAYMENT
    const CreatePayment = async (item = null) => {
        try {
            const handleApicall = (response) => {
                if(response !== null){
                    setResponseCreatePayment(new AdminResponseCreatePaymentModel(response))
                }                
            }
            if ((requestCreatePayment && Object.keys(requestCreatePayment.instructedAmount).length > 0 ) || item !== null) {               
                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment",
                    data: item !== null ? item : requestCreatePayment,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall, setErrorMessage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
                if (item !== null) {
                    if(!requestCreateBasket.paymentsCollectionIds.some(itemPay => itemPay === item?.paymentCollectionId)) {
                        setRequestCreateBasket((prevData) => {
                            return {
                                ...prevData,
                                paymentsCollectionIds: [
                                    ...prevData.paymentsCollectionIds,
                                    item.paymentCollectionId
                                ]
                            }
                        })

                     }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    // ERROR MESSAGES
    useEffect(() => {
        if (errorMessage !== '') {
            if (errorMessage.includes("{")) {
                 FlashMessage(<FlashMessagePaymentDuplicated jsonString={errorMessage} />, 'flash-messageError', null,true);
            }
            else if (errorMessage === 'Payment processed') {
                FlashMessage("En annan användare har redan undertecknat fullmakten för detta ärende. Kontrollera ärendets status.", 'flash-messageError', 5000);
                setTimeout(() => {
                    CustomReload();
                }, 6000);
            }
            else if(errorMessage === 'Locked'){
                FlashMessage("En annan användare har redan lagt den här betalningen i korgen. Kontrollera statusen på ärendet om några minuter.", 'flash-messageError', 5000);
                setTimeout(() => {
                    CustomReload();
                }, 5200);
            }
            else{
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
            setErrorMessage('');         
        }
    }, [errorMessage]);

    const CreatePaymentBGPG = async (item = null) => {
        try {
            const handleApicall = (response) => {
                if(response !== null){
                 setResponseCreatePayment(new AdminResponseCreatePaymentModel(response))
                }
            }
            if ((requestCreatePaymentBGPG && Object.keys(requestCreatePaymentBGPG.instructedAmount).length > 0 ) || item !== null) {

                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment/giro",
                    data: item !== null ? item : requestCreatePaymentBGPG,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall, setErrorMessage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
                if (item !== null) {
                    if(!requestCreateBasket.paymentsCollectionIds.some(itemPay => itemPay === item?.paymentCollectionId)) {
                        setRequestCreateBasket((prevData) => {
                            return {
                                ...prevData,
                                paymentsCollectionIds: [
                                    ...prevData.paymentsCollectionIds,
                                    item.paymentCollectionId
                                ]
                            }
                        })

                     }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

   
    useEffect(() => {
        if(requestCreatePayment.paymentCollectionId !== ''){
            if(requestCreateBasket.paymentsCollectionIds.some(item => item === requestCreatePayment?.paymentCollectionId)) {
                const updatedList = requestCreateBasket.paymentsCollectionIds.filter(item => item !== requestCreatePayment?.paymentCollectionId);
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: updatedList
                    };
                });
            } else {
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: [
                            ...prevRequestCreateBasket.paymentsCollectionIds,
                            requestCreatePayment?.paymentCollectionId
                        ]
                    };
                });
            }
            setRequestCreatePayment(new AdminRequestCreatePaymentModel());
        }

        if(requestCreatePaymentBGPG.paymentCollectionId !== ''){
            CreatePaymentBGPG();
            if(requestCreateBasket.paymentsCollectionIds.some(item => item === requestCreatePaymentBGPG?.paymentCollectionId)) {
                const updatedList = requestCreateBasket.paymentsCollectionIds.filter(item => item !== requestCreatePaymentBGPG?.paymentCollectionId);
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: updatedList
                    };
                });
            } else {
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: [
                            ...prevRequestCreateBasket.paymentsCollectionIds,
                            requestCreatePaymentBGPG?.paymentCollectionId
                        ]
                    };
                });
            }
            setRequestCreatePaymentBGPG(new AdminRequestCreatePaymentBGPGModel());
        }
    }, [requestCreatePayment,requestCreatePaymentBGPG]);
    useEffect(() => {

        if(requestCreatePayment.length > 0){
            requestCreatePayment.forEach(payment => {
                setRequestCreateBasket(prevBasket => {
                    if(prevBasket.paymentsCollectionIds.some(id => id === payment.paymentCollectionId)) {
                        const updatedList = prevBasket.paymentsCollectionIds.filter(id => id !== payment.paymentCollectionId);
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: updatedList
                        };
                    } else {
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: [
                                ...prevBasket.paymentsCollectionIds,
                                payment.paymentCollectionId
                            ]
                        };
                    }
                });
            });
            setRequestCreatePayment([]);
        }
        
        if(requestCreatePaymentBGPG.length > 0){
            requestCreatePaymentBGPG.forEach(payment => {
                CreatePaymentBGPG();
                setRequestCreateBasket(prevBasket => {
                    if(prevBasket.paymentsCollectionIds.some(id => id === payment.paymentCollectionId)) {
                        const updatedList = prevBasket.paymentsCollectionIds.filter(id => id !== payment.paymentCollectionId);
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: updatedList
                        };
                    } else {
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: [
                                ...prevBasket.paymentsCollectionIds,
                                payment.paymentCollectionId
                            ]
                        };
                    }
                });
            });
            setRequestCreatePaymentBGPG([]);
        }
    }, [requestCreatePayment, requestCreatePaymentBGPG]);

    //CREATE BASKET
    const CreateBasket = async () => {
        try {
            const handleApicall = (response) => {   
                if(response !== null){
                    startInterval();   
                    setResponseUpdateBasketStatus(new AdminResponseUpdateBasketStatusModel(response))               
                }    
                setIsSpinnerVisible(false);                      
            }
            if (requestCreateBasket && requestCreateBasket.paymentsCollectionIds?.length > 0 ) {
                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment/create-basket",
                    data: requestCreateBasket,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall, setErrorMessage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
        
    
    }

    const LockedPayments = async () => {
        try {
            const handleApicall = (response) => {              
                if(response !== null){
                    setDisplayBulkPaymentModal(true);
                    startInterval();
                }    
                setIsSpinnerVisible(false); 
                setIsProcessing(false);
                setIsSpinnerVisible(false);                       
            }
            if (requestCreateBasket && requestCreateBasket.paymentsCollectionIds?.length > 0 ) {
                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment/locked-payment",
                    data: requestCreateBasket,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall, setErrorMessage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const LockedAttestPayments = async (id = null) => {
        try {
            const handleApicall = (response) => {              
                if(response !== null){
                    startInterval('attest');
                }              
            }
            let payload = {
                paymentsCollectionIds: id !== null ? [id] : []
              };
            if (id != null) {

                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment/locked-payment-attest",
                    data: payload,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall, setErrorMessage);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const SimulatePayment = async (id) => {

        try {
            const handleApicall = (response) => {
                CustomReload(`${ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST}`);            
            }
            
            if (id !== '') {
                const apiCallOptions = {
                    method: "get",
                    url: `/open-payment/simulated-payment?paymentId=${id}`,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const GetPaymentStatus = async (id, product, message) => {
        
        try {
            const handleApicall = (response) => {
                setPendingStatus(response.transactionStatus);
                if(message){
                    setMessageError(message);
                }
                setShowPendingPayment(true);   

                if(response.transactionStatus === 'ACCC' ||  response.transactionStatus === 'ACSC')
                setTimeout(() => {
                    CustomReload(ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST);
                }, 5000);
            }
        
            if(product === 'Bankgiro' || product === 'Plusgiro'){
                product = 'swedish-giro';
            }

            if (id !== '') {
                const apiCallOptions = {
                    method: "get",
                    url: `/open-payment/pending-payment-initiation-status?paymentId=${id}&product=${product}`,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
   
    //REDIRECT TO PAGE FOR SIGN
    useEffect(() => {
        if (responseUpdateBasketStatus?.data !== '') {    
            const payForSign = responseUpdateBasketStatus.data;
            window.open(payForSign) ;
            setTimeout(CustomReload, 1000);
        }
        
    }, [responseUpdateBasketStatus]);

    const renderCheckColumn = (id) => {
        const index = checked.findIndex(item => item.id === id);
        return (
            <>
                <input type="checkbox" checked={index !== -1 ? true : false} onChange={(e)=>dataCheck(e, id)}/>
            </>
        );
    }

    const handleChangePaymentReverseModelId = (modelId) => {
        setApiRequestReversePayment((prevFormData) => ({
            ...prevFormData,
            modelId: modelId,
        }))
    }

    const handleChangePaymentReverseModel = (model) => {
        setApiRequestReversePayment((prevFormData) => ({
            ...prevFormData,
            model: model,
        }))
    }

    // HANDLE DENY
    const handleChangePaymentReverseId = (id) => {
        setApiRequestReversePayment((prevFormData) => ({
            ...prevFormData,
            id: id,
        }))
    }

    // HANDLE DENY REASON
    const handleChangePaymentReverseReason = (e) => {
        if (e && e.target && e.target.value && e.target.value !== "") {
            setApiRequestReversePayment((prevFormData) => ({
                ...prevFormData,
                reason: e.target.value,
            }))
            setErrorReason(false);
        } else {
            setErrorReason(true);
        }
    }

    const renderActionColumn = (item) => {
        return (
            <>
            {/* {item.message && item.status === 'Obetald' ? 
            <button id={item.message} className="rounded me-2 py-2 border-0 text-red bg-transparent p-custom-button position-relative class-approve" >
                    <i className="bx bxs-hand pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button-error-bank-account">
                        {<pre className="font-size-12">{item.message}</pre>}
                    </div>
                </button>
            : ''} */}
            {item.warning ? 
            <button id={item.id} className="rounded me-2 py-2 border-0 text-red bg-transparent p-custom-button position-relative class-approve" >
                    <i className="bx bx-error pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button-error-bank-account">
                        {<pre className="font-size-12">{item.warning}</pre>}
                    </div>
                </button>
             : ''}
                 
            {permissionDangerZone === true ?
                <CustomButton icon="bx bx-dollar-circle pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('payReceive.pay')} customClasses="rounded py-2 mx-2" buttonBorder={false} id={item.id} onClick={() => {
                     SimulatePayment(item.id);
                }} />
            : ''}
            {item.status && item.authorisationId?
                <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            let message;
                            if(item.message){
                                message = item.message;
                            }
                            GetPaymentStatus(item.id, item.paymentType, message);
                        }} >
                    <i className="bx bx-question-mark pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {'För granskning' }
                    </div>
                </button>
            : ''}
            {item.paymentDetails  ?
                <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            if(item.paymentDetails){
                                setTitlePaymentDetails(item.visualId + ' - ' + item.creditorName);
                                setPaymentDetailsList(item.paymentDetails);
                                setPaymentId(item.id);
                                setPaymentStatus(item.status);
                                handleChangePaymentReverseId(item.id);
                                setShowPaymentDetails(true);
                            }else{
                                FlashMessage('Det är inte möjligt att se denna betalning, eftersom den tillhör den tidigare uppdateringen, för att se den gå till attesten eller direkt till roten av modellen t.ex: Ersättningsbegäran ,Årligt arvode ,Betala Faktura etc...', 'flash-messageError');
                            }                        
                        }} >
                    {item.paymentDetails.length} <i className="bx bx-show pb-custom align-middle size-icon-custom-button" /> 
                    <div className="tooltip-custom-button">
                    {t('button.info')}
                    </div>
                </button>   
             
            :   <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.id}`);
                        }} >
                    <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('button.info')}
                    </div>
                </button>}         
            </>
        );
    }

    // const actionAllCheckColumn = async (e) => {      
    //     if (e.target.checked === true) {
    //         const updatedCheckboxes = responseOpenPaymentList.items
    //         .filter(item => item.status !== 'Väntande')
    //         .map(item  => ({
    //             id: item.id,
    //         }));
    //         const filteredItems = responseOpenPaymentList.items.filter(item => item.status !== 'Väntande');
    //         setChecked(updatedCheckboxes);
    //         setOpenPaymentList(filteredItems);
            
    //         responseOpenPaymentList.items.map(itemR => {
    //             const index = responseOpenPaymentList.items.findIndex(item => item.id === itemR.id);

    //             let selectedItem = responseOpenPaymentList.items[index];
    //             let instructedAmountValue;
    //             let creditorNameValue;
    //             let creditorAccountIbanValue;
    //             let creditorGiroValue;
    //             let invoiceReference;
    //             let debtorAccountMessage;
    //             let paymentCollectionIdValue;
    //             let messageValue;

    //             if (selectedItem) {
    //                 if(selectedItem.paymentType === 'Bankkonto'){
                        
    //                     instructedAmountValue = {
    //                         amount:selectedItem.instructedAmount,
    //                     };
    //                     creditorNameValue = selectedItem.creditorName;
    //                     creditorAccountIbanValue = {
    //                         ibanorbban: selectedItem.creditorAccountIban,
    //                         currency:'SEK',
    //                         clearingNumber:selectedItem.clearingNumber,
    //                     }
    //                     paymentCollectionIdValue = selectedItem.id;
    //                     messageValue = selectedItem.visualId;
    //                 }else if (selectedItem.paymentType === 'Bankgiro' || selectedItem.paymentType === 'Plusgiro') {
                        
    //                     invoiceReference = selectedItem.ocr;
    //                     debtorAccountMessage = selectedItem.debtorAccountMessage;
    //                     instructedAmountValue = {
    //                         amount:selectedItem.instructedAmount,
    //                     };
    //                     creditorNameValue = selectedItem.creditorName;
    //                     creditorGiroValue = {
    //                         giroNumber: selectedItem.creditorAccountIban,
    //                         giroType:selectedItem.paymentType.toUpperCase(),
    //                     }
    //                     paymentCollectionIdValue = selectedItem.id;
    //                     messageValue = selectedItem.visualId;
    //                 }}                    

    //             if (selectedItem.paymentType === 'Bankkonto' ) {
    //                 console.log("Punto 3", selectedItem)
    //                 setRequestCreatePayment((prevFormData) => ({
    //                     ...prevFormData,
    //                     instructedAmount: instructedAmountValue,
    //                     creditorName: creditorNameValue,
    //                     creditorAccount: creditorAccountIbanValue,
    //                     message: `LRF(${messageValue.toString()})`, //messageValue ,ahora solo permite 10 caracteres, mirar si eso esta bien o hay que cambiarlo porque si se le da el valor de reimbursment no funciona
    //                     product: 'domestic',
    //                     paymentCollectionId: paymentCollectionIdValue,
    //                 }));
    //             } else if ((selectedItem.paymentType === 'Bankgiro' || selectedItem.paymentType === 'Plusgiro') ) {
                    
    //                 setRequestCreatePaymentBGPG((prevFormData) => ({
    //                     ...prevFormData,
    //                     instructedAmount: instructedAmountValue,
    //                     creditorName: creditorNameValue,
    //                     invoiceReference: selectedItem.ocr,
    //                     debtorAccountMessage: `LRF(${messageValue.toString()})`,
    //                     creditorGiro: {
    //                         giroNumber: selectedItem.creditorAccountIban,
    //                         giroType: selectedItem.paymentType.toUpperCase(), // Asigna BANKGIRO o PLUSGIRO
    //                     },
    //                     paymentCollectionId: paymentCollectionIdValue,
    //                 }));
    //             }
    //         });
    //     } else {         
    //         setChecked([]);
    //         setOpenPaymentList([]);
    //         setRequestCreateBasket(new AdminRequestCreateBasketModel())
    //     }
    // }
    const actionAllCheckColumn = async (e) => {      
        if (e.target.checked === true) {
            const filteredItems = responseOpenPaymentList.items.filter(item => item.status !== 'Väntande');
            const updatedCheckboxes = filteredItems.map(item => ({ id: item.id }));
            
            setChecked(updatedCheckboxes);
            setOpenPaymentList(filteredItems);
            
            const newBankkontoPayments = [];
            const newBGPGPayments = [];
            
            filteredItems.forEach(item => {
                LockedAttestPayments(item.id);
                let instructedAmountValue = { amount: item.instructedAmount };
                let paymentCollectionIdValue = item.id;
                let messageValue = item.visualId;
                
                if (item.paymentType === 'Bankkonto') {
                    newBankkontoPayments.push({
                        instructedAmount: instructedAmountValue,
                        creditorName: item.creditorName,
                        creditorAccount: {
                            ibanorbban: item.creditorAccountIban,
                            currency: 'SEK',
                            clearingNumber: item.clearingNumber,
                        },
                        message: `LRF(${messageValue.toString()})`,
                        product: 'domestic',
                        paymentCollectionId: paymentCollectionIdValue,
                    });
                } else if (item.paymentType === 'Bankgiro' || item.paymentType === 'Plusgiro') {
                    newBGPGPayments.push({
                        instructedAmount: instructedAmountValue,
                        creditorName: item.creditorName,
                        invoiceReference: item.ocr,
                        debtorAccountMessage: `LRF(${messageValue.toString()})`,
                        creditorGiro: {
                            giroNumber: item.creditorAccountIban,
                            giroType: item.paymentType.toUpperCase(), // BANKGIRO o PLUSGIRO
                        },
                        paymentCollectionId: paymentCollectionIdValue,
                    });
                }
            });
            
            setRequestCreatePayment(newBankkontoPayments);
            setRequestCreatePaymentBGPG(newBGPGPayments);
            
            const allPaymentIds = [
                ...newBankkontoPayments.map(payment => payment.paymentCollectionId),
                ...newBGPGPayments.map(payment => payment.paymentCollectionId)
            ];
            setRequestCreateBasket(prevBasket => ({
                ...prevBasket,
                paymentsCollectionIds: allPaymentIds,
            }));                  
        } else {
            UnlockedAttestPayment([]);         
            setChecked([]);
            setOpenPaymentList([]);
            setRequestCreateBasket(new AdminRequestCreateBasketModel());
        }
    };
    
    useEffect(() => {  
        // Procesa los pagos tipo Bankkonto
        if(requestCreatePayment.length > 0){
            requestCreatePayment.forEach(payment => {
                setRequestCreateBasket(prevBasket => {
                    if(prevBasket.paymentsCollectionIds.some(id => id === payment.paymentCollectionId)) {
                        const updatedList = prevBasket.paymentsCollectionIds.filter(id => id !== payment.paymentCollectionId);
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: updatedList
                        };
                    } else {
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: [
                                ...prevBasket.paymentsCollectionIds,
                                payment.paymentCollectionId
                            ]
                        };
                    }
                });
            });
            // Se limpia el array para evitar reprocesos
            setRequestCreatePayment([]);
        }
        
        // Procesa los pagos tipo Bankgiro/Plusgiro
        if(requestCreatePaymentBGPG.length > 0){
            requestCreatePaymentBGPG.forEach(payment => {
                CreatePaymentBGPG();
                setRequestCreateBasket(prevBasket => {
                    if(prevBasket.paymentsCollectionIds.some(id => id === payment.paymentCollectionId)) {
                        const updatedList = prevBasket.paymentsCollectionIds.filter(id => id !== payment.paymentCollectionId);
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: updatedList
                        };
                    } else {
                        return {
                            ...prevBasket,
                            paymentsCollectionIds: [
                                ...prevBasket.paymentsCollectionIds,
                                payment.paymentCollectionId
                            ]
                        };
                    }
                });
            });
            // Se limpia el array para evitar reprocesos
            setRequestCreatePaymentBGPG([]);
        }
    }, [requestCreatePayment, requestCreatePaymentBGPG]);
    
   
    const renderButtonColumn = (item) => {
        return (
            <>
                <div className='pt-3'>
                    <Row className="p-0 " style={{ width: '500px' }}>
                        <Col lg={6}>
                            <div onMouseEnter={ () => {
                                    if(!openPaymentList.length > 0 ){
                                        handleMouseEnter();
                                    }}
                                }
                                onMouseLeave={ () => {
                                    if(!openPaymentList.length > 0 ){
                                        handleMouseLeave();
                                    }}
                                }>
                                    
                                {permissionSign === true ?
                                    <Button  id="TooltipExample" disabled={openPaymentList.length === 0 || isProcessing}
                                             onClick={() => {
                                                 setIsSpinnerVisible(true);
                                                 setIsProcessing(true);                                                
                                                 LockedPayments();
                                                 SaveLocalStorage('openPaymentList', JSON.stringify(openPaymentList));
                                             }}
                                             className="bg-grey-tab text-muted border-0 fw-bold position-relative">
                                        {t('payReceive.sign')}
                                        <div className={`tooltip-grey ${isMouseOver === true ? 'opacity-1' : ''}`}>{t('validationMessage.selectPayment')} </div>
                                    </Button>
                                    : ''}
                                    {isSpinnerVisible && (
                                        <Spinner color="success" style={{ width: '1rem', height: '1rem', marginLeft: '0.5rem' }} />
                                )}
                            </div>                         
                        </Col>
                    </Row>                 
                </div>
            </>
        );
    }

    const columns = ["visualId", 'model','creditorName','creditorAccountIban','paymentType','createdAt', 'status', 'instructedAmount'];
    const listOptions = {
        "model": AdminResponseOpenPaymentModel,
        'columns':[
            "visualId",'creditorName','creditorAccountIban','updatedAt', //añadir product, creditorAccountIban,instructedAmount
            {
                "field" : "status",
                "label" : t('modelLabel.status'),
                'insideClass' : (item)=> item.status === 'Betald' ? 'box-green' : item.status === 'Väntande' ? 'box-orange' :'box-red ',
                "value": (item) =>  item.status === 'Betald' ? t('modelLabel.paidOut') : item.status === 'Väntande' ? t('attest.pending') : t('modelLabel.unpaid') || "",
                'type': 'select'
                //'thClass': 'ps-5'
            },
            {
                "field" : "instructedAmount",
                "label" : t('modelLabel.amount'),
                'insideClass' : (item)=> item.instructedAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.instructedAmount ? ToCustomDecimal(item.instructedAmount) +' kr' : "",
                'thClass': 'ps-5',
                'type': 'number'
            },

        ],
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,

        },
        "pagination": {
            "viewDropdownPerPage": false,
            "controlsView": false,
            "initialItemsPerPage": 1000
        },
        "table":{
            "paginationInFooter": false,
        }, 
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/open-payment/admin/search-unpaid/filter",
        "nameFile": t('payReceive.bankAccount'),
        "ignoreFields" : columns,
        "filterTestIsAvailable": true,
        "modelType": "BankTransferPending",
        "urlGetSearch" : "/open-payment/admin/search-unpaid/filter",
    };

    if (permissionSign === true) {
        listOptions.actions.checkColumn = renderCheckColumn;
        listOptions.actions.actionAllCheckColumn = actionAllCheckColumn;
        listOptions.actions.allCheckColumn = true;
    }

    //MODAL CONTENT
    const modalContent = (
        <div>
            <h4 className="rokkitt-font">{t('openPayment.bulkPayment')}</h4>          
            {openPaymentList.map((item, index) => {
                return (
                    <Card className="bg-card-grey py-2 px-4 text-start mb-4" key={'payment' + item.modelId + index}>
                        <div><span className="fw-bold">{t('modelLabel.model')}:</span> {item.model === 'Förrättning' ? t('reimbursement.reportProcessing') : item.model === 'Arvode' ? t('honorarium.honorarium') : item.model === 'Betala' ? t('payReceive.pay') : item.model === 'Intern transaktion' ? t('internalTransaction.internalTransaction') : item.model === 'Skatteverket' ? item.type : ''} </div>
                        <div><span className="fw-bold">{t('openPayment.instructedAmount')}: </span> {item.instructedAmount ? ToCustomDecimal(item.instructedAmount) : 0} kr</div>
                        <div><span className="fw-bold">{t('openPayment.creditorName')}:</span> { item.model === 'Skatteverket' ? t('modelLabel.TheSwedishTaxAgency'): item.creditorName} </div>
                        {item.creditorAccountIban ?
                            <div><span className="fw-bold">{t('openPayment.creditorAccountIban')}:</span> {item.creditorAccountIban} </div>
                            : ''}
                        {item.creditorAccountBban ?
                            <div><span className="fw-bold">{t('openPayment.creditorAccountBban')}:</span> {item.creditorAccountBban} </div>
                            :''}
                        <div><span className="fw-bold">{t('modelLabel.status')}:</span> {item.status === 'Betald' ? t('modelLabel.paid') : item.status === 'Obetald' ? t('modelLabel.unpaid') : item.status} </div>
                    </Card>
                );
            })}
            {duplicatePaymentsList && duplicatePaymentsList.length > 0 && (
                duplicatePaymentsList.map((item, index) => {
                    return (
                        <Card className="bg-card-grey-duplicate py-2 px-4 text-start mb-4" key={'payment' + item.modelId}>
                            <div><span className="fw-bold"></span>  </div>
                            <div><span className="fw-bold">{'Id'}:</span> {item.visualId} </div>
                            <div><span className="fw-bold">{t('modelLabel.model')}:</span> {item.model === 'Förrättning' ? t('reimbursement.reportProcessing') : item.model === 'Arvode' ? t('honorarium.honorarium') : item.model === 'Betala' ? t('payReceive.pay') : item.model === 'Intern transaktion' ? t('internalTransaction.internalTransaction') : item.model === 'Skatteverket' ? item.type : ''} </div>
                            <div><span className="fw-bold">{t('openPayment.instructedAmount')}: </span> {item.amount ? ToCustomDecimal(item.amount) : 0} kr</div>
                            <div><span className="fw-bold">{t('openPayment.creditorName')}:</span> {item.name} </div>
                            {item.bankAccount ? (
                                <div><span className="fw-bold">{t('openPayment.creditorAccountIban')}:</span> {item.bankAccount} </div>
                            ) : null}                  
                            <div style={{ marginTop: '1rem' }}>
                            {item.status && item.status === 'Väntande' ? (
                                <div className="text-red-pending d-flex align-items-center mb-3">
                                    <i className='bx bx-info-circle fs-5 pe-2'/>{t('infoBox.duplicatePayment')}
                                </div>
                            ): 
                                <div className="text-red-pending d-flex align-items-center mb-3">
                                    <i className='bx bx-info-circle fs-5 pe-2'/>{t('infoBox.duplicatePaymentStatus')}
                                </div>
                            }
                            </div>
                        </Card>
                     );
                 })
            )}
        </div>
    );
    
    const modalFooter = (
        <>
         {displaySpinner === true ?
            <Col lg='12' className="text-center">
                {t('validationMessage.sendForm')} <Spinner color='success' className="me-3" />
            </Col>
            : ""}
            <Button className="box-green border-0" disabled={showButtonSing} onClick={async()=> {              
                const paymentPromises = openPaymentList.map(async (item) => {
                    if (item.status !== 'Väntande') {
                      if (item.paymentType === 'Bankkonto') {
                        const newRequest = new AdminRequestCreatePaymentModel();
                        newRequest.instructedAmount.amount = item.instructedAmount;
                        newRequest.creditorName = item.creditorName;
                        newRequest.creditorAccount.clearingNumber = item.clearingNumber;
                        newRequest.creditorAccount.ibanorbban = item.creditorAccountIban;
                        newRequest.creditorAccount.currency = 'SEK';
                        newRequest.product = 'domestic';
                        newRequest.message = `LRF(${item.visualId.toString()})`;
                        newRequest.paymentCollectionId = item.id;
                        // Esperamos a que se complete la operación
                        await CreatePayment(newRequest, 'Bankkonto');
                      } else if (item.paymentType === 'Bankgiro') {
                        const newRequestBankGiro = new AdminRequestCreatePaymentBGPGModel();
                        newRequestBankGiro.invoiceReference = item.ocr;
                        newRequestBankGiro.debtorAccountMessage = item.creditorAccountIban;
                        newRequestBankGiro.instructedAmount.amount = item.instructedAmount;
                        newRequestBankGiro.creditorName = item.creditorName;
                        newRequestBankGiro.creditorGiro.giroNumber = item.creditorAccountIban;
                        newRequestBankGiro.creditorGiro.giroType = 'BANKGIRO';
                        newRequestBankGiro.paymentCollectionId = item.id;
                        await CreatePaymentBGPG(newRequestBankGiro);
                      } else if (item.paymentType === 'Plusgiro') {
                        const newRequestPlusGiro = new AdminRequestCreatePaymentBGPGModel();
                        newRequestPlusGiro.invoiceReference = item.ocr;
                        newRequestPlusGiro.debtorAccountMessage = item.creditorAccountIban;
                        newRequestPlusGiro.instructedAmount.amount = item.instructedAmount;
                        newRequestPlusGiro.creditorName = item.creditorName;
                        newRequestPlusGiro.creditorGiro.giroNumber = item.creditorAccountIban;
                        newRequestPlusGiro.creditorGiro.giroType = 'PLUSGIRO';
                        newRequestPlusGiro.paymentCollectionId = item.id;
                        await CreatePaymentBGPG(newRequestPlusGiro);
                      }
                    }
                  });
                  
                  // Espera a que se resuelvan todas las promesas
                  await Promise.all(paymentPromises);
                CreateBasket();
                await stopIntervalWithoutUnlocking();
                localStorage.removeItem("openPaymentList");
                setDisplaySpinner(true);
                setShowButtonSing(true);
            }}>
                {t('openPayment.sign')}
            </Button>
            <Button className="box-red border-0" onClick={() =>{
                UnlockedPayment(openPaymentList);
                setIsProcessing(false);         
                setDisplayBulkPaymentModal(false);
                stopInterval();
                $("#TooltipExample").prop("disabled", false);
            }}>{t('button.cancel')}</Button>
        </>
    );

    //PENDING MODAL CONTENT
    const pendingModalContent = (
        <>      
            <Col lg='12' className="text-center">
                <h4 className="rokkitt-font">{t('modelLabel.status')}</h4>
                <h4>{pendingStatus}</h4>
                {pendingStatus === 'ACSP' ? 
                    <p className="mt-4">ACSP: ’AcceptedSettlementInProcess’ - Alla föregående kontroller såsom teknisk validering och kundprofil var framgångsrika och därför har betalningsinitieringen accepterats för genomförande.</p> :
                 pendingStatus === 'ACTC' ? 
                    <p className="mt-4">ACTC: 'AcceptedTechnicalValidation' - Autentisering samt syntaktisk och semantisk validering har genomförts.</p> : 
                pendingStatus === 'ACWC' ? 
                    <p className="mt-4">ACWC: 'AcceptedWithChange' - Instruktionen är accepterad men en ändring kommer att göras, t.ex. att datum eller remiss inte skickas.</p> : 
                pendingStatus === 'ACWP' ? 
                    <p className="mt-4">ACWP: 'AcceptedWithoutPosting' - Betalningsinstruktionen som ingår i gireringen accepteras utan att bokföras på den kreditgivande kundens konto.</p> : 
                pendingStatus === 'RCVD' ? 
                    <p className="mt-4">RCVD: 'Received' - Betalningsinitieringen har tagits emot av det mottagande ombudet.</p> : 
                pendingStatus === 'PDNG' ? 
                    <p className="mt-4">PDNG: 'Pending' - Betalningsinitiering eller enskild transaktion som ingår i betalningsinitieringen är under behandling. Ytterligare kontroller och statusuppdatering kommer att utföras.</p> : 
                pendingStatus === 'ACFC' ? 
                    <p className="mt-4">ACFC: 'AcceptedFundsChecked' - Föregående kontroll av teknisk validering och kundprofil var framgångsrik och en automatisk kontroll av medel var positiv. Anmärkning: Denna kod accepteras som ny kod av ISO20022.</p> :
                pendingStatus === 'PATC' ? 
                    <p className="mt-4">PATC: 'PartiallyAcceptedTechnical' Correct Betalningsinitieringen behöver flera autentiseringar, där vissa men ännu inte alla har utförts. Syntaktiska och semantiska valideringar är framgångsrika. Anmärkning: Denna kod accepteras som ny kod av ISO20022.</p> :
                pendingStatus === 'PART' ? 
                    <p className="mt-4">PART: 'PartiallyAccepted' - Ett antal transaktioner har accepterats, medan ett annat antal transaktioner ännu inte har uppnått statusen 'accepterad'. Anmärkning: Denna kod får endast användas vid massbetalningar. Den används endast i en situation där alla föreskrivna behörigheter har tillämpats, men vissa betalningar har avvisats.</p> :
                pendingStatus === 'ACCC' ? 
                    <p className="mt-4">ACCC: ’AcceptedSettlementCompleted’ - Avräkning på fordringsägarens konto har slutförts.</p> :
                pendingStatus === 'ACSC' ? 
                    <p className="mt-4">ACSC: 'AcceptedSettlementCompleted' - Settlement on the debtor's account has been completed.</p> :
                pendingStatus === 'RJCT' ? 
                    <p className="mt-4">RJCT: 'Rejected' - Betalningsinitieringen eller en enskild transaktion som ingår i betalningsinitieringen har avvisats.</p> : "" }
                    
                {messageError && (pendingStatus !== "ACSP" || pendingStatus !== "ACTC" || pendingStatus !== "ACCC")?
                <h5 className="rokkitt-font pt-2">{`Beskrivning: ${messageError}`}</h5>      
                : ""}
            </Col> 
        </>
    );

    const pendingModalFooter = (
        <>
          <Button
                className="box-green border-0"
                onClick={() => {
                    setShowPendingPayment(false);
                    setMessageError('');
                }}
                >
                {t('button.hideInfo')}
            </Button>
        </>
    );

    const contentBodyDeny = (
        <>
            <div className="text-center pb-3">
                <h5 className="rokkitt-font">{t('validationMessage.modalPaymentDenyHeader')}</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-75 position-relative">
                    <CustomInput model={AdminRequestAttestDenyModel} attribute="reason" customChange={e => handleChangePaymentReverseReason(e)}/>
                    <div className={`error tooltip-custom-normal ${errorReason === true ? 'opacity-1' : 'opacity-0'}`}
                        style={{color: '#f56e6e'}}>
                        {t('validationMessage.fieldRequired')}
                    </div>
                </div>
            </div>
            <div>{t('validationMessage.modalPaymentDeny')}</div>
        </>
    );

    const contentPaymentDetails = (
        <div className="payment-details-container" style={{ width: '1000px', margin: '0 auto' }}>
            <h4 className="col fw-bold rokkitt-font" style={{ marginBottom: '20px' }}>{titlePaymentDetails}</h4>
            <div className="row fw-bold mb-2 border-bottom pb-2">
                <div className="col">{t('modelLabel.id')}</div>
                <div className="col">{t('modelLabel.type')}</div>
                <div className="col">{t('modelLabel.orgName')}</div>
                <div className="col">{t('modelLabel.createdAt')}</div>
                <div className="col">{t('modelLabel.amount')}</div>
                <div className="col">{t('button.info')}</div>
            </div>
            {paymentDetailsList.map((item, index) => (
                <div className="row mb-2" key={'payment-' + index}>
                    <div className="col">{item.visualId <= 0 ? 0 : item.visualId}</div>
                    <div className="col">
                        {item.model === 'Förrättning' ? t('reimbursement.reportProcessing') :
                         item.model === 'Arvode' ? t('honorarium.honorarium') :
                         item.model === 'Betala' ? t('payReceive.pay') :
                         item.model === 'Intern transaktion' ? t('internalTransaction.internalTransaction') :
                         item.model }
                    </div>
                    <div className="col">{item.orgName}</div>
                    <div className="col">{item.createdAt ? ChangeDateFormat('dateTime', item.createdAt): ''}</div>
                    <div className="col">{item.instructedAmount ? ToCustomDecimal(item.instructedAmount) : 0} kr</div>
                    <div className="col">
                    {(item.model !== 'Arvode' && item.model !== 'Skatteverket') && (paymentStatus === 'Obetald' || paymentStatus === 'Väntande') && permissionDeny === true ?    
                        <button id={item.id} className="rounded me-2 py-2 border-0 text-red box-red p-custom-button position-relative class-deny"
                                onClick={() => {
                                    handleChangePaymentReverseModelId(item.modelId);
                                    handleChangePaymentReverseModel(item.model);
                                    setShowConfirmModalReverse(true);
                                    const allButtons = document.querySelectorAll('button');
                                    allButtons.forEach(button => {
                                        if (button.classList.contains('class-approve')) {
                                            button.disabled = true;
                                            button.classList.remove('bg-green-light', 'text-green');
                                            button.classList.add('bg-grey-tab');
                                        } else if (button.classList.contains('class-deny')) {
                                            button.disabled = true;
                                            button.classList.remove('box-red', 'text-red');
                                            button.classList.add('bg-grey-tab');
                                        }
                                    });
                                }} >
                            <i className="bx bx-list-minus pb-custom align-middle size-icon-custom-button" />
                            <div className="tooltip-custom-button">
                                {t('attest.deny') }
                            </div>
                        </button>
                        : ''}
                         <button
                            id={item.modelId}
                            className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                            onClick={() => {
                                window.open(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.modelId}&paymentId=${paymentId}&model=${item.model}&amount=${item.instructedAmount}`);
                            }}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            >
                            <img src={hovered ? iconTabWhite : iconTabGreen} alt="Icono" width={22} />
                            <div className="tooltip-custom-button">
                                {t('button.info')}
                            </div>
                        </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    

    const contentPaymentFooterDeny = (
        <>         
            <Button className="box-red border-0" onClick={() => {
                let url = new URL(window.location.href);
                url.searchParams.delete('paymentId');
                window.history.replaceState({}, document.title, url.toString());
                setShowPaymentDetails(false);
                
            }}>{t('button.cancel')}</Button>
        </>
    );

    const contentFooterDeny = (
        <>
            <Button className="box-green border-0" disabled={isAcceptDisabled} onClick={async () => {
                setIsAcceptDisabled(true);
                setShowPaymentDetails(false);
                setShowConfirmModalApprove(false); // Close Modal
                await handleValidation("deny");
            }}>{t('button.accept')}</Button>
            <Button className="box-red border-0" onClick={() => {
                setShowConfirmModalReverse(false);
                setErrorReason(false);
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab');
                    }
                });

            }}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
            <Col lg={12} className=' pb-5 px-0'>
                <CustomModal isOpen={showPendingPayment} toggle={() => setShowPendingPayment(!showPendingPayment)}
                             body={pendingModalContent} footer={pendingModalFooter}/>
                 <CustomModal isOpen={showConfirmModalReverse} toggle={() => setShowConfirmModalReverse(!showConfirmModalReverse)} body={contentBodyDeny} footer={contentFooterDeny} />
                             
                <CustomModal isOpen={displayBulkPaymentModal} toggle={() => setDisplayBulkPaymentModal(!displayBulkPaymentModal)}
                             body={modalContent} footer={modalFooter}/>
                <CustomModal isOpen={showPaymentDetails} toggle={() => setShowPaymentDetails(!showPaymentDetails)} body={contentPaymentDetails} footer={contentPaymentFooterDeny} size='xl'/>
                <Card className='card-backend p-4'>
                    <Row>
                        <Col lg={6}>
                            <h2 className="fw-bold rokkitt-font">{t('openPayment.pendingBankList')}</h2>
                        </Col>
                        <Col lg={6} className="text-end">
                            <Button className="bg-green" onClick={() => {
                                    navigate(`${ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST}`);
                                }}>
                                {t('openPayment.history')}
                            </Button>
                        </Col>
                    </Row>

                    <SortableList
                        listOptions={listOptions}
                        setApiCallDone={setResponseOpenPaymentList}
                    />     
                </Card>
            </Col>
        </>
    );
}