import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { ChangeDateFormat } from '../../Common/Helpers/ChangeDateFormat';
import { ToCustomDecimal } from '../../AppSystem/Helpers/ToCustomDecimal';
import { useTranslation } from 'react-i18next';

export function RelatedProceedings({ relatedData, data, onPageChange, page, setPage, handleClick, visibleCount }) {
    const { t } = useTranslation();

    return (
        <>
            {relatedData?.items.length >= 1 && (
                <Row className="mt-5">
                    <Col lg={12}>
                        {relatedData?.items.length === 1 && relatedData?.pagination.totalPages === 1 ? (
                            <h3 className="rokkitt-font">{t('attest.singleRelatedProceeding')}</h3>
                        ) : (
                            <h3 className="rokkitt-font">{t('attest.relatedProceedings')}</h3>
                        )}
                        <Card className="p-4">
                            <Row>
                                <Col lg={12}>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="text-left">
                                                <tr>
                                                    <th style={{ textAlign: "left" }}>{t('modelLabel.type')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('attest.activityDate')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('modelLabel.recipient')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('attest.organisation')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('honorarium.payment')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('attest.purpose')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('attest.createdAt')}</th>
                                                    <th>{t('attest.amount')}</th>
                                                    <th style={{ textAlign: "left" }}>{t('modelLabel.status')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {relatedData.items
                                                    .filter(item => {
                                                        if (!(relatedData?.items.length === 1 && relatedData?.pagination.totalPages === 1)) {
                                                            return item.attest?.id !== data.id;
                                                        }
                                                        return true;
                                                    })
                                                    .sort((a, b) => {
                                                        const dateA = a.attest?.createdAt ? new Date(a.attest.createdAt) : new Date(0);
                                                        const dateB = b.attest?.createdAt ? new Date(b.attest.createdAt) : new Date(0);
                                                        return dateB - dateA;
                                                    })
                                                    .slice(0, visibleCount)
                                                    .map((item, index) => {
                                                        const relatedModel = item.relatedModel || {};
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-left align-middle">
                                                                    {item.attest?.model === "Arvode" ? t('honorarium.honorarium') : t('reimbursement.reportProcessing')}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {relatedModel.date ? ChangeDateFormat('date', relatedModel.date) : ''}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {item.attest?.recipient || ''}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {item.attest?.orgName || ''}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {relatedModel.paymentInterval === 1
                                                                        ? t('modelLabel.monthly')
                                                                        : relatedModel.paymentInterval === 12
                                                                        ? t('modelLabel.yearly')
                                                                        : relatedModel.paymentInterval || ''}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {relatedModel.purpose || ''}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {item.attest?.createdAt ? ChangeDateFormat('dateTime', item.attest.createdAt) : ''}
                                                                </td>
                                                                <td className={`text-end align-middle ${item.attest?.amount >= 0 ? 'text-green' : 'text-red'}`}
                                                                    style={{ textAlign: "right", paddingRight: "3rem" }}>
                                                                    {item.attest?.amount !== undefined ? `${ToCustomDecimal(item.attest.amount)} kr` : ''}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    {relatedModel.status ? (
                                                                        relatedModel.status === "Nekad" ? (
                                                                            <div className="box-red me-4 fixed-width">{t("attest.denied")}</div>
                                                                        ) : relatedModel.status === "Skickat" ? (
                                                                            <div className="box-custom-grey me-4 fixed-width">{t("reimbursement.sent")}</div>
                                                                        ) : relatedModel.status === "Godkänd" ? (
                                                                            <div className="box-green me-4 fixed-width">{t("attest.approved")}</div>
                                                                        ) : relatedModel.status === "Utbetald" ? (
                                                                            <div className="box-green me-4 fixed-width">{t("modelLabel.paidOut")}</div>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td className="text-left align-middle">
                                                                    <button
                                                                        className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                                                                        onClick={() => handleClick(item.attest?.id)}
                                                                    >
                                                                        <i className="bx bx-link-external pb-custom align-middle size-icon-custom-button"></i>
                                                                        <div className="tooltip-custom-button">
                                                                            {t('button.info')}
                                                                        </div>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        {relatedData.pagination.totalPages > 1 && (
                            <Row>
                                <Col lg="12" className="text-center">
                                    <button
                                        className="btn mx-1"
                                        style={{ border: "none", marginTop: "1rem" }}
                                        aria-label={t('accessibility.prevPage')}
                                        onClick={() => {
                                            onPageChange(page - 1);
                                            setPage(page - 1);
                                        }}
                                        disabled={page <= 1}
                                    >
                                        <i className="bx bx-left-arrow-alt fs-4"></i>
                                    </button>

                                    <button
                                        className="btn mx-1"
                                        style={{ border: "none", marginTop: "1rem" }}
                                        aria-label={t('accessibility.nextPage')}
                                        onClick={() => {
                                            onPageChange(page + 1);
                                            setPage(page + 1);
                                        }}
                                        disabled={page === relatedData.pagination.totalPages}
                                    >
                                        <i className="bx bx-right-arrow-alt fs-4"></i>
                                    </button>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
}
